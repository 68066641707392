import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    function toInteger(dirtyNumber) {
        if (dirtyNumber === null || dirtyNumber === true || dirtyNumber === false) {
            return NaN;
        }

        var number = Number(dirtyNumber);

        if (isNaN(number)) {
            return number;
        }

        return number < 0 ? Math.ceil(number) : Math.floor(number);
    }

    function requiredArgs(required, args) {
        if (args.length < required) {
            throw new TypeError(required + ' argument' + (required > 1 ? 's' : '') + ' required, but only ' + args.length + ' present');
        }
    }


    /**
     * @name toDate
     * @category Common Helpers
     * @summary Convert the given argument to an instance of Date.
     *
     * @description
     * Convert the given argument to an instance of Date.
     *
     * If the argument is an instance of Date, the function returns its clone.
     *
     * If the argument is a number, it is treated as a timestamp.
     *
     * If the argument is none of the above, the function returns Invalid Date.
     *
     * **Note**: *all* Date arguments passed to any *date-fns* function is processed by `toDate`.
     *
     * @param {Date|Number} argument - the value to convert
     * @returns {Date} the parsed date in the local time zone
     * @throws {TypeError} 1 argument required
     *
     * @example
     * // Clone the date:
     * const result = toDate(new Date(2014, 1, 11, 11, 30, 30))
     * //=> Tue Feb 11 2014 11:30:30
     *
     * @example
     * // Convert the timestamp to date:
     * const result = toDate(1392098430000)
     * //=> Tue Feb 11 2014 11:30:30
     */

    function toDate(argument) {
        requiredArgs(1, arguments);
        var argStr = Object.prototype.toString.call(argument); // Clone the date

        if (argument instanceof Date || typeof argument === 'object' && argStr === '[object Date]') {
            // Prevent the date to lose the milliseconds when passed to new Date() in IE10
            return new Date(argument.getTime());
        } else if (typeof argument === 'number' || argStr === '[object Number]') {
            return new Date(argument);
        } else {
            if ((typeof argument === 'string' || argStr === '[object String]') && typeof console !== 'undefined') {
                // eslint-disable-next-line no-console
                console.warn("Starting with v2.0.0-beta.1 date-fns doesn't accept strings as date arguments. Please use `parseISO` to parse strings. See: https://github.com/date-fns/date-fns/blob/master/docs/upgradeGuide.md#string-arguments"); // eslint-disable-next-line no-console

                console.warn(new Error().stack);
            }

            return new Date(NaN);
        }
    }

    /**
     * @name addDays
     * @category Day Helpers
     * @summary Add the specified number of days to the given date.
     *
     * @description
     * Add the specified number of days to the given date.
     *
     * @param {Date|Number} date - the date to be changed
     * @param {Number} amount - the amount of days to be added. Positive decimals will be rounded using `Math.floor`, decimals less than zero will be rounded using `Math.ceil`.
     * @returns {Date} - the new date with the days added
     * @throws {TypeError} - 2 arguments required
     *
     * @example
     * // Add 10 days to 1 September 2014:
     * const result = addDays(new Date(2014, 8, 1), 10)
     * //=> Thu Sep 11 2014 00:00:00
     */

    function addDays(dirtyDate, dirtyAmount) {
        requiredArgs(2, arguments);
        var date = toDate(dirtyDate);
        var amount = toInteger(dirtyAmount);

        if (isNaN(amount)) {
            return new Date(NaN);
        }

        if (!amount) {
            // If 0 days, no-op to avoid changing times in the hour before end of DST
            return date;
        }

        date.setDate(date.getDate() + amount);
        return date;
    }

    /**
     * @name addMonths
     * @category Month Helpers
     * @summary Add the specified number of months to the given date.
     *
     * @description
     * Add the specified number of months to the given date.
     *
     * @param {Date|Number} date - the date to be changed
     * @param {Number} amount - the amount of months to be added. Positive decimals will be rounded using `Math.floor`, decimals less than zero will be rounded using `Math.ceil`.
     * @returns {Date} the new date with the months added
     * @throws {TypeError} 2 arguments required
     *
     * @example
     * // Add 5 months to 1 September 2014:
     * const result = addMonths(new Date(2014, 8, 1), 5)
     * //=> Sun Feb 01 2015 00:00:00
     */

    function addMonths(dirtyDate, dirtyAmount) {
        requiredArgs(2, arguments);
        var date = toDate(dirtyDate);
        var amount = toInteger(dirtyAmount);

        if (isNaN(amount)) {
            return new Date(NaN);
        }

        if (!amount) {
            // If 0 months, no-op to avoid changing times in the hour before end of DST
            return date;
        }

        var dayOfMonth = date.getDate(); // The JS Date object supports date math by accepting out-of-bounds values for
        // month, day, etc. For example, new Date(2020, 0, 0) returns 31 Dec 2019 and
        // new Date(2020, 13, 1) returns 1 Feb 2021.  This is *almost* the behavior we
        // want except that dates will wrap around the end of a month, meaning that
        // new Date(2020, 13, 31) will return 3 Mar 2021 not 28 Feb 2021 as desired. So
        // we'll default to the end of the desired month by adding 1 to the desired
        // month and using a date of 0 to back up one day to the end of the desired
        // month.

        var endOfDesiredMonth = new Date(date.getTime());
        endOfDesiredMonth.setMonth(date.getMonth() + amount + 1, 0);
        var daysInMonth = endOfDesiredMonth.getDate();

        if (dayOfMonth >= daysInMonth) {
            // If we're already at the end of the month, then this is the correct date
            // and we're done.
            return endOfDesiredMonth;
        } else {
            // Otherwise, we now know that setting the original day-of-month value won't
            // cause an overflow, so set the desired day-of-month. Note that we can't
            // just set the date of `endOfDesiredMonth` because that object may have had
            // its time changed in the unusual case where where a DST transition was on
            // the last day of the month and its local time was in the hour skipped or
            // repeated next to a DST transition.  So we use `date` instead which is
            // guaranteed to still have the original time.
            date.setFullYear(endOfDesiredMonth.getFullYear(), endOfDesiredMonth.getMonth(), dayOfMonth);
            return date;
        }
    }

    /**
     * @name add
     * @category Common Helpers
     * @summary Add the specified years, months, weeks, days, hours, minutes and seconds to the given date.
     *
     * @description
     * Add the specified years, months, weeks, days, hours, minutes and seconds to the given date.
     *
     * @param {Date|Number} date - the date to be changed
     * @param {Duration} duration - the object with years, months, weeks, days, hours, minutes and seconds to be added. Positive decimals will be rounded using `Math.floor`, decimals less than zero will be rounded using `Math.ceil`.
     *
     * | Key            | Description                        |
     * |----------------|------------------------------------|
     * | years          | Amount of years to be added        |
     * | months         | Amount of months to be added       |
     * | weeks          | Amount of weeks to be added        |
     * | days           | Amount of days to be added         |
     * | hours          | Amount of hours to be added        |
     * | minutes        | Amount of minutes to be added      |
     * | seconds        | Amount of seconds to be added      |
     *
     * All values default to 0
     *
     * @returns {Date} the new date with the seconds added
     * @throws {TypeError} 2 arguments required
     *
     * @example
     * // Add the following duration to 1 September 2014, 10:19:50
     * const result = add(new Date(2014, 8, 1, 10, 19, 50), {
     *   years: 2,
     *   months: 9,
     *   weeks: 1,
     *   days: 7,
     *   hours: 5,
     *   minutes: 9,
     *   seconds: 30,
     * })
     * //=> Thu Jun 15 2017 15:29:20
     */
    function add(dirtyDate, duration) {
        requiredArgs(2, arguments);
        if (!duration || typeof duration !== 'object') return new Date(NaN);
        var years = duration.years ? toInteger(duration.years) : 0;
        var months = duration.months ? toInteger(duration.months) : 0;
        var weeks = duration.weeks ? toInteger(duration.weeks) : 0;
        var days = duration.days ? toInteger(duration.days) : 0;
        var hours = duration.hours ? toInteger(duration.hours) : 0;
        var minutes = duration.minutes ? toInteger(duration.minutes) : 0;
        var seconds = duration.seconds ? toInteger(duration.seconds) : 0; // Add years and months

        var date = toDate(dirtyDate);
        var dateWithMonths = months || years ? addMonths(date, months + years * 12) : date; // Add weeks and days

        var dateWithDays = days || weeks ? addDays(dateWithMonths, days + weeks * 7) : dateWithMonths; // Add days, hours, minutes and seconds

        var minutesToAdd = minutes + hours * 60;
        var secondsToAdd = seconds + minutesToAdd * 60;
        var msToAdd = secondsToAdd * 1000;
        var finalDate = new Date(dateWithDays.getTime() + msToAdd);
        return finalDate;
    }

    /**
     * @name addMilliseconds
     * @category Millisecond Helpers
     * @summary Add the specified number of milliseconds to the given date.
     *
     * @description
     * Add the specified number of milliseconds to the given date.
     *
     * @param {Date|Number} date - the date to be changed
     * @param {Number} amount - the amount of milliseconds to be added. Positive decimals will be rounded using `Math.floor`, decimals less than zero will be rounded using `Math.ceil`.
     * @returns {Date} the new date with the milliseconds added
     * @throws {TypeError} 2 arguments required
     *
     * @example
     * // Add 750 milliseconds to 10 July 2014 12:45:30.000:
     * const result = addMilliseconds(new Date(2014, 6, 10, 12, 45, 30, 0), 750)
     * //=> Thu Jul 10 2014 12:45:30.750
     */

    function addMilliseconds(dirtyDate, dirtyAmount) {
        requiredArgs(2, arguments);
        var timestamp = toDate(dirtyDate).getTime();
        var amount = toInteger(dirtyAmount);
        return new Date(timestamp + amount);
    }

    var defaultOptions = {};
    function getDefaultOptions() {
        return defaultOptions;
    }

    /**
     * @name startOfWeek
     * @category Week Helpers
     * @summary Return the start of a week for the given date.
     *
     * @description
     * Return the start of a week for the given date.
     * The result will be in the local timezone.
     *
     * @param {Date|Number} date - the original date
     * @param {Object} [options] - an object with options.
     * @param {Locale} [options.locale=defaultLocale] - the locale object. See [Locale]{@link https://date-fns.org/docs/Locale}
     * @param {0|1|2|3|4|5|6} [options.weekStartsOn=0] - the index of the first day of the week (0 - Sunday)
     * @returns {Date} the start of a week
     * @throws {TypeError} 1 argument required
     * @throws {RangeError} `options.weekStartsOn` must be between 0 and 6
     *
     * @example
     * // The start of a week for 2 September 2014 11:55:00:
     * const result = startOfWeek(new Date(2014, 8, 2, 11, 55, 0))
     * //=> Sun Aug 31 2014 00:00:00
     *
     * @example
     * // If the week starts on Monday, the start of the week for 2 September 2014 11:55:00:
     * const result = startOfWeek(new Date(2014, 8, 2, 11, 55, 0), { weekStartsOn: 1 })
     * //=> Mon Sep 01 2014 00:00:00
     */

    function startOfWeek(dirtyDate, options) {
        var _ref, _ref2, _ref3, _options$weekStartsOn, _options$locale, _options$locale$optio, _defaultOptions$local, _defaultOptions$local2;

        requiredArgs(1, arguments);
        var defaultOptions = getDefaultOptions();
        var weekStartsOn = toInteger((_ref = (_ref2 = (_ref3 = (_options$weekStartsOn = options === null || options === void 0 ? void 0 : options.weekStartsOn) !== null && _options$weekStartsOn !== void 0 ? _options$weekStartsOn : options === null || options === void 0 ? void 0 : (_options$locale = options.locale) === null || _options$locale === void 0 ? void 0 : (_options$locale$optio = _options$locale.options) === null || _options$locale$optio === void 0 ? void 0 : _options$locale$optio.weekStartsOn) !== null && _ref3 !== void 0 ? _ref3 : defaultOptions.weekStartsOn) !== null && _ref2 !== void 0 ? _ref2 : (_defaultOptions$local = defaultOptions.locale) === null || _defaultOptions$local === void 0 ? void 0 : (_defaultOptions$local2 = _defaultOptions$local.options) === null || _defaultOptions$local2 === void 0 ? void 0 : _defaultOptions$local2.weekStartsOn) !== null && _ref !== void 0 ? _ref : 0); // Test if weekStartsOn is between 0 and 6 _and_ is not NaN

        if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
            throw new RangeError('weekStartsOn must be between 0 and 6 inclusively');
        }

        var date = toDate(dirtyDate);
        var day = date.getDay();
        var diff = (day < weekStartsOn ? 7 : 0) + day - weekStartsOn;
        date.setDate(date.getDate() - diff);
        date.setHours(0, 0, 0, 0);
        return date;
    }

    /**
     * @name startOfISOWeek
     * @category ISO Week Helpers
     * @summary Return the start of an ISO week for the given date.
     *
     * @description
     * Return the start of an ISO week for the given date.
     * The result will be in the local timezone.
     *
     * ISO week-numbering year: http://en.wikipedia.org/wiki/ISO_week_date
     *
     * @param {Date|Number} date - the original date
     * @returns {Date} the start of an ISO week
     * @throws {TypeError} 1 argument required
     *
     * @example
     * // The start of an ISO week for 2 September 2014 11:55:00:
     * const result = startOfISOWeek(new Date(2014, 8, 2, 11, 55, 0))
     * //=> Mon Sep 01 2014 00:00:00
     */

    function startOfISOWeek(dirtyDate) {
        requiredArgs(1, arguments);
        return startOfWeek(dirtyDate, {
            weekStartsOn: 1
        });
    }

    /**
     * @name getISOWeekYear
     * @category ISO Week-Numbering Year Helpers
     * @summary Get the ISO week-numbering year of the given date.
     *
     * @description
     * Get the ISO week-numbering year of the given date,
     * which always starts 3 days before the year's first Thursday.
     *
     * ISO week-numbering year: http://en.wikipedia.org/wiki/ISO_week_date
     *
     * @param {Date|Number} date - the given date
     * @returns {Number} the ISO week-numbering year
     * @throws {TypeError} 1 argument required
     *
     * @example
     * // Which ISO-week numbering year is 2 January 2005?
     * const result = getISOWeekYear(new Date(2005, 0, 2))
     * //=> 2004
     */

    function getISOWeekYear(dirtyDate) {
        requiredArgs(1, arguments);
        var date = toDate(dirtyDate);
        var year = date.getFullYear();
        var fourthOfJanuaryOfNextYear = new Date(0);
        fourthOfJanuaryOfNextYear.setFullYear(year + 1, 0, 4);
        fourthOfJanuaryOfNextYear.setHours(0, 0, 0, 0);
        var startOfNextYear = startOfISOWeek(fourthOfJanuaryOfNextYear);
        var fourthOfJanuaryOfThisYear = new Date(0);
        fourthOfJanuaryOfThisYear.setFullYear(year, 0, 4);
        fourthOfJanuaryOfThisYear.setHours(0, 0, 0, 0);
        var startOfThisYear = startOfISOWeek(fourthOfJanuaryOfThisYear);

        if (date.getTime() >= startOfNextYear.getTime()) {
            return year + 1;
        } else if (date.getTime() >= startOfThisYear.getTime()) {
            return year;
        } else {
            return year - 1;
        }
    }

    /**
     * @name startOfISOWeekYear
     * @category ISO Week-Numbering Year Helpers
     * @summary Return the start of an ISO week-numbering year for the given date.
     *
     * @description
     * Return the start of an ISO week-numbering year,
     * which always starts 3 days before the year's first Thursday.
     * The result will be in the local timezone.
     *
     * ISO week-numbering year: http://en.wikipedia.org/wiki/ISO_week_date
     *
     * @param {Date|Number} date - the original date
     * @returns {Date} the start of an ISO week-numbering year
     * @throws {TypeError} 1 argument required
     *
     * @example
     * // The start of an ISO week-numbering year for 2 July 2005:
     * const result = startOfISOWeekYear(new Date(2005, 6, 2))
     * //=> Mon Jan 03 2005 00:00:00
     */

    function startOfISOWeekYear(dirtyDate) {
        requiredArgs(1, arguments);
        var year = getISOWeekYear(dirtyDate);
        var fourthOfJanuary = new Date(0);
        fourthOfJanuary.setFullYear(year, 0, 4);
        fourthOfJanuary.setHours(0, 0, 0, 0);
        var date = startOfISOWeek(fourthOfJanuary);
        return date;
    }

    /**
     * Google Chrome as of 67.0.3396.87 introduced timezones with offset that includes seconds.
     * They usually appear for dates that denote time before the timezones were introduced
     * (e.g. for 'Europe/Prague' timezone the offset is GMT+00:57:44 before 1 October 1891
     * and GMT+01:00:00 after that date)
     *
     * Date#getTimezoneOffset returns the offset in minutes and would return 57 for the example above,
     * which would lead to incorrect calculations.
     *
     * This function returns the timezone offset in milliseconds that takes seconds in account.
     */
    function getTimezoneOffsetInMilliseconds(date) {
        var utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
        utcDate.setUTCFullYear(date.getFullYear());
        return date.getTime() - utcDate.getTime();
    }

    /**
     * @name startOfDay
     * @category Day Helpers
     * @summary Return the start of a day for the given date.
     *
     * @description
     * Return the start of a day for the given date.
     * The result will be in the local timezone.
     *
     * @param {Date|Number} date - the original date
     * @returns {Date} the start of a day
     * @throws {TypeError} 1 argument required
     *
     * @example
     * // The start of a day for 2 September 2014 11:55:00:
     * const result = startOfDay(new Date(2014, 8, 2, 11, 55, 0))
     * //=> Tue Sep 02 2014 00:00:00
     */

    function startOfDay(dirtyDate) {
        requiredArgs(1, arguments);
        var date = toDate(dirtyDate);
        date.setHours(0, 0, 0, 0);
        return date;
    }

    var MILLISECONDS_IN_DAY$1 = 86400000;
    /**
     * @name differenceInCalendarDays
     * @category Day Helpers
     * @summary Get the number of calendar days between the given dates.
     *
     * @description
     * Get the number of calendar days between the given dates. This means that the times are removed
     * from the dates and then the difference in days is calculated.
     *
     * @param {Date|Number} dateLeft - the later date
     * @param {Date|Number} dateRight - the earlier date
     * @returns {Number} the number of calendar days
     * @throws {TypeError} 2 arguments required
     *
     * @example
     * // How many calendar days are between
     * // 2 July 2011 23:00:00 and 2 July 2012 00:00:00?
     * const result = differenceInCalendarDays(
     *   new Date(2012, 6, 2, 0, 0),
     *   new Date(2011, 6, 2, 23, 0)
     * )
     * //=> 366
     * // How many calendar days are between
     * // 2 July 2011 23:59:00 and 3 July 2011 00:01:00?
     * const result = differenceInCalendarDays(
     *   new Date(2011, 6, 3, 0, 1),
     *   new Date(2011, 6, 2, 23, 59)
     * )
     * //=> 1
     */

    function differenceInCalendarDays(dirtyDateLeft, dirtyDateRight) {
        requiredArgs(2, arguments);
        var startOfDayLeft = startOfDay(dirtyDateLeft);
        var startOfDayRight = startOfDay(dirtyDateRight);
        var timestampLeft = startOfDayLeft.getTime() - getTimezoneOffsetInMilliseconds(startOfDayLeft);
        var timestampRight = startOfDayRight.getTime() - getTimezoneOffsetInMilliseconds(startOfDayRight); // Round the number of days to the nearest integer
        // because the number of milliseconds in a day is not constant
        // (e.g. it's different in the day of the daylight saving time clock shift)

        return Math.round((timestampLeft - timestampRight) / MILLISECONDS_IN_DAY$1);
    }

    /**
     * @name addYears
     * @category Year Helpers
     * @summary Add the specified number of years to the given date.
     *
     * @description
     * Add the specified number of years to the given date.
     *
     * @param {Date|Number} date - the date to be changed
     * @param {Number} amount - the amount of years to be added. Positive decimals will be rounded using `Math.floor`, decimals less than zero will be rounded using `Math.ceil`.
     * @returns {Date} the new date with the years added
     * @throws {TypeError} 2 arguments required
     *
     * @example
     * // Add 5 years to 1 September 2014:
     * const result = addYears(new Date(2014, 8, 1), 5)
     * //=> Sun Sep 01 2019 00:00:00
     */

    function addYears(dirtyDate, dirtyAmount) {
        requiredArgs(2, arguments);
        var amount = toInteger(dirtyAmount);
        return addMonths(dirtyDate, amount * 12);
    }

    /**
     * Days in 1 week.
     *
     * @name daysInWeek
     * @constant
     * @type {number}
     * @default
     */
    /**
     * Milliseconds in 1 minute
     *
     * @name millisecondsInMinute
     * @constant
     * @type {number}
     * @default
     */

    var millisecondsInMinute = 60000;
    /**
     * Milliseconds in 1 hour
     *
     * @name millisecondsInHour
     * @constant
     * @type {number}
     * @default
     */

    var millisecondsInHour = 3600000;
    /**
     * Milliseconds in 1 second
     *
     * @name millisecondsInSecond
     * @constant
     * @type {number}
     * @default
     */

    var millisecondsInSecond = 1000;

    /**
     * @name isDate
     * @category Common Helpers
     * @summary Is the given value a date?
     *
     * @description
     * Returns true if the given value is an instance of Date. The function works for dates transferred across iframes.
     *
     * @param {*} value - the value to check
     * @returns {boolean} true if the given value is a date
     * @throws {TypeError} 1 arguments required
     *
     * @example
     * // For a valid date:
     * const result = isDate(new Date())
     * //=> true
     *
     * @example
     * // For an invalid date:
     * const result = isDate(new Date(NaN))
     * //=> true
     *
     * @example
     * // For some value:
     * const result = isDate('2014-02-31')
     * //=> false
     *
     * @example
     * // For an object:
     * const result = isDate({})
     * //=> false
     */

    function isDate(value) {
        requiredArgs(1, arguments);
        return value instanceof Date || typeof value === 'object' && Object.prototype.toString.call(value) === '[object Date]';
    }

    /**
     * @name isValid
     * @category Common Helpers
     * @summary Is the given date valid?
     *
     * @description
     * Returns false if argument is Invalid Date and true otherwise.
     * Argument is converted to Date using `toDate`. See [toDate]{@link https://date-fns.org/docs/toDate}
     * Invalid Date is a Date, whose time value is NaN.
     *
     * Time value of Date: http://es5.github.io/#x15.9.1.1
     *
     * @param {*} date - the date to check
     * @returns {Boolean} the date is valid
     * @throws {TypeError} 1 argument required
     *
     * @example
     * // For the valid date:
     * const result = isValid(new Date(2014, 1, 31))
     * //=> true
     *
     * @example
     * // For the value, convertable into a date:
     * const result = isValid(1393804800000)
     * //=> true
     *
     * @example
     * // For the invalid date:
     * const result = isValid(new Date(''))
     * //=> false
     */

    function isValid(dirtyDate) {
        requiredArgs(1, arguments);

        if (!isDate(dirtyDate) && typeof dirtyDate !== 'number') {
            return false;
        }

        var date = toDate(dirtyDate);
        return !isNaN(Number(date));
    }

    /**
     * @name eachDayOfInterval
     * @category Interval Helpers
     * @summary Return the array of dates within the specified time interval.
     *
     * @description
     * Return the array of dates within the specified time interval.
     *
     * @param {Interval} interval - the interval. See [Interval]{@link https://date-fns.org/docs/Interval}
     * @param {Object} [options] - an object with options.
     * @param {Number} [options.step=1] - the step to increment by. The value should be more than 1.
     * @returns {Date[]} the array with starts of days from the day of the interval start to the day of the interval end
     * @throws {TypeError} 1 argument required
     * @throws {RangeError} `options.step` must be a number greater than 1
     * @throws {RangeError} The start of an interval cannot be after its end
     * @throws {RangeError} Date in interval cannot be `Invalid Date`
     *
     * @example
     * // Each day between 6 October 2014 and 10 October 2014:
     * const result = eachDayOfInterval({
     *   start: new Date(2014, 9, 6),
     *   end: new Date(2014, 9, 10)
     * })
     * //=> [
     * //   Mon Oct 06 2014 00:00:00,
     * //   Tue Oct 07 2014 00:00:00,
     * //   Wed Oct 08 2014 00:00:00,
     * //   Thu Oct 09 2014 00:00:00,
     * //   Fri Oct 10 2014 00:00:00
     * // ]
     */

    function eachDayOfInterval(dirtyInterval, options) {
        var _options$step;

        requiredArgs(1, arguments);
        var interval = dirtyInterval || {};
        var startDate = toDate(interval.start);
        var endDate = toDate(interval.end);
        var endTime = endDate.getTime(); // Throw an exception if start date is after end date or if any date is `Invalid Date`

        if (!(startDate.getTime() <= endTime)) {
            throw new RangeError('Invalid interval');
        }

        var dates = [];
        var currentDate = startDate;
        currentDate.setHours(0, 0, 0, 0);
        var step = Number((_options$step = options === null || options === void 0 ? void 0 : options.step) !== null && _options$step !== void 0 ? _options$step : 1);
        if (step < 1 || isNaN(step)) throw new RangeError('`options.step` must be a number greater than 1');

        while (currentDate.getTime() <= endTime) {
            dates.push(toDate(currentDate));
            currentDate.setDate(currentDate.getDate() + step);
            currentDate.setHours(0, 0, 0, 0);
        }

        return dates;
    }

    /**
     * @name endOfWeek
     * @category Week Helpers
     * @summary Return the end of a week for the given date.
     *
     * @description
     * Return the end of a week for the given date.
     * The result will be in the local timezone.
     *
     * @param {Date|Number} date - the original date
     * @param {Object} [options] - an object with options.
     * @param {Locale} [options.locale=defaultLocale] - the locale object. See [Locale]{@link https://date-fns.org/docs/Locale}
     * @param {0|1|2|3|4|5|6} [options.weekStartsOn=0] - the index of the first day of the week (0 - Sunday)
     * @returns {Date} the end of a week
     * @throws {TypeError} 1 argument required
     * @throws {RangeError} `options.weekStartsOn` must be between 0 and 6
     *
     * @example
     * // The end of a week for 2 September 2014 11:55:00:
     * const result = endOfWeek(new Date(2014, 8, 2, 11, 55, 0))
     * //=> Sat Sep 06 2014 23:59:59.999
     *
     * @example
     * // If the week starts on Monday, the end of the week for 2 September 2014 11:55:00:
     * const result = endOfWeek(new Date(2014, 8, 2, 11, 55, 0), { weekStartsOn: 1 })
     * //=> Sun Sep 07 2014 23:59:59.999
     */
    function endOfWeek(dirtyDate, options) {
        var _ref, _ref2, _ref3, _options$weekStartsOn, _options$locale, _options$locale$optio, _defaultOptions$local, _defaultOptions$local2;

        requiredArgs(1, arguments);
        var defaultOptions = getDefaultOptions();
        var weekStartsOn = toInteger((_ref = (_ref2 = (_ref3 = (_options$weekStartsOn = options === null || options === void 0 ? void 0 : options.weekStartsOn) !== null && _options$weekStartsOn !== void 0 ? _options$weekStartsOn : options === null || options === void 0 ? void 0 : (_options$locale = options.locale) === null || _options$locale === void 0 ? void 0 : (_options$locale$optio = _options$locale.options) === null || _options$locale$optio === void 0 ? void 0 : _options$locale$optio.weekStartsOn) !== null && _ref3 !== void 0 ? _ref3 : defaultOptions.weekStartsOn) !== null && _ref2 !== void 0 ? _ref2 : (_defaultOptions$local = defaultOptions.locale) === null || _defaultOptions$local === void 0 ? void 0 : (_defaultOptions$local2 = _defaultOptions$local.options) === null || _defaultOptions$local2 === void 0 ? void 0 : _defaultOptions$local2.weekStartsOn) !== null && _ref !== void 0 ? _ref : 0); // Test if weekStartsOn is between 0 and 6 _and_ is not NaN

        if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
            throw new RangeError('weekStartsOn must be between 0 and 6 inclusively');
        }

        var date = toDate(dirtyDate);
        var day = date.getDay();
        var diff = (day < weekStartsOn ? -7 : 0) + 6 - (day - weekStartsOn);
        date.setDate(date.getDate() + diff);
        date.setHours(23, 59, 59, 999);
        return date;
    }

    /**
     * @name subMilliseconds
     * @category Millisecond Helpers
     * @summary Subtract the specified number of milliseconds from the given date.
     *
     * @description
     * Subtract the specified number of milliseconds from the given date.
     *
     * @param {Date|Number} date - the date to be changed
     * @param {Number} amount - the amount of milliseconds to be subtracted. Positive decimals will be rounded using `Math.floor`, decimals less than zero will be rounded using `Math.ceil`.
     * @returns {Date} the new date with the milliseconds subtracted
     * @throws {TypeError} 2 arguments required
     *
     * @example
     * // Subtract 750 milliseconds from 10 July 2014 12:45:30.000:
     * const result = subMilliseconds(new Date(2014, 6, 10, 12, 45, 30, 0), 750)
     * //=> Thu Jul 10 2014 12:45:29.250
     */

    function subMilliseconds(dirtyDate, dirtyAmount) {
        requiredArgs(2, arguments);
        var amount = toInteger(dirtyAmount);
        return addMilliseconds(dirtyDate, -amount);
    }

    var MILLISECONDS_IN_DAY = 86400000;
    function getUTCDayOfYear(dirtyDate) {
        requiredArgs(1, arguments);
        var date = toDate(dirtyDate);
        var timestamp = date.getTime();
        date.setUTCMonth(0, 1);
        date.setUTCHours(0, 0, 0, 0);
        var startOfYearTimestamp = date.getTime();
        var difference = timestamp - startOfYearTimestamp;
        return Math.floor(difference / MILLISECONDS_IN_DAY) + 1;
    }

    function startOfUTCISOWeek(dirtyDate) {
        requiredArgs(1, arguments);
        var weekStartsOn = 1;
        var date = toDate(dirtyDate);
        var day = date.getUTCDay();
        var diff = (day < weekStartsOn ? 7 : 0) + day - weekStartsOn;
        date.setUTCDate(date.getUTCDate() - diff);
        date.setUTCHours(0, 0, 0, 0);
        return date;
    }

    function getUTCISOWeekYear(dirtyDate) {
        requiredArgs(1, arguments);
        var date = toDate(dirtyDate);
        var year = date.getUTCFullYear();
        var fourthOfJanuaryOfNextYear = new Date(0);
        fourthOfJanuaryOfNextYear.setUTCFullYear(year + 1, 0, 4);
        fourthOfJanuaryOfNextYear.setUTCHours(0, 0, 0, 0);
        var startOfNextYear = startOfUTCISOWeek(fourthOfJanuaryOfNextYear);
        var fourthOfJanuaryOfThisYear = new Date(0);
        fourthOfJanuaryOfThisYear.setUTCFullYear(year, 0, 4);
        fourthOfJanuaryOfThisYear.setUTCHours(0, 0, 0, 0);
        var startOfThisYear = startOfUTCISOWeek(fourthOfJanuaryOfThisYear);

        if (date.getTime() >= startOfNextYear.getTime()) {
            return year + 1;
        } else if (date.getTime() >= startOfThisYear.getTime()) {
            return year;
        } else {
            return year - 1;
        }
    }

    function startOfUTCISOWeekYear(dirtyDate) {
        requiredArgs(1, arguments);
        var year = getUTCISOWeekYear(dirtyDate);
        var fourthOfJanuary = new Date(0);
        fourthOfJanuary.setUTCFullYear(year, 0, 4);
        fourthOfJanuary.setUTCHours(0, 0, 0, 0);
        var date = startOfUTCISOWeek(fourthOfJanuary);
        return date;
    }

    var MILLISECONDS_IN_WEEK$2 = 604800000;
    function getUTCISOWeek(dirtyDate) {
        requiredArgs(1, arguments);
        var date = toDate(dirtyDate);
        var diff = startOfUTCISOWeek(date).getTime() - startOfUTCISOWeekYear(date).getTime(); // Round the number of days to the nearest integer
        // because the number of milliseconds in a week is not constant
        // (e.g. it's different in the week of the daylight saving time clock shift)

        return Math.round(diff / MILLISECONDS_IN_WEEK$2) + 1;
    }

    function startOfUTCWeek(dirtyDate, options) {
        var _ref, _ref2, _ref3, _options$weekStartsOn, _options$locale, _options$locale$optio, _defaultOptions$local, _defaultOptions$local2;

        requiredArgs(1, arguments);
        var defaultOptions = getDefaultOptions();
        var weekStartsOn = toInteger((_ref = (_ref2 = (_ref3 = (_options$weekStartsOn = options === null || options === void 0 ? void 0 : options.weekStartsOn) !== null && _options$weekStartsOn !== void 0 ? _options$weekStartsOn : options === null || options === void 0 ? void 0 : (_options$locale = options.locale) === null || _options$locale === void 0 ? void 0 : (_options$locale$optio = _options$locale.options) === null || _options$locale$optio === void 0 ? void 0 : _options$locale$optio.weekStartsOn) !== null && _ref3 !== void 0 ? _ref3 : defaultOptions.weekStartsOn) !== null && _ref2 !== void 0 ? _ref2 : (_defaultOptions$local = defaultOptions.locale) === null || _defaultOptions$local === void 0 ? void 0 : (_defaultOptions$local2 = _defaultOptions$local.options) === null || _defaultOptions$local2 === void 0 ? void 0 : _defaultOptions$local2.weekStartsOn) !== null && _ref !== void 0 ? _ref : 0); // Test if weekStartsOn is between 0 and 6 _and_ is not NaN

        if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
            throw new RangeError('weekStartsOn must be between 0 and 6 inclusively');
        }

        var date = toDate(dirtyDate);
        var day = date.getUTCDay();
        var diff = (day < weekStartsOn ? 7 : 0) + day - weekStartsOn;
        date.setUTCDate(date.getUTCDate() - diff);
        date.setUTCHours(0, 0, 0, 0);
        return date;
    }

    function getUTCWeekYear(dirtyDate, options) {
        var _ref, _ref2, _ref3, _options$firstWeekCon, _options$locale, _options$locale$optio, _defaultOptions$local, _defaultOptions$local2;

        requiredArgs(1, arguments);
        var date = toDate(dirtyDate);
        var year = date.getUTCFullYear();
        var defaultOptions = getDefaultOptions();
        var firstWeekContainsDate = toInteger((_ref = (_ref2 = (_ref3 = (_options$firstWeekCon = options === null || options === void 0 ? void 0 : options.firstWeekContainsDate) !== null && _options$firstWeekCon !== void 0 ? _options$firstWeekCon : options === null || options === void 0 ? void 0 : (_options$locale = options.locale) === null || _options$locale === void 0 ? void 0 : (_options$locale$optio = _options$locale.options) === null || _options$locale$optio === void 0 ? void 0 : _options$locale$optio.firstWeekContainsDate) !== null && _ref3 !== void 0 ? _ref3 : defaultOptions.firstWeekContainsDate) !== null && _ref2 !== void 0 ? _ref2 : (_defaultOptions$local = defaultOptions.locale) === null || _defaultOptions$local === void 0 ? void 0 : (_defaultOptions$local2 = _defaultOptions$local.options) === null || _defaultOptions$local2 === void 0 ? void 0 : _defaultOptions$local2.firstWeekContainsDate) !== null && _ref !== void 0 ? _ref : 1); // Test if weekStartsOn is between 1 and 7 _and_ is not NaN

        if (!(firstWeekContainsDate >= 1 && firstWeekContainsDate <= 7)) {
            throw new RangeError('firstWeekContainsDate must be between 1 and 7 inclusively');
        }

        var firstWeekOfNextYear = new Date(0);
        firstWeekOfNextYear.setUTCFullYear(year + 1, 0, firstWeekContainsDate);
        firstWeekOfNextYear.setUTCHours(0, 0, 0, 0);
        var startOfNextYear = startOfUTCWeek(firstWeekOfNextYear, options);
        var firstWeekOfThisYear = new Date(0);
        firstWeekOfThisYear.setUTCFullYear(year, 0, firstWeekContainsDate);
        firstWeekOfThisYear.setUTCHours(0, 0, 0, 0);
        var startOfThisYear = startOfUTCWeek(firstWeekOfThisYear, options);

        if (date.getTime() >= startOfNextYear.getTime()) {
            return year + 1;
        } else if (date.getTime() >= startOfThisYear.getTime()) {
            return year;
        } else {
            return year - 1;
        }
    }

    function startOfUTCWeekYear(dirtyDate, options) {
        var _ref, _ref2, _ref3, _options$firstWeekCon, _options$locale, _options$locale$optio, _defaultOptions$local, _defaultOptions$local2;

        requiredArgs(1, arguments);
        var defaultOptions = getDefaultOptions();
        var firstWeekContainsDate = toInteger((_ref = (_ref2 = (_ref3 = (_options$firstWeekCon = options === null || options === void 0 ? void 0 : options.firstWeekContainsDate) !== null && _options$firstWeekCon !== void 0 ? _options$firstWeekCon : options === null || options === void 0 ? void 0 : (_options$locale = options.locale) === null || _options$locale === void 0 ? void 0 : (_options$locale$optio = _options$locale.options) === null || _options$locale$optio === void 0 ? void 0 : _options$locale$optio.firstWeekContainsDate) !== null && _ref3 !== void 0 ? _ref3 : defaultOptions.firstWeekContainsDate) !== null && _ref2 !== void 0 ? _ref2 : (_defaultOptions$local = defaultOptions.locale) === null || _defaultOptions$local === void 0 ? void 0 : (_defaultOptions$local2 = _defaultOptions$local.options) === null || _defaultOptions$local2 === void 0 ? void 0 : _defaultOptions$local2.firstWeekContainsDate) !== null && _ref !== void 0 ? _ref : 1);
        var year = getUTCWeekYear(dirtyDate, options);
        var firstWeek = new Date(0);
        firstWeek.setUTCFullYear(year, 0, firstWeekContainsDate);
        firstWeek.setUTCHours(0, 0, 0, 0);
        var date = startOfUTCWeek(firstWeek, options);
        return date;
    }

    var MILLISECONDS_IN_WEEK$1 = 604800000;
    function getUTCWeek(dirtyDate, options) {
        requiredArgs(1, arguments);
        var date = toDate(dirtyDate);
        var diff = startOfUTCWeek(date, options).getTime() - startOfUTCWeekYear(date, options).getTime(); // Round the number of days to the nearest integer
        // because the number of milliseconds in a week is not constant
        // (e.g. it's different in the week of the daylight saving time clock shift)

        return Math.round(diff / MILLISECONDS_IN_WEEK$1) + 1;
    }

    function addLeadingZeros(number, targetLength) {
        var sign = number < 0 ? '-' : '';
        var output = Math.abs(number).toString();

        while (output.length < targetLength) {
            output = '0' + output;
        }

        return sign + output;
    }

    /*
    * |     | Unit                           |     | Unit                           |
    * |-----|--------------------------------|-----|--------------------------------|
    * |  a  | AM, PM                         |  A* |                                |
    * |  d  | Day of month                   |  D  |                                |
    * |  h  | Hour [1-12]                    |  H  | Hour [0-23]                    |
    * |  m  | Minute                         |  M  | Month                          |
    * |  s  | Second                         |  S  | Fraction of second             |
    * |  y  | Year (abs)                     |  Y  |                                |
    *
    * Letters marked by * are not implemented but reserved by Unicode standard.
    */

    var formatters$2 = {
        // Year
        y: function (date, token) {
            // From http://www.unicode.org/reports/tr35/tr35-31/tr35-dates.html#Date_Format_tokens
            // | Year     |     y | yy |   yyy |  yyyy | yyyyy |
            // |----------|-------|----|-------|-------|-------|
            // | AD 1     |     1 | 01 |   001 |  0001 | 00001 |
            // | AD 12    |    12 | 12 |   012 |  0012 | 00012 |
            // | AD 123   |   123 | 23 |   123 |  0123 | 00123 |
            // | AD 1234  |  1234 | 34 |  1234 |  1234 | 01234 |
            // | AD 12345 | 12345 | 45 | 12345 | 12345 | 12345 |
            var signedYear = date.getUTCFullYear(); // Returns 1 for 1 BC (which is year 0 in JavaScript)

            var year = signedYear > 0 ? signedYear : 1 - signedYear;
            return addLeadingZeros(token === 'yy' ? year % 100 : year, token.length);
        },
        // Month
        M: function (date, token) {
            var month = date.getUTCMonth();
            return token === 'M' ? String(month + 1) : addLeadingZeros(month + 1, 2);
        },
        // Day of the month
        d: function (date, token) {
            return addLeadingZeros(date.getUTCDate(), token.length);
        },
        // AM or PM
        a: function (date, token) {
            var dayPeriodEnumValue = date.getUTCHours() / 12 >= 1 ? 'pm' : 'am';

            switch (token) {
                case 'a':
                case 'aa':
                    return dayPeriodEnumValue.toUpperCase();

                case 'aaa':
                    return dayPeriodEnumValue;

                case 'aaaaa':
                    return dayPeriodEnumValue[0];

                case 'aaaa':
                default:
                    return dayPeriodEnumValue === 'am' ? 'a.m.' : 'p.m.';
            }
        },
        // Hour [1-12]
        h: function (date, token) {
            return addLeadingZeros(date.getUTCHours() % 12 || 12, token.length);
        },
        // Hour [0-23]
        H: function (date, token) {
            return addLeadingZeros(date.getUTCHours(), token.length);
        },
        // Minute
        m: function (date, token) {
            return addLeadingZeros(date.getUTCMinutes(), token.length);
        },
        // Second
        s: function (date, token) {
            return addLeadingZeros(date.getUTCSeconds(), token.length);
        },
        // Fraction of second
        S: function (date, token) {
            var numberOfDigits = token.length;
            var milliseconds = date.getUTCMilliseconds();
            var fractionalSeconds = Math.floor(milliseconds * Math.pow(10, numberOfDigits - 3));
            return addLeadingZeros(fractionalSeconds, token.length);
        }
    };
    var formatters$3 = formatters$2;

    var dayPeriodEnum = {
        am: 'am',
        pm: 'pm',
        midnight: 'midnight',
        noon: 'noon',
        morning: 'morning',
        afternoon: 'afternoon',
        evening: 'evening',
        night: 'night'
    };

    /*
    * |     | Unit                           |     | Unit                           |
    * |-----|--------------------------------|-----|--------------------------------|
    * |  a  | AM, PM                         |  A* | Milliseconds in day            |
    * |  b  | AM, PM, noon, midnight         |  B  | Flexible day period            |
    * |  c  | Stand-alone local day of week  |  C* | Localized hour w/ day period   |
    * |  d  | Day of month                   |  D  | Day of year                    |
    * |  e  | Local day of week              |  E  | Day of week                    |
    * |  f  |                                |  F* | Day of week in month           |
    * |  g* | Modified Julian day            |  G  | Era                            |
    * |  h  | Hour [1-12]                    |  H  | Hour [0-23]                    |
    * |  i! | ISO day of week                |  I! | ISO week of year               |
    * |  j* | Localized hour w/ day period   |  J* | Localized hour w/o day period  |
    * |  k  | Hour [1-24]                    |  K  | Hour [0-11]                    |
    * |  l* | (deprecated)                   |  L  | Stand-alone month              |
    * |  m  | Minute                         |  M  | Month                          |
    * |  n  |                                |  N  |                                |
    * |  o! | Ordinal number modifier        |  O  | Timezone (GMT)                 |
    * |  p! | Long localized time            |  P! | Long localized date            |
    * |  q  | Stand-alone quarter            |  Q  | Quarter                        |
    * |  r* | Related Gregorian year         |  R! | ISO week-numbering year        |
    * |  s  | Second                         |  S  | Fraction of second             |
    * |  t! | Seconds timestamp              |  T! | Milliseconds timestamp         |
    * |  u  | Extended year                  |  U* | Cyclic year                    |
    * |  v* | Timezone (generic non-locat.)  |  V* | Timezone (location)            |
    * |  w  | Local week of year             |  W* | Week of month                  |
    * |  x  | Timezone (ISO-8601 w/o Z)      |  X  | Timezone (ISO-8601)            |
    * |  y  | Year (abs)                     |  Y  | Local week-numbering year      |
    * |  z  | Timezone (specific non-locat.) |  Z* | Timezone (aliases)             |
    *
    * Letters marked by * are not implemented but reserved by Unicode standard.
    *
    * Letters marked by ! are non-standard, but implemented by date-fns:
    * - `o` modifies the previous token to turn it into an ordinal (see `format` docs)
    * - `i` is ISO day of week. For `i` and `ii` is returns numeric ISO week days,
    *   i.e. 7 for Sunday, 1 for Monday, etc.
    * - `I` is ISO week of year, as opposed to `w` which is local week of year.
    * - `R` is ISO week-numbering year, as opposed to `Y` which is local week-numbering year.
    *   `R` is supposed to be used in conjunction with `I` and `i`
    *   for universal ISO week-numbering date, whereas
    *   `Y` is supposed to be used in conjunction with `w` and `e`
    *   for week-numbering date specific to the locale.
    * - `P` is long localized date format
    * - `p` is long localized time format
    */
    var formatters = {
        // Era
        G: function (date, token, localize) {
            var era = date.getUTCFullYear() > 0 ? 1 : 0;

            switch (token) {
                // AD, BC
                case 'G':
                case 'GG':
                case 'GGG':
                    return localize.era(era, {
                        width: 'abbreviated'
                    });
                // A, B

                case 'GGGGG':
                    return localize.era(era, {
                        width: 'narrow'
                    });
                // Anno Domini, Before Christ

                case 'GGGG':
                default:
                    return localize.era(era, {
                        width: 'wide'
                    });
            }
        },
        // Year
        y: function (date, token, localize) {
            // Ordinal number
            if (token === 'yo') {
                var signedYear = date.getUTCFullYear(); // Returns 1 for 1 BC (which is year 0 in JavaScript)

                var year = signedYear > 0 ? signedYear : 1 - signedYear;
                return localize.ordinalNumber(year, {
                    unit: 'year'
                });
            }

            return formatters$3.y(date, token);
        },
        // Local week-numbering year
        Y: function (date, token, localize, options) {
            var signedWeekYear = getUTCWeekYear(date, options); // Returns 1 for 1 BC (which is year 0 in JavaScript)

            var weekYear = signedWeekYear > 0 ? signedWeekYear : 1 - signedWeekYear; // Two digit year

            if (token === 'YY') {
                var twoDigitYear = weekYear % 100;
                return addLeadingZeros(twoDigitYear, 2);
            } // Ordinal number


            if (token === 'Yo') {
                return localize.ordinalNumber(weekYear, {
                    unit: 'year'
                });
            } // Padding


            return addLeadingZeros(weekYear, token.length);
        },
        // ISO week-numbering year
        R: function (date, token) {
            var isoWeekYear = getUTCISOWeekYear(date); // Padding

            return addLeadingZeros(isoWeekYear, token.length);
        },
        // Extended year. This is a single number designating the year of this calendar system.
        // The main difference between `y` and `u` localizers are B.C. years:
        // | Year | `y` | `u` |
        // |------|-----|-----|
        // | AC 1 |   1 |   1 |
        // | BC 1 |   1 |   0 |
        // | BC 2 |   2 |  -1 |
        // Also `yy` always returns the last two digits of a year,
        // while `uu` pads single digit years to 2 characters and returns other years unchanged.
        u: function (date, token) {
            var year = date.getUTCFullYear();
            return addLeadingZeros(year, token.length);
        },
        // Quarter
        Q: function (date, token, localize) {
            var quarter = Math.ceil((date.getUTCMonth() + 1) / 3);

            switch (token) {
                // 1, 2, 3, 4
                case 'Q':
                    return String(quarter);
                // 01, 02, 03, 04

                case 'QQ':
                    return addLeadingZeros(quarter, 2);
                // 1st, 2nd, 3rd, 4th

                case 'Qo':
                    return localize.ordinalNumber(quarter, {
                        unit: 'quarter'
                    });
                // Q1, Q2, Q3, Q4

                case 'QQQ':
                    return localize.quarter(quarter, {
                        width: 'abbreviated',
                        context: 'formatting'
                    });
                // 1, 2, 3, 4 (narrow quarter; could be not numerical)

                case 'QQQQQ':
                    return localize.quarter(quarter, {
                        width: 'narrow',
                        context: 'formatting'
                    });
                // 1st quarter, 2nd quarter, ...

                case 'QQQQ':
                default:
                    return localize.quarter(quarter, {
                        width: 'wide',
                        context: 'formatting'
                    });
            }
        },
        // Stand-alone quarter
        q: function (date, token, localize) {
            var quarter = Math.ceil((date.getUTCMonth() + 1) / 3);

            switch (token) {
                // 1, 2, 3, 4
                case 'q':
                    return String(quarter);
                // 01, 02, 03, 04

                case 'qq':
                    return addLeadingZeros(quarter, 2);
                // 1st, 2nd, 3rd, 4th

                case 'qo':
                    return localize.ordinalNumber(quarter, {
                        unit: 'quarter'
                    });
                // Q1, Q2, Q3, Q4

                case 'qqq':
                    return localize.quarter(quarter, {
                        width: 'abbreviated',
                        context: 'standalone'
                    });
                // 1, 2, 3, 4 (narrow quarter; could be not numerical)

                case 'qqqqq':
                    return localize.quarter(quarter, {
                        width: 'narrow',
                        context: 'standalone'
                    });
                // 1st quarter, 2nd quarter, ...

                case 'qqqq':
                default:
                    return localize.quarter(quarter, {
                        width: 'wide',
                        context: 'standalone'
                    });
            }
        },
        // Month
        M: function (date, token, localize) {
            var month = date.getUTCMonth();

            switch (token) {
                case 'M':
                case 'MM':
                    return formatters$3.M(date, token);
                // 1st, 2nd, ..., 12th

                case 'Mo':
                    return localize.ordinalNumber(month + 1, {
                        unit: 'month'
                    });
                // Jan, Feb, ..., Dec

                case 'MMM':
                    return localize.month(month, {
                        width: 'abbreviated',
                        context: 'formatting'
                    });
                // J, F, ..., D

                case 'MMMMM':
                    return localize.month(month, {
                        width: 'narrow',
                        context: 'formatting'
                    });
                // January, February, ..., December

                case 'MMMM':
                default:
                    return localize.month(month, {
                        width: 'wide',
                        context: 'formatting'
                    });
            }
        },
        // Stand-alone month
        L: function (date, token, localize) {
            var month = date.getUTCMonth();

            switch (token) {
                // 1, 2, ..., 12
                case 'L':
                    return String(month + 1);
                // 01, 02, ..., 12

                case 'LL':
                    return addLeadingZeros(month + 1, 2);
                // 1st, 2nd, ..., 12th

                case 'Lo':
                    return localize.ordinalNumber(month + 1, {
                        unit: 'month'
                    });
                // Jan, Feb, ..., Dec

                case 'LLL':
                    return localize.month(month, {
                        width: 'abbreviated',
                        context: 'standalone'
                    });
                // J, F, ..., D

                case 'LLLLL':
                    return localize.month(month, {
                        width: 'narrow',
                        context: 'standalone'
                    });
                // January, February, ..., December

                case 'LLLL':
                default:
                    return localize.month(month, {
                        width: 'wide',
                        context: 'standalone'
                    });
            }
        },
        // Local week of year
        w: function (date, token, localize, options) {
            var week = getUTCWeek(date, options);

            if (token === 'wo') {
                return localize.ordinalNumber(week, {
                    unit: 'week'
                });
            }

            return addLeadingZeros(week, token.length);
        },
        // ISO week of year
        I: function (date, token, localize) {
            var isoWeek = getUTCISOWeek(date);

            if (token === 'Io') {
                return localize.ordinalNumber(isoWeek, {
                    unit: 'week'
                });
            }

            return addLeadingZeros(isoWeek, token.length);
        },
        // Day of the month
        d: function (date, token, localize) {
            if (token === 'do') {
                return localize.ordinalNumber(date.getUTCDate(), {
                    unit: 'date'
                });
            }

            return formatters$3.d(date, token);
        },
        // Day of year
        D: function (date, token, localize) {
            var dayOfYear = getUTCDayOfYear(date);

            if (token === 'Do') {
                return localize.ordinalNumber(dayOfYear, {
                    unit: 'dayOfYear'
                });
            }

            return addLeadingZeros(dayOfYear, token.length);
        },
        // Day of week
        E: function (date, token, localize) {
            var dayOfWeek = date.getUTCDay();

            switch (token) {
                // Tue
                case 'E':
                case 'EE':
                case 'EEE':
                    return localize.day(dayOfWeek, {
                        width: 'abbreviated',
                        context: 'formatting'
                    });
                // T

                case 'EEEEE':
                    return localize.day(dayOfWeek, {
                        width: 'narrow',
                        context: 'formatting'
                    });
                // Tu

                case 'EEEEEE':
                    return localize.day(dayOfWeek, {
                        width: 'short',
                        context: 'formatting'
                    });
                // Tuesday

                case 'EEEE':
                default:
                    return localize.day(dayOfWeek, {
                        width: 'wide',
                        context: 'formatting'
                    });
            }
        },
        // Local day of week
        e: function (date, token, localize, options) {
            var dayOfWeek = date.getUTCDay();
            var localDayOfWeek = (dayOfWeek - options.weekStartsOn + 8) % 7 || 7;

            switch (token) {
                // Numerical value (Nth day of week with current locale or weekStartsOn)
                case 'e':
                    return String(localDayOfWeek);
                // Padded numerical value

                case 'ee':
                    return addLeadingZeros(localDayOfWeek, 2);
                // 1st, 2nd, ..., 7th

                case 'eo':
                    return localize.ordinalNumber(localDayOfWeek, {
                        unit: 'day'
                    });

                case 'eee':
                    return localize.day(dayOfWeek, {
                        width: 'abbreviated',
                        context: 'formatting'
                    });
                // T

                case 'eeeee':
                    return localize.day(dayOfWeek, {
                        width: 'narrow',
                        context: 'formatting'
                    });
                // Tu

                case 'eeeeee':
                    return localize.day(dayOfWeek, {
                        width: 'short',
                        context: 'formatting'
                    });
                // Tuesday

                case 'eeee':
                default:
                    return localize.day(dayOfWeek, {
                        width: 'wide',
                        context: 'formatting'
                    });
            }
        },
        // Stand-alone local day of week
        c: function (date, token, localize, options) {
            var dayOfWeek = date.getUTCDay();
            var localDayOfWeek = (dayOfWeek - options.weekStartsOn + 8) % 7 || 7;

            switch (token) {
                // Numerical value (same as in `e`)
                case 'c':
                    return String(localDayOfWeek);
                // Padded numerical value

                case 'cc':
                    return addLeadingZeros(localDayOfWeek, token.length);
                // 1st, 2nd, ..., 7th

                case 'co':
                    return localize.ordinalNumber(localDayOfWeek, {
                        unit: 'day'
                    });

                case 'ccc':
                    return localize.day(dayOfWeek, {
                        width: 'abbreviated',
                        context: 'standalone'
                    });
                // T

                case 'ccccc':
                    return localize.day(dayOfWeek, {
                        width: 'narrow',
                        context: 'standalone'
                    });
                // Tu

                case 'cccccc':
                    return localize.day(dayOfWeek, {
                        width: 'short',
                        context: 'standalone'
                    });
                // Tuesday

                case 'cccc':
                default:
                    return localize.day(dayOfWeek, {
                        width: 'wide',
                        context: 'standalone'
                    });
            }
        },
        // ISO day of week
        i: function (date, token, localize) {
            var dayOfWeek = date.getUTCDay();
            var isoDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;

            switch (token) {
                // 2
                case 'i':
                    return String(isoDayOfWeek);
                // 02

                case 'ii':
                    return addLeadingZeros(isoDayOfWeek, token.length);
                // 2nd

                case 'io':
                    return localize.ordinalNumber(isoDayOfWeek, {
                        unit: 'day'
                    });
                // Tue

                case 'iii':
                    return localize.day(dayOfWeek, {
                        width: 'abbreviated',
                        context: 'formatting'
                    });
                // T

                case 'iiiii':
                    return localize.day(dayOfWeek, {
                        width: 'narrow',
                        context: 'formatting'
                    });
                // Tu

                case 'iiiiii':
                    return localize.day(dayOfWeek, {
                        width: 'short',
                        context: 'formatting'
                    });
                // Tuesday

                case 'iiii':
                default:
                    return localize.day(dayOfWeek, {
                        width: 'wide',
                        context: 'formatting'
                    });
            }
        },
        // AM or PM
        a: function (date, token, localize) {
            var hours = date.getUTCHours();
            var dayPeriodEnumValue = hours / 12 >= 1 ? 'pm' : 'am';

            switch (token) {
                case 'a':
                case 'aa':
                    return localize.dayPeriod(dayPeriodEnumValue, {
                        width: 'abbreviated',
                        context: 'formatting'
                    });

                case 'aaa':
                    return localize.dayPeriod(dayPeriodEnumValue, {
                        width: 'abbreviated',
                        context: 'formatting'
                    }).toLowerCase();

                case 'aaaaa':
                    return localize.dayPeriod(dayPeriodEnumValue, {
                        width: 'narrow',
                        context: 'formatting'
                    });

                case 'aaaa':
                default:
                    return localize.dayPeriod(dayPeriodEnumValue, {
                        width: 'wide',
                        context: 'formatting'
                    });
            }
        },
        // AM, PM, midnight, noon
        b: function (date, token, localize) {
            var hours = date.getUTCHours();
            var dayPeriodEnumValue;

            if (hours === 12) {
                dayPeriodEnumValue = dayPeriodEnum.noon;
            } else if (hours === 0) {
                dayPeriodEnumValue = dayPeriodEnum.midnight;
            } else {
                dayPeriodEnumValue = hours / 12 >= 1 ? 'pm' : 'am';
            }

            switch (token) {
                case 'b':
                case 'bb':
                    return localize.dayPeriod(dayPeriodEnumValue, {
                        width: 'abbreviated',
                        context: 'formatting'
                    });

                case 'bbb':
                    return localize.dayPeriod(dayPeriodEnumValue, {
                        width: 'abbreviated',
                        context: 'formatting'
                    }).toLowerCase();

                case 'bbbbb':
                    return localize.dayPeriod(dayPeriodEnumValue, {
                        width: 'narrow',
                        context: 'formatting'
                    });

                case 'bbbb':
                default:
                    return localize.dayPeriod(dayPeriodEnumValue, {
                        width: 'wide',
                        context: 'formatting'
                    });
            }
        },
        // in the morning, in the afternoon, in the evening, at night
        B: function (date, token, localize) {
            var hours = date.getUTCHours();
            var dayPeriodEnumValue;

            if (hours >= 17) {
                dayPeriodEnumValue = dayPeriodEnum.evening;
            } else if (hours >= 12) {
                dayPeriodEnumValue = dayPeriodEnum.afternoon;
            } else if (hours >= 4) {
                dayPeriodEnumValue = dayPeriodEnum.morning;
            } else {
                dayPeriodEnumValue = dayPeriodEnum.night;
            }

            switch (token) {
                case 'B':
                case 'BB':
                case 'BBB':
                    return localize.dayPeriod(dayPeriodEnumValue, {
                        width: 'abbreviated',
                        context: 'formatting'
                    });

                case 'BBBBB':
                    return localize.dayPeriod(dayPeriodEnumValue, {
                        width: 'narrow',
                        context: 'formatting'
                    });

                case 'BBBB':
                default:
                    return localize.dayPeriod(dayPeriodEnumValue, {
                        width: 'wide',
                        context: 'formatting'
                    });
            }
        },
        // Hour [1-12]
        h: function (date, token, localize) {
            if (token === 'ho') {
                var hours = date.getUTCHours() % 12;
                if (hours === 0) hours = 12;
                return localize.ordinalNumber(hours, {
                    unit: 'hour'
                });
            }

            return formatters$3.h(date, token);
        },
        // Hour [0-23]
        H: function (date, token, localize) {
            if (token === 'Ho') {
                return localize.ordinalNumber(date.getUTCHours(), {
                    unit: 'hour'
                });
            }

            return formatters$3.H(date, token);
        },
        // Hour [0-11]
        K: function (date, token, localize) {
            var hours = date.getUTCHours() % 12;

            if (token === 'Ko') {
                return localize.ordinalNumber(hours, {
                    unit: 'hour'
                });
            }

            return addLeadingZeros(hours, token.length);
        },
        // Hour [1-24]
        k: function (date, token, localize) {
            var hours = date.getUTCHours();
            if (hours === 0) hours = 24;

            if (token === 'ko') {
                return localize.ordinalNumber(hours, {
                    unit: 'hour'
                });
            }

            return addLeadingZeros(hours, token.length);
        },
        // Minute
        m: function (date, token, localize) {
            if (token === 'mo') {
                return localize.ordinalNumber(date.getUTCMinutes(), {
                    unit: 'minute'
                });
            }

            return formatters$3.m(date, token);
        },
        // Second
        s: function (date, token, localize) {
            if (token === 'so') {
                return localize.ordinalNumber(date.getUTCSeconds(), {
                    unit: 'second'
                });
            }

            return formatters$3.s(date, token);
        },
        // Fraction of second
        S: function (date, token) {
            return formatters$3.S(date, token);
        },
        // Timezone (ISO-8601. If offset is 0, output is always `'Z'`)
        X: function (date, token, _localize, options) {
            var originalDate = options._originalDate || date;
            var timezoneOffset = originalDate.getTimezoneOffset();

            if (timezoneOffset === 0) {
                return 'Z';
            }

            switch (token) {
                // Hours and optional minutes
                case 'X':
                    return formatTimezoneWithOptionalMinutes(timezoneOffset);
                // Hours, minutes and optional seconds without `:` delimiter
                // Note: neither ISO-8601 nor JavaScript supports seconds in timezone offsets
                // so this token always has the same output as `XX`

                case 'XXXX':
                case 'XX':
                    // Hours and minutes without `:` delimiter
                    return formatTimezone(timezoneOffset);
                // Hours, minutes and optional seconds with `:` delimiter
                // Note: neither ISO-8601 nor JavaScript supports seconds in timezone offsets
                // so this token always has the same output as `XXX`

                case 'XXXXX':
                case 'XXX': // Hours and minutes with `:` delimiter

                default:
                    return formatTimezone(timezoneOffset, ':');
            }
        },
        // Timezone (ISO-8601. If offset is 0, output is `'+00:00'` or equivalent)
        x: function (date, token, _localize, options) {
            var originalDate = options._originalDate || date;
            var timezoneOffset = originalDate.getTimezoneOffset();

            switch (token) {
                // Hours and optional minutes
                case 'x':
                    return formatTimezoneWithOptionalMinutes(timezoneOffset);
                // Hours, minutes and optional seconds without `:` delimiter
                // Note: neither ISO-8601 nor JavaScript supports seconds in timezone offsets
                // so this token always has the same output as `xx`

                case 'xxxx':
                case 'xx':
                    // Hours and minutes without `:` delimiter
                    return formatTimezone(timezoneOffset);
                // Hours, minutes and optional seconds with `:` delimiter
                // Note: neither ISO-8601 nor JavaScript supports seconds in timezone offsets
                // so this token always has the same output as `xxx`

                case 'xxxxx':
                case 'xxx': // Hours and minutes with `:` delimiter

                default:
                    return formatTimezone(timezoneOffset, ':');
            }
        },
        // Timezone (GMT)
        O: function (date, token, _localize, options) {
            var originalDate = options._originalDate || date;
            var timezoneOffset = originalDate.getTimezoneOffset();

            switch (token) {
                // Short
                case 'O':
                case 'OO':
                case 'OOO':
                    return 'GMT' + formatTimezoneShort(timezoneOffset, ':');
                // Long

                case 'OOOO':
                default:
                    return 'GMT' + formatTimezone(timezoneOffset, ':');
            }
        },
        // Timezone (specific non-location)
        z: function (date, token, _localize, options) {
            var originalDate = options._originalDate || date;
            var timezoneOffset = originalDate.getTimezoneOffset();

            switch (token) {
                // Short
                case 'z':
                case 'zz':
                case 'zzz':
                    return 'GMT' + formatTimezoneShort(timezoneOffset, ':');
                // Long

                case 'zzzz':
                default:
                    return 'GMT' + formatTimezone(timezoneOffset, ':');
            }
        },
        // Seconds timestamp
        t: function (date, token, _localize, options) {
            var originalDate = options._originalDate || date;
            var timestamp = Math.floor(originalDate.getTime() / 1000);
            return addLeadingZeros(timestamp, token.length);
        },
        // Milliseconds timestamp
        T: function (date, token, _localize, options) {
            var originalDate = options._originalDate || date;
            var timestamp = originalDate.getTime();
            return addLeadingZeros(timestamp, token.length);
        }
    };

    function formatTimezoneShort(offset, dirtyDelimiter) {
        var sign = offset > 0 ? '-' : '+';
        var absOffset = Math.abs(offset);
        var hours = Math.floor(absOffset / 60);
        var minutes = absOffset % 60;

        if (minutes === 0) {
            return sign + String(hours);
        }

        var delimiter = dirtyDelimiter || '';
        return sign + String(hours) + delimiter + addLeadingZeros(minutes, 2);
    }

    function formatTimezoneWithOptionalMinutes(offset, dirtyDelimiter) {
        if (offset % 60 === 0) {
            var sign = offset > 0 ? '-' : '+';
            return sign + addLeadingZeros(Math.abs(offset) / 60, 2);
        }

        return formatTimezone(offset, dirtyDelimiter);
    }

    function formatTimezone(offset, dirtyDelimiter) {
        var delimiter = dirtyDelimiter || '';
        var sign = offset > 0 ? '-' : '+';
        var absOffset = Math.abs(offset);
        var hours = addLeadingZeros(Math.floor(absOffset / 60), 2);
        var minutes = addLeadingZeros(absOffset % 60, 2);
        return sign + hours + delimiter + minutes;
    }

    var formatters$1 = formatters;

    var dateLongFormatter = function (pattern, formatLong) {
        switch (pattern) {
            case 'P':
                return formatLong.date({
                    width: 'short'
                });

            case 'PP':
                return formatLong.date({
                    width: 'medium'
                });

            case 'PPP':
                return formatLong.date({
                    width: 'long'
                });

            case 'PPPP':
            default:
                return formatLong.date({
                    width: 'full'
                });
        }
    };

    var timeLongFormatter = function (pattern, formatLong) {
        switch (pattern) {
            case 'p':
                return formatLong.time({
                    width: 'short'
                });

            case 'pp':
                return formatLong.time({
                    width: 'medium'
                });

            case 'ppp':
                return formatLong.time({
                    width: 'long'
                });

            case 'pppp':
            default:
                return formatLong.time({
                    width: 'full'
                });
        }
    };

    var dateTimeLongFormatter = function (pattern, formatLong) {
        var matchResult = pattern.match(/(P+)(p+)?/) || [];
        var datePattern = matchResult[1];
        var timePattern = matchResult[2];

        if (!timePattern) {
            return dateLongFormatter(pattern, formatLong);
        }

        var dateTimeFormat;

        switch (datePattern) {
            case 'P':
                dateTimeFormat = formatLong.dateTime({
                    width: 'short'
                });
                break;

            case 'PP':
                dateTimeFormat = formatLong.dateTime({
                    width: 'medium'
                });
                break;

            case 'PPP':
                dateTimeFormat = formatLong.dateTime({
                    width: 'long'
                });
                break;

            case 'PPPP':
            default:
                dateTimeFormat = formatLong.dateTime({
                    width: 'full'
                });
                break;
        }

        return dateTimeFormat.replace('{{date}}', dateLongFormatter(datePattern, formatLong)).replace('{{time}}', timeLongFormatter(timePattern, formatLong));
    };

    var longFormatters = {
        p: timeLongFormatter,
        P: dateTimeLongFormatter
    };
    var longFormatters$1 = longFormatters;

    var protectedDayOfYearTokens = ['D', 'DD'];
    var protectedWeekYearTokens = ['YY', 'YYYY'];
    function isProtectedDayOfYearToken(token) {
        return protectedDayOfYearTokens.indexOf(token) !== -1;
    }
    function isProtectedWeekYearToken(token) {
        return protectedWeekYearTokens.indexOf(token) !== -1;
    }
    function throwProtectedError(token, format, input) {
        if (token === 'YYYY') {
            throw new RangeError("Use `yyyy` instead of `YYYY` (in `".concat(format, "`) for formatting years to the input `").concat(input, "`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md"));
        } else if (token === 'YY') {
            throw new RangeError("Use `yy` instead of `YY` (in `".concat(format, "`) for formatting years to the input `").concat(input, "`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md"));
        } else if (token === 'D') {
            throw new RangeError("Use `d` instead of `D` (in `".concat(format, "`) for formatting days of the month to the input `").concat(input, "`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md"));
        } else if (token === 'DD') {
            throw new RangeError("Use `dd` instead of `DD` (in `".concat(format, "`) for formatting days of the month to the input `").concat(input, "`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md"));
        }
    }

    var formatDistanceLocale = {
        lessThanXSeconds: {
            one: 'less than a second',
            other: 'less than {{count}} seconds'
        },
        xSeconds: {
            one: '1 second',
            other: '{{count}} seconds'
        },
        halfAMinute: 'half a minute',
        lessThanXMinutes: {
            one: 'less than a minute',
            other: 'less than {{count}} minutes'
        },
        xMinutes: {
            one: '1 minute',
            other: '{{count}} minutes'
        },
        aboutXHours: {
            one: 'about 1 hour',
            other: 'about {{count}} hours'
        },
        xHours: {
            one: '1 hour',
            other: '{{count}} hours'
        },
        xDays: {
            one: '1 day',
            other: '{{count}} days'
        },
        aboutXWeeks: {
            one: 'about 1 week',
            other: 'about {{count}} weeks'
        },
        xWeeks: {
            one: '1 week',
            other: '{{count}} weeks'
        },
        aboutXMonths: {
            one: 'about 1 month',
            other: 'about {{count}} months'
        },
        xMonths: {
            one: '1 month',
            other: '{{count}} months'
        },
        aboutXYears: {
            one: 'about 1 year',
            other: 'about {{count}} years'
        },
        xYears: {
            one: '1 year',
            other: '{{count}} years'
        },
        overXYears: {
            one: 'over 1 year',
            other: 'over {{count}} years'
        },
        almostXYears: {
            one: 'almost 1 year',
            other: 'almost {{count}} years'
        }
    };

    var formatDistance = function (token, count, options) {
        var result;
        var tokenValue = formatDistanceLocale[token];

        if (typeof tokenValue === 'string') {
            result = tokenValue;
        } else if (count === 1) {
            result = tokenValue.one;
        } else {
            result = tokenValue.other.replace('{{count}}', count.toString());
        }

        if (options !== null && options !== void 0 && options.addSuffix) {
            if (options.comparison && options.comparison > 0) {
                return 'in ' + result;
            } else {
                return result + ' ago';
            }
        }

        return result;
    };

    var formatDistance$1 = formatDistance;

    function buildFormatLongFn(args) {
        return function () {
            var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
            // TODO: Remove String()
            var width = options.width ? String(options.width) : args.defaultWidth;
            var format = args.formats[width] || args.formats[args.defaultWidth];
            return format;
        };
    }

    var dateFormats = {
        full: 'EEEE, MMMM do, y',
        long: 'MMMM do, y',
        medium: 'MMM d, y',
        short: 'MM/dd/yyyy'
    };
    var timeFormats = {
        full: 'h:mm:ss a zzzz',
        long: 'h:mm:ss a z',
        medium: 'h:mm:ss a',
        short: 'h:mm a'
    };
    var dateTimeFormats = {
        full: "{{date}} 'at' {{time}}",
        long: "{{date}} 'at' {{time}}",
        medium: '{{date}}, {{time}}',
        short: '{{date}}, {{time}}'
    };
    var formatLong = {
        date: buildFormatLongFn({
            formats: dateFormats,
            defaultWidth: 'full'
        }),
        time: buildFormatLongFn({
            formats: timeFormats,
            defaultWidth: 'full'
        }),
        dateTime: buildFormatLongFn({
            formats: dateTimeFormats,
            defaultWidth: 'full'
        })
    };
    var formatLong$1 = formatLong;

    var formatRelativeLocale = {
        lastWeek: "'last' eeee 'at' p",
        yesterday: "'yesterday at' p",
        today: "'today at' p",
        tomorrow: "'tomorrow at' p",
        nextWeek: "eeee 'at' p",
        other: 'P'
    };

    var formatRelative = function (token, _date, _baseDate, _options) {
        return formatRelativeLocale[token];
    };

    var formatRelative$1 = formatRelative;

    function buildLocalizeFn(args) {
        return function (dirtyIndex, options) {
            var context = options !== null && options !== void 0 && options.context ? String(options.context) : 'standalone';
            var valuesArray;

            if (context === 'formatting' && args.formattingValues) {
                var defaultWidth = args.defaultFormattingWidth || args.defaultWidth;
                var width = options !== null && options !== void 0 && options.width ? String(options.width) : defaultWidth;
                valuesArray = args.formattingValues[width] || args.formattingValues[defaultWidth];
            } else {
                var _defaultWidth = args.defaultWidth;

                var _width = options !== null && options !== void 0 && options.width ? String(options.width) : args.defaultWidth;

                valuesArray = args.values[_width] || args.values[_defaultWidth];
            }

            var index = args.argumentCallback ? args.argumentCallback(dirtyIndex) : dirtyIndex; // @ts-ignore: For some reason TypeScript just don't want to match it, no matter how hard we try. I challenge you to try to remove it!

            return valuesArray[index];
        };
    }

    var eraValues = {
        narrow: ['B', 'A'],
        abbreviated: ['BC', 'AD'],
        wide: ['Before Christ', 'Anno Domini']
    };
    var quarterValues = {
        narrow: ['1', '2', '3', '4'],
        abbreviated: ['Q1', 'Q2', 'Q3', 'Q4'],
        wide: ['1st quarter', '2nd quarter', '3rd quarter', '4th quarter']
    }; // Note: in English, the names of days of the week and months are capitalized.
    // If you are making a new locale based on this one, check if the same is true for the language you're working on.
    // Generally, formatted dates should look like they are in the middle of a sentence,
    // e.g. in Spanish language the weekdays and months should be in the lowercase.

    var monthValues = {
        narrow: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
        abbreviated: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        wide: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    };
    var dayValues = {
        narrow: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        short: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        abbreviated: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        wide: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    };
    var dayPeriodValues = {
        narrow: {
            am: 'a',
            pm: 'p',
            midnight: 'mi',
            noon: 'n',
            morning: 'morning',
            afternoon: 'afternoon',
            evening: 'evening',
            night: 'night'
        },
        abbreviated: {
            am: 'AM',
            pm: 'PM',
            midnight: 'midnight',
            noon: 'noon',
            morning: 'morning',
            afternoon: 'afternoon',
            evening: 'evening',
            night: 'night'
        },
        wide: {
            am: 'a.m.',
            pm: 'p.m.',
            midnight: 'midnight',
            noon: 'noon',
            morning: 'morning',
            afternoon: 'afternoon',
            evening: 'evening',
            night: 'night'
        }
    };
    var formattingDayPeriodValues = {
        narrow: {
            am: 'a',
            pm: 'p',
            midnight: 'mi',
            noon: 'n',
            morning: 'in the morning',
            afternoon: 'in the afternoon',
            evening: 'in the evening',
            night: 'at night'
        },
        abbreviated: {
            am: 'AM',
            pm: 'PM',
            midnight: 'midnight',
            noon: 'noon',
            morning: 'in the morning',
            afternoon: 'in the afternoon',
            evening: 'in the evening',
            night: 'at night'
        },
        wide: {
            am: 'a.m.',
            pm: 'p.m.',
            midnight: 'midnight',
            noon: 'noon',
            morning: 'in the morning',
            afternoon: 'in the afternoon',
            evening: 'in the evening',
            night: 'at night'
        }
    };

    var ordinalNumber = function (dirtyNumber, _options) {
        var number = Number(dirtyNumber); // If ordinal numbers depend on context, for example,
        // if they are different for different grammatical genders,
        // use `options.unit`.
        //
        // `unit` can be 'year', 'quarter', 'month', 'week', 'date', 'dayOfYear',
        // 'day', 'hour', 'minute', 'second'.

        var rem100 = number % 100;

        if (rem100 > 20 || rem100 < 10) {
            switch (rem100 % 10) {
                case 1:
                    return number + 'st';

                case 2:
                    return number + 'nd';

                case 3:
                    return number + 'rd';
            }
        }

        return number + 'th';
    };

    var localize = {
        ordinalNumber: ordinalNumber,
        era: buildLocalizeFn({
            values: eraValues,
            defaultWidth: 'wide'
        }),
        quarter: buildLocalizeFn({
            values: quarterValues,
            defaultWidth: 'wide',
            argumentCallback: function (quarter) {
                return quarter - 1;
            }
        }),
        month: buildLocalizeFn({
            values: monthValues,
            defaultWidth: 'wide'
        }),
        day: buildLocalizeFn({
            values: dayValues,
            defaultWidth: 'wide'
        }),
        dayPeriod: buildLocalizeFn({
            values: dayPeriodValues,
            defaultWidth: 'wide',
            formattingValues: formattingDayPeriodValues,
            defaultFormattingWidth: 'wide'
        })
    };
    var localize$1 = localize;

    function buildMatchFn(args) {
        return function (string) {
            var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            var width = options.width;
            var matchPattern = width && args.matchPatterns[width] || args.matchPatterns[args.defaultMatchWidth];
            var matchResult = string.match(matchPattern);

            if (!matchResult) {
                return null;
            }

            var matchedString = matchResult[0];
            var parsePatterns = width && args.parsePatterns[width] || args.parsePatterns[args.defaultParseWidth];
            var key = Array.isArray(parsePatterns) ? findIndex(parsePatterns, function (pattern) {
                return pattern.test(matchedString);
            }) : findKey(parsePatterns, function (pattern) {
                return pattern.test(matchedString);
            });
            var value;
            value = args.valueCallback ? args.valueCallback(key) : key;
            value = options.valueCallback ? options.valueCallback(value) : value;
            var rest = string.slice(matchedString.length);
            return {
                value: value,
                rest: rest
            };
        };
    }

    function findKey(object, predicate) {
        for (var key in object) {
            if (object.hasOwnProperty(key) && predicate(object[key])) {
                return key;
            }
        }

        return undefined;
    }

    function findIndex(array, predicate) {
        for (var key = 0; key < array.length; key++) {
            if (predicate(array[key])) {
                return key;
            }
        }

        return undefined;
    }

    function buildMatchPatternFn(args) {
        return function (string) {
            var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            var matchResult = string.match(args.matchPattern);
            if (!matchResult) return null;
            var matchedString = matchResult[0];
            var parseResult = string.match(args.parsePattern);
            if (!parseResult) return null;
            var value = args.valueCallback ? args.valueCallback(parseResult[0]) : parseResult[0];
            value = options.valueCallback ? options.valueCallback(value) : value;
            var rest = string.slice(matchedString.length);
            return {
                value: value,
                rest: rest
            };
        };
    }

    var matchOrdinalNumberPattern = /^(\d+)(th|st|nd|rd)?/i;
    var parseOrdinalNumberPattern = /\d+/i;
    var matchEraPatterns = {
        narrow: /^(b|a)/i,
        abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
        wide: /^(before christ|before common era|anno domini|common era)/i
    };
    var parseEraPatterns = {
        any: [/^b/i, /^(a|c)/i]
    };
    var matchQuarterPatterns = {
        narrow: /^[1234]/i,
        abbreviated: /^q[1234]/i,
        wide: /^[1234](th|st|nd|rd)? quarter/i
    };
    var parseQuarterPatterns = {
        any: [/1/i, /2/i, /3/i, /4/i]
    };
    var matchMonthPatterns = {
        narrow: /^[jfmasond]/i,
        abbreviated: /^(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/i,
        wide: /^(january|february|march|april|may|june|july|august|september|october|november|december)/i
    };
    var parseMonthPatterns = {
        narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
        any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^may/i, /^jun/i, /^jul/i, /^au/i, /^s/i, /^o/i, /^n/i, /^d/i]
    };
    var matchDayPatterns = {
        narrow: /^[smtwf]/i,
        short: /^(su|mo|tu|we|th|fr|sa)/i,
        abbreviated: /^(sun|mon|tue|wed|thu|fri|sat)/i,
        wide: /^(sunday|monday|tuesday|wednesday|thursday|friday|saturday)/i
    };
    var parseDayPatterns = {
        narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
        any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
    };
    var matchDayPeriodPatterns = {
        narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
        any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
    };
    var parseDayPeriodPatterns = {
        any: {
            am: /^a/i,
            pm: /^p/i,
            midnight: /^mi/i,
            noon: /^no/i,
            morning: /morning/i,
            afternoon: /afternoon/i,
            evening: /evening/i,
            night: /night/i
        }
    };
    var match = {
        ordinalNumber: buildMatchPatternFn({
            matchPattern: matchOrdinalNumberPattern,
            parsePattern: parseOrdinalNumberPattern,
            valueCallback: function (value) {
                return parseInt(value, 10);
            }
        }),
        era: buildMatchFn({
            matchPatterns: matchEraPatterns,
            defaultMatchWidth: 'wide',
            parsePatterns: parseEraPatterns,
            defaultParseWidth: 'any'
        }),
        quarter: buildMatchFn({
            matchPatterns: matchQuarterPatterns,
            defaultMatchWidth: 'wide',
            parsePatterns: parseQuarterPatterns,
            defaultParseWidth: 'any',
            valueCallback: function (index) {
                return index + 1;
            }
        }),
        month: buildMatchFn({
            matchPatterns: matchMonthPatterns,
            defaultMatchWidth: 'wide',
            parsePatterns: parseMonthPatterns,
            defaultParseWidth: 'any'
        }),
        day: buildMatchFn({
            matchPatterns: matchDayPatterns,
            defaultMatchWidth: 'wide',
            parsePatterns: parseDayPatterns,
            defaultParseWidth: 'any'
        }),
        dayPeriod: buildMatchFn({
            matchPatterns: matchDayPeriodPatterns,
            defaultMatchWidth: 'any',
            parsePatterns: parseDayPeriodPatterns,
            defaultParseWidth: 'any'
        })
    };
    var match$1 = match;

    /**
     * @type {Locale}
     * @category Locales
     * @summary English locale (United States).
     * @language English
     * @iso-639-2 eng
     * @author Sasha Koss [@kossnocorp]{@link https://github.com/kossnocorp}
     * @author Lesha Koss [@leshakoss]{@link https://github.com/leshakoss}
     */
    var locale = {
        code: 'en-US',
        formatDistance: formatDistance$1,
        formatLong: formatLong$1,
        formatRelative: formatRelative$1,
        localize: localize$1,
        match: match$1,
        options: {
            weekStartsOn: 0
            /* Sunday */
            ,
            firstWeekContainsDate: 1
        }
    };
    var defaultLocale = locale;

    // - [yYQqMLwIdDecihHKkms]o matches any available ordinal number token
    //   (one of the certain letters followed by `o`)
    // - (\w)\1* matches any sequences of the same letter
    // - '' matches two quote characters in a row
    // - '(''|[^'])+('|$) matches anything surrounded by two quote characters ('),
    //   except a single quote symbol, which ends the sequence.
    //   Two quote characters do not end the sequence.
    //   If there is no matching single quote
    //   then the sequence will continue until the end of the string.
    // - . matches any single character unmatched by previous parts of the RegExps

    var formattingTokensRegExp$1 = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g; // This RegExp catches symbols escaped by quotes, and also
    // sequences of symbols P, p, and the combinations like `PPPPPPPppppp`

    var longFormattingTokensRegExp$1 = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g;
    var escapedStringRegExp$1 = /^'([^]*?)'?$/;
    var doubleQuoteRegExp$1 = /''/g;
    var unescapedLatinCharacterRegExp$1 = /[a-zA-Z]/;
    /**
     * @name format
     * @category Common Helpers
     * @summary Format the date.
     *
     * @description
     * Return the formatted date string in the given format. The result may vary by locale.
     *
     * > ⚠️ Please note that the `format` tokens differ from Moment.js and other libraries.
     * > See: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
     *
     * The characters wrapped between two single quotes characters (') are escaped.
     * Two single quotes in a row, whether inside or outside a quoted sequence, represent a 'real' single quote.
     * (see the last example)
     *
     * Format of the string is based on Unicode Technical Standard #35:
     * https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
     * with a few additions (see note 7 below the table).
     *
     * Accepted patterns:
     * | Unit                            | Pattern | Result examples                   | Notes |
     * |---------------------------------|---------|-----------------------------------|-------|
     * | Era                             | G..GGG  | AD, BC                            |       |
     * |                                 | GGGG    | Anno Domini, Before Christ        | 2     |
     * |                                 | GGGGG   | A, B                              |       |
     * | Calendar year                   | y       | 44, 1, 1900, 2017                 | 5     |
     * |                                 | yo      | 44th, 1st, 0th, 17th              | 5,7   |
     * |                                 | yy      | 44, 01, 00, 17                    | 5     |
     * |                                 | yyy     | 044, 001, 1900, 2017              | 5     |
     * |                                 | yyyy    | 0044, 0001, 1900, 2017            | 5     |
     * |                                 | yyyyy   | ...                               | 3,5   |
     * | Local week-numbering year       | Y       | 44, 1, 1900, 2017                 | 5     |
     * |                                 | Yo      | 44th, 1st, 1900th, 2017th         | 5,7   |
     * |                                 | YY      | 44, 01, 00, 17                    | 5,8   |
     * |                                 | YYY     | 044, 001, 1900, 2017              | 5     |
     * |                                 | YYYY    | 0044, 0001, 1900, 2017            | 5,8   |
     * |                                 | YYYYY   | ...                               | 3,5   |
     * | ISO week-numbering year         | R       | -43, 0, 1, 1900, 2017             | 5,7   |
     * |                                 | RR      | -43, 00, 01, 1900, 2017           | 5,7   |
     * |                                 | RRR     | -043, 000, 001, 1900, 2017        | 5,7   |
     * |                                 | RRRR    | -0043, 0000, 0001, 1900, 2017     | 5,7   |
     * |                                 | RRRRR   | ...                               | 3,5,7 |
     * | Extended year                   | u       | -43, 0, 1, 1900, 2017             | 5     |
     * |                                 | uu      | -43, 01, 1900, 2017               | 5     |
     * |                                 | uuu     | -043, 001, 1900, 2017             | 5     |
     * |                                 | uuuu    | -0043, 0001, 1900, 2017           | 5     |
     * |                                 | uuuuu   | ...                               | 3,5   |
     * | Quarter (formatting)            | Q       | 1, 2, 3, 4                        |       |
     * |                                 | Qo      | 1st, 2nd, 3rd, 4th                | 7     |
     * |                                 | QQ      | 01, 02, 03, 04                    |       |
     * |                                 | QQQ     | Q1, Q2, Q3, Q4                    |       |
     * |                                 | QQQQ    | 1st quarter, 2nd quarter, ...     | 2     |
     * |                                 | QQQQQ   | 1, 2, 3, 4                        | 4     |
     * | Quarter (stand-alone)           | q       | 1, 2, 3, 4                        |       |
     * |                                 | qo      | 1st, 2nd, 3rd, 4th                | 7     |
     * |                                 | qq      | 01, 02, 03, 04                    |       |
     * |                                 | qqq     | Q1, Q2, Q3, Q4                    |       |
     * |                                 | qqqq    | 1st quarter, 2nd quarter, ...     | 2     |
     * |                                 | qqqqq   | 1, 2, 3, 4                        | 4     |
     * | Month (formatting)              | M       | 1, 2, ..., 12                     |       |
     * |                                 | Mo      | 1st, 2nd, ..., 12th               | 7     |
     * |                                 | MM      | 01, 02, ..., 12                   |       |
     * |                                 | MMM     | Jan, Feb, ..., Dec                |       |
     * |                                 | MMMM    | January, February, ..., December  | 2     |
     * |                                 | MMMMM   | J, F, ..., D                      |       |
     * | Month (stand-alone)             | L       | 1, 2, ..., 12                     |       |
     * |                                 | Lo      | 1st, 2nd, ..., 12th               | 7     |
     * |                                 | LL      | 01, 02, ..., 12                   |       |
     * |                                 | LLL     | Jan, Feb, ..., Dec                |       |
     * |                                 | LLLL    | January, February, ..., December  | 2     |
     * |                                 | LLLLL   | J, F, ..., D                      |       |
     * | Local week of year              | w       | 1, 2, ..., 53                     |       |
     * |                                 | wo      | 1st, 2nd, ..., 53th               | 7     |
     * |                                 | ww      | 01, 02, ..., 53                   |       |
     * | ISO week of year                | I       | 1, 2, ..., 53                     | 7     |
     * |                                 | Io      | 1st, 2nd, ..., 53th               | 7     |
     * |                                 | II      | 01, 02, ..., 53                   | 7     |
     * | Day of month                    | d       | 1, 2, ..., 31                     |       |
     * |                                 | do      | 1st, 2nd, ..., 31st               | 7     |
     * |                                 | dd      | 01, 02, ..., 31                   |       |
     * | Day of year                     | D       | 1, 2, ..., 365, 366               | 9     |
     * |                                 | Do      | 1st, 2nd, ..., 365th, 366th       | 7     |
     * |                                 | DD      | 01, 02, ..., 365, 366             | 9     |
     * |                                 | DDD     | 001, 002, ..., 365, 366           |       |
     * |                                 | DDDD    | ...                               | 3     |
     * | Day of week (formatting)        | E..EEE  | Mon, Tue, Wed, ..., Sun           |       |
     * |                                 | EEEE    | Monday, Tuesday, ..., Sunday      | 2     |
     * |                                 | EEEEE   | M, T, W, T, F, S, S               |       |
     * |                                 | EEEEEE  | Mo, Tu, We, Th, Fr, Sa, Su        |       |
     * | ISO day of week (formatting)    | i       | 1, 2, 3, ..., 7                   | 7     |
     * |                                 | io      | 1st, 2nd, ..., 7th                | 7     |
     * |                                 | ii      | 01, 02, ..., 07                   | 7     |
     * |                                 | iii     | Mon, Tue, Wed, ..., Sun           | 7     |
     * |                                 | iiii    | Monday, Tuesday, ..., Sunday      | 2,7   |
     * |                                 | iiiii   | M, T, W, T, F, S, S               | 7     |
     * |                                 | iiiiii  | Mo, Tu, We, Th, Fr, Sa, Su        | 7     |
     * | Local day of week (formatting)  | e       | 2, 3, 4, ..., 1                   |       |
     * |                                 | eo      | 2nd, 3rd, ..., 1st                | 7     |
     * |                                 | ee      | 02, 03, ..., 01                   |       |
     * |                                 | eee     | Mon, Tue, Wed, ..., Sun           |       |
     * |                                 | eeee    | Monday, Tuesday, ..., Sunday      | 2     |
     * |                                 | eeeee   | M, T, W, T, F, S, S               |       |
     * |                                 | eeeeee  | Mo, Tu, We, Th, Fr, Sa, Su        |       |
     * | Local day of week (stand-alone) | c       | 2, 3, 4, ..., 1                   |       |
     * |                                 | co      | 2nd, 3rd, ..., 1st                | 7     |
     * |                                 | cc      | 02, 03, ..., 01                   |       |
     * |                                 | ccc     | Mon, Tue, Wed, ..., Sun           |       |
     * |                                 | cccc    | Monday, Tuesday, ..., Sunday      | 2     |
     * |                                 | ccccc   | M, T, W, T, F, S, S               |       |
     * |                                 | cccccc  | Mo, Tu, We, Th, Fr, Sa, Su        |       |
     * | AM, PM                          | a..aa   | AM, PM                            |       |
     * |                                 | aaa     | am, pm                            |       |
     * |                                 | aaaa    | a.m., p.m.                        | 2     |
     * |                                 | aaaaa   | a, p                              |       |
     * | AM, PM, noon, midnight          | b..bb   | AM, PM, noon, midnight            |       |
     * |                                 | bbb     | am, pm, noon, midnight            |       |
     * |                                 | bbbb    | a.m., p.m., noon, midnight        | 2     |
     * |                                 | bbbbb   | a, p, n, mi                       |       |
     * | Flexible day period             | B..BBB  | at night, in the morning, ...     |       |
     * |                                 | BBBB    | at night, in the morning, ...     | 2     |
     * |                                 | BBBBB   | at night, in the morning, ...     |       |
     * | Hour [1-12]                     | h       | 1, 2, ..., 11, 12                 |       |
     * |                                 | ho      | 1st, 2nd, ..., 11th, 12th         | 7     |
     * |                                 | hh      | 01, 02, ..., 11, 12               |       |
     * | Hour [0-23]                     | H       | 0, 1, 2, ..., 23                  |       |
     * |                                 | Ho      | 0th, 1st, 2nd, ..., 23rd          | 7     |
     * |                                 | HH      | 00, 01, 02, ..., 23               |       |
     * | Hour [0-11]                     | K       | 1, 2, ..., 11, 0                  |       |
     * |                                 | Ko      | 1st, 2nd, ..., 11th, 0th          | 7     |
     * |                                 | KK      | 01, 02, ..., 11, 00               |       |
     * | Hour [1-24]                     | k       | 24, 1, 2, ..., 23                 |       |
     * |                                 | ko      | 24th, 1st, 2nd, ..., 23rd         | 7     |
     * |                                 | kk      | 24, 01, 02, ..., 23               |       |
     * | Minute                          | m       | 0, 1, ..., 59                     |       |
     * |                                 | mo      | 0th, 1st, ..., 59th               | 7     |
     * |                                 | mm      | 00, 01, ..., 59                   |       |
     * | Second                          | s       | 0, 1, ..., 59                     |       |
     * |                                 | so      | 0th, 1st, ..., 59th               | 7     |
     * |                                 | ss      | 00, 01, ..., 59                   |       |
     * | Fraction of second              | S       | 0, 1, ..., 9                      |       |
     * |                                 | SS      | 00, 01, ..., 99                   |       |
     * |                                 | SSS     | 000, 001, ..., 999                |       |
     * |                                 | SSSS    | ...                               | 3     |
     * | Timezone (ISO-8601 w/ Z)        | X       | -08, +0530, Z                     |       |
     * |                                 | XX      | -0800, +0530, Z                   |       |
     * |                                 | XXX     | -08:00, +05:30, Z                 |       |
     * |                                 | XXXX    | -0800, +0530, Z, +123456          | 2     |
     * |                                 | XXXXX   | -08:00, +05:30, Z, +12:34:56      |       |
     * | Timezone (ISO-8601 w/o Z)       | x       | -08, +0530, +00                   |       |
     * |                                 | xx      | -0800, +0530, +0000               |       |
     * |                                 | xxx     | -08:00, +05:30, +00:00            | 2     |
     * |                                 | xxxx    | -0800, +0530, +0000, +123456      |       |
     * |                                 | xxxxx   | -08:00, +05:30, +00:00, +12:34:56 |       |
     * | Timezone (GMT)                  | O...OOO | GMT-8, GMT+5:30, GMT+0            |       |
     * |                                 | OOOO    | GMT-08:00, GMT+05:30, GMT+00:00   | 2     |
     * | Timezone (specific non-locat.)  | z...zzz | GMT-8, GMT+5:30, GMT+0            | 6     |
     * |                                 | zzzz    | GMT-08:00, GMT+05:30, GMT+00:00   | 2,6   |
     * | Seconds timestamp               | t       | 512969520                         | 7     |
     * |                                 | tt      | ...                               | 3,7   |
     * | Milliseconds timestamp          | T       | 512969520900                      | 7     |
     * |                                 | TT      | ...                               | 3,7   |
     * | Long localized date             | P       | 04/29/1453                        | 7     |
     * |                                 | PP      | Apr 29, 1453                      | 7     |
     * |                                 | PPP     | April 29th, 1453                  | 7     |
     * |                                 | PPPP    | Friday, April 29th, 1453          | 2,7   |
     * | Long localized time             | p       | 12:00 AM                          | 7     |
     * |                                 | pp      | 12:00:00 AM                       | 7     |
     * |                                 | ppp     | 12:00:00 AM GMT+2                 | 7     |
     * |                                 | pppp    | 12:00:00 AM GMT+02:00             | 2,7   |
     * | Combination of date and time    | Pp      | 04/29/1453, 12:00 AM              | 7     |
     * |                                 | PPpp    | Apr 29, 1453, 12:00:00 AM         | 7     |
     * |                                 | PPPppp  | April 29th, 1453 at ...           | 7     |
     * |                                 | PPPPpppp| Friday, April 29th, 1453 at ...   | 2,7   |
     * Notes:
     * 1. "Formatting" units (e.g. formatting quarter) in the default en-US locale
     *    are the same as "stand-alone" units, but are different in some languages.
     *    "Formatting" units are declined according to the rules of the language
     *    in the context of a date. "Stand-alone" units are always nominative singular:
     *
     *    `format(new Date(2017, 10, 6), 'do LLLL', {locale: cs}) //=> '6. listopad'`
     *
     *    `format(new Date(2017, 10, 6), 'do MMMM', {locale: cs}) //=> '6. listopadu'`
     *
     * 2. Any sequence of the identical letters is a pattern, unless it is escaped by
     *    the single quote characters (see below).
     *    If the sequence is longer than listed in table (e.g. `EEEEEEEEEEE`)
     *    the output will be the same as default pattern for this unit, usually
     *    the longest one (in case of ISO weekdays, `EEEE`). Default patterns for units
     *    are marked with "2" in the last column of the table.
     *
     *    `format(new Date(2017, 10, 6), 'MMM') //=> 'Nov'`
     *
     *    `format(new Date(2017, 10, 6), 'MMMM') //=> 'November'`
     *
     *    `format(new Date(2017, 10, 6), 'MMMMM') //=> 'N'`
     *
     *    `format(new Date(2017, 10, 6), 'MMMMMM') //=> 'November'`
     *
     *    `format(new Date(2017, 10, 6), 'MMMMMMM') //=> 'November'`
     *
     * 3. Some patterns could be unlimited length (such as `yyyyyyyy`).
     *    The output will be padded with zeros to match the length of the pattern.
     *
     *    `format(new Date(2017, 10, 6), 'yyyyyyyy') //=> '00002017'`
     *
     * 4. `QQQQQ` and `qqqqq` could be not strictly numerical in some locales.
     *    These tokens represent the shortest form of the quarter.
     *
     * 5. The main difference between `y` and `u` patterns are B.C. years:
     *
     *    | Year | `y` | `u` |
     *    |------|-----|-----|
     *    | AC 1 |   1 |   1 |
     *    | BC 1 |   1 |   0 |
     *    | BC 2 |   2 |  -1 |
     *
     *    Also `yy` always returns the last two digits of a year,
     *    while `uu` pads single digit years to 2 characters and returns other years unchanged:
     *
     *    | Year | `yy` | `uu` |
     *    |------|------|------|
     *    | 1    |   01 |   01 |
     *    | 14   |   14 |   14 |
     *    | 376  |   76 |  376 |
     *    | 1453 |   53 | 1453 |
     *
     *    The same difference is true for local and ISO week-numbering years (`Y` and `R`),
     *    except local week-numbering years are dependent on `options.weekStartsOn`
     *    and `options.firstWeekContainsDate` (compare [getISOWeekYear]{@link https://date-fns.org/docs/getISOWeekYear}
     *    and [getWeekYear]{@link https://date-fns.org/docs/getWeekYear}).
     *
     * 6. Specific non-location timezones are currently unavailable in `date-fns`,
     *    so right now these tokens fall back to GMT timezones.
     *
     * 7. These patterns are not in the Unicode Technical Standard #35:
     *    - `i`: ISO day of week
     *    - `I`: ISO week of year
     *    - `R`: ISO week-numbering year
     *    - `t`: seconds timestamp
     *    - `T`: milliseconds timestamp
     *    - `o`: ordinal number modifier
     *    - `P`: long localized date
     *    - `p`: long localized time
     *
     * 8. `YY` and `YYYY` tokens represent week-numbering years but they are often confused with years.
     *    You should enable `options.useAdditionalWeekYearTokens` to use them. See: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
     *
     * 9. `D` and `DD` tokens represent days of the year but they are often confused with days of the month.
     *    You should enable `options.useAdditionalDayOfYearTokens` to use them. See: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
     *
     * @param {Date|Number} date - the original date
     * @param {String} format - the string of tokens
     * @param {Object} [options] - an object with options.
     * @param {Locale} [options.locale=defaultLocale] - the locale object. See [Locale]{@link https://date-fns.org/docs/Locale}
     * @param {0|1|2|3|4|5|6} [options.weekStartsOn=0] - the index of the first day of the week (0 - Sunday)
     * @param {Number} [options.firstWeekContainsDate=1] - the day of January, which is
     * @param {Boolean} [options.useAdditionalWeekYearTokens=false] - if true, allows usage of the week-numbering year tokens `YY` and `YYYY`;
     *   see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
     * @param {Boolean} [options.useAdditionalDayOfYearTokens=false] - if true, allows usage of the day of year tokens `D` and `DD`;
     *   see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
     * @returns {String} the formatted date string
     * @throws {TypeError} 2 arguments required
     * @throws {RangeError} `date` must not be Invalid Date
     * @throws {RangeError} `options.locale` must contain `localize` property
     * @throws {RangeError} `options.locale` must contain `formatLong` property
     * @throws {RangeError} `options.weekStartsOn` must be between 0 and 6
     * @throws {RangeError} `options.firstWeekContainsDate` must be between 1 and 7
     * @throws {RangeError} use `yyyy` instead of `YYYY` for formatting years using [format provided] to the input [input provided]; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
     * @throws {RangeError} use `yy` instead of `YY` for formatting years using [format provided] to the input [input provided]; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
     * @throws {RangeError} use `d` instead of `D` for formatting days of the month using [format provided] to the input [input provided]; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
     * @throws {RangeError} use `dd` instead of `DD` for formatting days of the month using [format provided] to the input [input provided]; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
     * @throws {RangeError} format string contains an unescaped latin alphabet character
     *
     * @example
     * // Represent 11 February 2014 in middle-endian format:
     * const result = format(new Date(2014, 1, 11), 'MM/dd/yyyy')
     * //=> '02/11/2014'
     *
     * @example
     * // Represent 2 July 2014 in Esperanto:
     * import { eoLocale } from 'date-fns/locale/eo'
     * const result = format(new Date(2014, 6, 2), "do 'de' MMMM yyyy", {
     *   locale: eoLocale
     * })
     * //=> '2-a de julio 2014'
     *
     * @example
     * // Escape string by single quote characters:
     * const result = format(new Date(2014, 6, 2, 15), "h 'o''clock'")
     * //=> "3 o'clock"
     */

    function format(dirtyDate, dirtyFormatStr, options) {
        var _ref, _options$locale, _ref2, _ref3, _ref4, _options$firstWeekCon, _options$locale2, _options$locale2$opti, _defaultOptions$local, _defaultOptions$local2, _ref5, _ref6, _ref7, _options$weekStartsOn, _options$locale3, _options$locale3$opti, _defaultOptions$local3, _defaultOptions$local4;

        requiredArgs(2, arguments);
        var formatStr = String(dirtyFormatStr);
        var defaultOptions = getDefaultOptions();
        var locale = (_ref = (_options$locale = options === null || options === void 0 ? void 0 : options.locale) !== null && _options$locale !== void 0 ? _options$locale : defaultOptions.locale) !== null && _ref !== void 0 ? _ref : defaultLocale;
        var firstWeekContainsDate = toInteger((_ref2 = (_ref3 = (_ref4 = (_options$firstWeekCon = options === null || options === void 0 ? void 0 : options.firstWeekContainsDate) !== null && _options$firstWeekCon !== void 0 ? _options$firstWeekCon : options === null || options === void 0 ? void 0 : (_options$locale2 = options.locale) === null || _options$locale2 === void 0 ? void 0 : (_options$locale2$opti = _options$locale2.options) === null || _options$locale2$opti === void 0 ? void 0 : _options$locale2$opti.firstWeekContainsDate) !== null && _ref4 !== void 0 ? _ref4 : defaultOptions.firstWeekContainsDate) !== null && _ref3 !== void 0 ? _ref3 : (_defaultOptions$local = defaultOptions.locale) === null || _defaultOptions$local === void 0 ? void 0 : (_defaultOptions$local2 = _defaultOptions$local.options) === null || _defaultOptions$local2 === void 0 ? void 0 : _defaultOptions$local2.firstWeekContainsDate) !== null && _ref2 !== void 0 ? _ref2 : 1); // Test if weekStartsOn is between 1 and 7 _and_ is not NaN

        if (!(firstWeekContainsDate >= 1 && firstWeekContainsDate <= 7)) {
            throw new RangeError('firstWeekContainsDate must be between 1 and 7 inclusively');
        }

        var weekStartsOn = toInteger((_ref5 = (_ref6 = (_ref7 = (_options$weekStartsOn = options === null || options === void 0 ? void 0 : options.weekStartsOn) !== null && _options$weekStartsOn !== void 0 ? _options$weekStartsOn : options === null || options === void 0 ? void 0 : (_options$locale3 = options.locale) === null || _options$locale3 === void 0 ? void 0 : (_options$locale3$opti = _options$locale3.options) === null || _options$locale3$opti === void 0 ? void 0 : _options$locale3$opti.weekStartsOn) !== null && _ref7 !== void 0 ? _ref7 : defaultOptions.weekStartsOn) !== null && _ref6 !== void 0 ? _ref6 : (_defaultOptions$local3 = defaultOptions.locale) === null || _defaultOptions$local3 === void 0 ? void 0 : (_defaultOptions$local4 = _defaultOptions$local3.options) === null || _defaultOptions$local4 === void 0 ? void 0 : _defaultOptions$local4.weekStartsOn) !== null && _ref5 !== void 0 ? _ref5 : 0); // Test if weekStartsOn is between 0 and 6 _and_ is not NaN

        if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
            throw new RangeError('weekStartsOn must be between 0 and 6 inclusively');
        }

        if (!locale.localize) {
            throw new RangeError('locale must contain localize property');
        }

        if (!locale.formatLong) {
            throw new RangeError('locale must contain formatLong property');
        }

        var originalDate = toDate(dirtyDate);

        if (!isValid(originalDate)) {
            throw new RangeError('Invalid time value');
        } // Convert the date in system timezone to the same date in UTC+00:00 timezone.
        // This ensures that when UTC functions will be implemented, locales will be compatible with them.
        // See an issue about UTC functions: https://github.com/date-fns/date-fns/issues/376


        var timezoneOffset = getTimezoneOffsetInMilliseconds(originalDate);
        var utcDate = subMilliseconds(originalDate, timezoneOffset);
        var formatterOptions = {
            firstWeekContainsDate: firstWeekContainsDate,
            weekStartsOn: weekStartsOn,
            locale: locale,
            _originalDate: originalDate
        };
        var result = formatStr.match(longFormattingTokensRegExp$1).map(function (substring) {
            var firstCharacter = substring[0];

            if (firstCharacter === 'p' || firstCharacter === 'P') {
                var longFormatter = longFormatters$1[firstCharacter];
                return longFormatter(substring, locale.formatLong);
            }

            return substring;
        }).join('').match(formattingTokensRegExp$1).map(function (substring) {
            // Replace two single quote characters with one single quote character
            if (substring === "''") {
                return "'";
            }

            var firstCharacter = substring[0];

            if (firstCharacter === "'") {
                return cleanEscapedString$1(substring);
            }

            var formatter = formatters$1[firstCharacter];

            if (formatter) {
                if (!(options !== null && options !== void 0 && options.useAdditionalWeekYearTokens) && isProtectedWeekYearToken(substring)) {
                    throwProtectedError(substring, dirtyFormatStr, String(dirtyDate));
                }

                if (!(options !== null && options !== void 0 && options.useAdditionalDayOfYearTokens) && isProtectedDayOfYearToken(substring)) {
                    throwProtectedError(substring, dirtyFormatStr, String(dirtyDate));
                }

                return formatter(utcDate, substring, locale.localize, formatterOptions);
            }

            if (firstCharacter.match(unescapedLatinCharacterRegExp$1)) {
                throw new RangeError('Format string contains an unescaped latin alphabet character `' + firstCharacter + '`');
            }

            return substring;
        }).join('');
        return result;
    }

    function cleanEscapedString$1(input) {
        var matched = input.match(escapedStringRegExp$1);

        if (!matched) {
            return input;
        }

        return matched[1].replace(doubleQuoteRegExp$1, "'");
    }

    function assign(target, object) {
        if (target == null) {
            throw new TypeError('assign requires that input parameter not be null or undefined');
        }

        for (var property in object) {
            if (Object.prototype.hasOwnProperty.call(object, property)) {
                target[property] = object[property];
            }
        }

        return target;
    }

    /**
     * @name getDay
     * @category Weekday Helpers
     * @summary Get the day of the week of the given date.
     *
     * @description
     * Get the day of the week of the given date.
     *
     * @param {Date|Number} date - the given date
     * @returns {0|1|2|3|4|5|6} the day of week, 0 represents Sunday
     * @throws {TypeError} 1 argument required
     *
     * @example
     * // Which day of the week is 29 February 2012?
     * const result = getDay(new Date(2012, 1, 29))
     * //=> 3
     */

    function getDay(dirtyDate) {
        requiredArgs(1, arguments);
        var date = toDate(dirtyDate);
        var day = date.getDay();
        return day;
    }

    /**
     * @name getDaysInMonth
     * @category Month Helpers
     * @summary Get the number of days in a month of the given date.
     *
     * @description
     * Get the number of days in a month of the given date.
     *
     * @param {Date|Number} date - the given date
     * @returns {Number} the number of days in a month
     * @throws {TypeError} 1 argument required
     *
     * @example
     * // How many days are in February 2000?
     * const result = getDaysInMonth(new Date(2000, 1))
     * //=> 29
     */

    function getDaysInMonth(dirtyDate) {
        requiredArgs(1, arguments);
        var date = toDate(dirtyDate);
        var year = date.getFullYear();
        var monthIndex = date.getMonth();
        var lastDayOfMonth = new Date(0);
        lastDayOfMonth.setFullYear(year, monthIndex + 1, 0);
        lastDayOfMonth.setHours(0, 0, 0, 0);
        return lastDayOfMonth.getDate();
    }

    /**
     * @name getHours
     * @category Hour Helpers
     * @summary Get the hours of the given date.
     *
     * @description
     * Get the hours of the given date.
     *
     * @param {Date|Number} date - the given date
     * @returns {Number} the hours
     * @throws {TypeError} 1 argument required
     *
     * @example
     * // Get the hours of 29 February 2012 11:45:00:
     * const result = getHours(new Date(2012, 1, 29, 11, 45))
     * //=> 11
     */

    function getHours(dirtyDate) {
        requiredArgs(1, arguments);
        var date = toDate(dirtyDate);
        var hours = date.getHours();
        return hours;
    }

    var MILLISECONDS_IN_WEEK = 604800000;
    /**
     * @name getISOWeek
     * @category ISO Week Helpers
     * @summary Get the ISO week of the given date.
     *
     * @description
     * Get the ISO week of the given date.
     *
     * ISO week-numbering year: http://en.wikipedia.org/wiki/ISO_week_date
     *
     * @param {Date|Number} date - the given date
     * @returns {Number} the ISO week
     * @throws {TypeError} 1 argument required
     *
     * @example
     * // Which week of the ISO-week numbering year is 2 January 2005?
     * const result = getISOWeek(new Date(2005, 0, 2))
     * //=> 53
     */

    function getISOWeek(dirtyDate) {
        requiredArgs(1, arguments);
        var date = toDate(dirtyDate);
        var diff = startOfISOWeek(date).getTime() - startOfISOWeekYear(date).getTime(); // Round the number of days to the nearest integer
        // because the number of milliseconds in a week is not constant
        // (e.g. it's different in the week of the daylight saving time clock shift)

        return Math.round(diff / MILLISECONDS_IN_WEEK) + 1;
    }

    /**
     * @name getMinutes
     * @category Minute Helpers
     * @summary Get the minutes of the given date.
     *
     * @description
     * Get the minutes of the given date.
     *
     * @param {Date|Number} date - the given date
     * @returns {Number} the minutes
     * @throws {TypeError} 1 argument required
     *
     * @example
     * // Get the minutes of 29 February 2012 11:45:05:
     * const result = getMinutes(new Date(2012, 1, 29, 11, 45, 5))
     * //=> 45
     */

    function getMinutes(dirtyDate) {
        requiredArgs(1, arguments);
        var date = toDate(dirtyDate);
        var minutes = date.getMinutes();
        return minutes;
    }

    /**
     * @name getMonth
     * @category Month Helpers
     * @summary Get the month of the given date.
     *
     * @description
     * Get the month of the given date.
     *
     * @param {Date|Number} date - the given date
     * @returns {Number} the month
     * @throws {TypeError} 1 argument required
     *
     * @example
     * // Which month is 29 February 2012?
     * const result = getMonth(new Date(2012, 1, 29))
     * //=> 1
     */

    function getMonth(dirtyDate) {
        requiredArgs(1, arguments);
        var date = toDate(dirtyDate);
        var month = date.getMonth();
        return month;
    }

    /**
     * @name getSeconds
     * @category Second Helpers
     * @summary Get the seconds of the given date.
     *
     * @description
     * Get the seconds of the given date.
     *
     * @param {Date|Number} date - the given date
     * @returns {Number} the seconds
     * @throws {TypeError} 1 argument required
     *
     * @example
     * // Get the seconds of 29 February 2012 11:45:05.123:
     * const result = getSeconds(new Date(2012, 1, 29, 11, 45, 5, 123))
     * //=> 5
     */

    function getSeconds(dirtyDate) {
        requiredArgs(1, arguments);
        var date = toDate(dirtyDate);
        var seconds = date.getSeconds();
        return seconds;
    }

    /**
     * @name getYear
     * @category Year Helpers
     * @summary Get the year of the given date.
     *
     * @description
     * Get the year of the given date.
     *
     * @param {Date|Number} date - the given date
     * @returns {Number} the year
     * @throws {TypeError} 1 argument required
     *
     * @example
     * // Which year is 2 July 2014?
     * const result = getYear(new Date(2014, 6, 2))
     * //=> 2014
     */

    function getYear(dirtyDate) {
        requiredArgs(1, arguments);
        return toDate(dirtyDate).getFullYear();
    }

    /**
     * @name isAfter
     * @category Common Helpers
     * @summary Is the first date after the second one?
     *
     * @description
     * Is the first date after the second one?
     *
     * @param {Date|Number} date - the date that should be after the other one to return true
     * @param {Date|Number} dateToCompare - the date to compare with
     * @returns {Boolean} the first date is after the second date
     * @throws {TypeError} 2 arguments required
     *
     * @example
     * // Is 10 July 1989 after 11 February 1987?
     * const result = isAfter(new Date(1989, 6, 10), new Date(1987, 1, 11))
     * //=> true
     */

    function isAfter(dirtyDate, dirtyDateToCompare) {
        requiredArgs(2, arguments);
        var date = toDate(dirtyDate);
        var dateToCompare = toDate(dirtyDateToCompare);
        return date.getTime() > dateToCompare.getTime();
    }

    /**
     * @name isBefore
     * @category Common Helpers
     * @summary Is the first date before the second one?
     *
     * @description
     * Is the first date before the second one?
     *
     * @param {Date|Number} date - the date that should be before the other one to return true
     * @param {Date|Number} dateToCompare - the date to compare with
     * @returns {Boolean} the first date is before the second date
     * @throws {TypeError} 2 arguments required
     *
     * @example
     * // Is 10 July 1989 before 11 February 1987?
     * const result = isBefore(new Date(1989, 6, 10), new Date(1987, 1, 11))
     * //=> false
     */

    function isBefore(dirtyDate, dirtyDateToCompare) {
        requiredArgs(2, arguments);
        var date = toDate(dirtyDate);
        var dateToCompare = toDate(dirtyDateToCompare);
        return date.getTime() < dateToCompare.getTime();
    }

    /**
     * @name isEqual
     * @category Common Helpers
     * @summary Are the given dates equal?
     *
     * @description
     * Are the given dates equal?
     *
     * @param {Date|Number} dateLeft - the first date to compare
     * @param {Date|Number} dateRight - the second date to compare
     * @returns {Boolean} the dates are equal
     * @throws {TypeError} 2 arguments required
     *
     * @example
     * // Are 2 July 2014 06:30:45.000 and 2 July 2014 06:30:45.500 equal?
     * const result = isEqual(
     *   new Date(2014, 6, 2, 6, 30, 45, 0),
     *   new Date(2014, 6, 2, 6, 30, 45, 500)
     * )
     * //=> false
     */

    function isEqual(dirtyLeftDate, dirtyRightDate) {
        requiredArgs(2, arguments);
        var dateLeft = toDate(dirtyLeftDate);
        var dateRight = toDate(dirtyRightDate);
        return dateLeft.getTime() === dateRight.getTime();
    }

    function _defineProperty$w(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

    var TIMEZONE_UNIT_PRIORITY = 10;
    class Setter {
        constructor() {
            _defineProperty$w(this, "priority", void 0);

            _defineProperty$w(this, "subPriority", 0);
        }

        validate(_utcDate, _options) {
            return true;
        }

    }
    class ValueSetter extends Setter {
        constructor(value, validateValue, setValue, priority, subPriority) {
            super();
            this.value = value;
            this.validateValue = validateValue;
            this.setValue = setValue;
            this.priority = priority;

            if (subPriority) {
                this.subPriority = subPriority;
            }
        }

        validate(utcDate, options) {
            return this.validateValue(utcDate, this.value, options);
        }

        set(utcDate, flags, options) {
            return this.setValue(utcDate, flags, this.value, options);
        }

    }
    class DateToSystemTimezoneSetter extends Setter {
        constructor() {
            super(...arguments);

            _defineProperty$w(this, "priority", TIMEZONE_UNIT_PRIORITY);

            _defineProperty$w(this, "subPriority", -1);
        }

        set(date, flags) {
            if (flags.timestampIsSet) {
                return date;
            }

            var convertedDate = new Date(0);
            convertedDate.setFullYear(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
            convertedDate.setHours(date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
            return convertedDate;
        }

    }

    function _defineProperty$v(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    class Parser {
        constructor() {
            _defineProperty$v(this, "incompatibleTokens", void 0);

            _defineProperty$v(this, "priority", void 0);

            _defineProperty$v(this, "subPriority", void 0);
        }

        run(dateString, token, match, options) {
            var result = this.parse(dateString, token, match, options);

            if (!result) {
                return null;
            }

            return {
                setter: new ValueSetter(result.value, this.validate, this.set, this.priority, this.subPriority),
                rest: result.rest
            };
        }

        validate(_utcDate, _value, _options) {
            return true;
        }

    }

    function _defineProperty$u(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    class EraParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$u(this, "priority", 140);

            _defineProperty$u(this, "incompatibleTokens", ['R', 'u', 't', 'T']);
        }

        parse(dateString, token, match) {
            switch (token) {
                // AD, BC
                case 'G':
                case 'GG':
                case 'GGG':
                    return match.era(dateString, {
                        width: 'abbreviated'
                    }) || match.era(dateString, {
                        width: 'narrow'
                    });
                // A, B

                case 'GGGGG':
                    return match.era(dateString, {
                        width: 'narrow'
                    });
                // Anno Domini, Before Christ

                case 'GGGG':
                default:
                    return match.era(dateString, {
                        width: 'wide'
                    }) || match.era(dateString, {
                        width: 'abbreviated'
                    }) || match.era(dateString, {
                        width: 'narrow'
                    });
            }
        }

        set(date, flags, value) {
            flags.era = value;
            date.setUTCFullYear(value, 0, 1);
            date.setUTCHours(0, 0, 0, 0);
            return date;
        }

    }

    var numericPatterns = {
        month: /^(1[0-2]|0?\d)/,
        // 0 to 12
        date: /^(3[0-1]|[0-2]?\d)/,
        // 0 to 31
        dayOfYear: /^(36[0-6]|3[0-5]\d|[0-2]?\d?\d)/,
        // 0 to 366
        week: /^(5[0-3]|[0-4]?\d)/,
        // 0 to 53
        hour23h: /^(2[0-3]|[0-1]?\d)/,
        // 0 to 23
        hour24h: /^(2[0-4]|[0-1]?\d)/,
        // 0 to 24
        hour11h: /^(1[0-1]|0?\d)/,
        // 0 to 11
        hour12h: /^(1[0-2]|0?\d)/,
        // 0 to 12
        minute: /^[0-5]?\d/,
        // 0 to 59
        second: /^[0-5]?\d/,
        // 0 to 59
        singleDigit: /^\d/,
        // 0 to 9
        twoDigits: /^\d{1,2}/,
        // 0 to 99
        threeDigits: /^\d{1,3}/,
        // 0 to 999
        fourDigits: /^\d{1,4}/,
        // 0 to 9999
        anyDigitsSigned: /^-?\d+/,
        singleDigitSigned: /^-?\d/,
        // 0 to 9, -0 to -9
        twoDigitsSigned: /^-?\d{1,2}/,
        // 0 to 99, -0 to -99
        threeDigitsSigned: /^-?\d{1,3}/,
        // 0 to 999, -0 to -999
        fourDigitsSigned: /^-?\d{1,4}/ // 0 to 9999, -0 to -9999

    };
    var timezonePatterns = {
        basicOptionalMinutes: /^([+-])(\d{2})(\d{2})?|Z/,
        basic: /^([+-])(\d{2})(\d{2})|Z/,
        basicOptionalSeconds: /^([+-])(\d{2})(\d{2})((\d{2}))?|Z/,
        extended: /^([+-])(\d{2}):(\d{2})|Z/,
        extendedOptionalSeconds: /^([+-])(\d{2}):(\d{2})(:(\d{2}))?|Z/
    };

    function mapValue(parseFnResult, mapFn) {
        if (!parseFnResult) {
            return parseFnResult;
        }

        return {
            value: mapFn(parseFnResult.value),
            rest: parseFnResult.rest
        };
    }
    function parseNumericPattern(pattern, dateString) {
        var matchResult = dateString.match(pattern);

        if (!matchResult) {
            return null;
        }

        return {
            value: parseInt(matchResult[0], 10),
            rest: dateString.slice(matchResult[0].length)
        };
    }
    function parseTimezonePattern(pattern, dateString) {
        var matchResult = dateString.match(pattern);

        if (!matchResult) {
            return null;
        } // Input is 'Z'


        if (matchResult[0] === 'Z') {
            return {
                value: 0,
                rest: dateString.slice(1)
            };
        }

        var sign = matchResult[1] === '+' ? 1 : -1;
        var hours = matchResult[2] ? parseInt(matchResult[2], 10) : 0;
        var minutes = matchResult[3] ? parseInt(matchResult[3], 10) : 0;
        var seconds = matchResult[5] ? parseInt(matchResult[5], 10) : 0;
        return {
            value: sign * (hours * millisecondsInHour + minutes * millisecondsInMinute + seconds * millisecondsInSecond),
            rest: dateString.slice(matchResult[0].length)
        };
    }
    function parseAnyDigitsSigned(dateString) {
        return parseNumericPattern(numericPatterns.anyDigitsSigned, dateString);
    }
    function parseNDigits(n, dateString) {
        switch (n) {
            case 1:
                return parseNumericPattern(numericPatterns.singleDigit, dateString);

            case 2:
                return parseNumericPattern(numericPatterns.twoDigits, dateString);

            case 3:
                return parseNumericPattern(numericPatterns.threeDigits, dateString);

            case 4:
                return parseNumericPattern(numericPatterns.fourDigits, dateString);

            default:
                return parseNumericPattern(new RegExp('^\\d{1,' + n + '}'), dateString);
        }
    }
    function parseNDigitsSigned(n, dateString) {
        switch (n) {
            case 1:
                return parseNumericPattern(numericPatterns.singleDigitSigned, dateString);

            case 2:
                return parseNumericPattern(numericPatterns.twoDigitsSigned, dateString);

            case 3:
                return parseNumericPattern(numericPatterns.threeDigitsSigned, dateString);

            case 4:
                return parseNumericPattern(numericPatterns.fourDigitsSigned, dateString);

            default:
                return parseNumericPattern(new RegExp('^-?\\d{1,' + n + '}'), dateString);
        }
    }
    function dayPeriodEnumToHours(dayPeriod) {
        switch (dayPeriod) {
            case 'morning':
                return 4;

            case 'evening':
                return 17;

            case 'pm':
            case 'noon':
            case 'afternoon':
                return 12;

            case 'am':
            case 'midnight':
            case 'night':
            default:
                return 0;
        }
    }
    function normalizeTwoDigitYear(twoDigitYear, currentYear) {
        var isCommonEra = currentYear > 0; // Absolute number of the current year:
        // 1 -> 1 AC
        // 0 -> 1 BC
        // -1 -> 2 BC

        var absCurrentYear = isCommonEra ? currentYear : 1 - currentYear;
        var result;

        if (absCurrentYear <= 50) {
            result = twoDigitYear || 100;
        } else {
            var rangeEnd = absCurrentYear + 50;
            var rangeEndCentury = Math.floor(rangeEnd / 100) * 100;
            var isPreviousCentury = twoDigitYear >= rangeEnd % 100;
            result = twoDigitYear + rangeEndCentury - (isPreviousCentury ? 100 : 0);
        }

        return isCommonEra ? result : 1 - result;
    }
    function isLeapYearIndex$1(year) {
        return year % 400 === 0 || year % 4 === 0 && year % 100 !== 0;
    }

    function _defineProperty$t(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    // From http://www.unicode.org/reports/tr35/tr35-31/tr35-dates.html#Date_Format_Patterns
    // | Year     |     y | yy |   yyy |  yyyy | yyyyy |
    // |----------|-------|----|-------|-------|-------|
    // | AD 1     |     1 | 01 |   001 |  0001 | 00001 |
    // | AD 12    |    12 | 12 |   012 |  0012 | 00012 |
    // | AD 123   |   123 | 23 |   123 |  0123 | 00123 |
    // | AD 1234  |  1234 | 34 |  1234 |  1234 | 01234 |
    // | AD 12345 | 12345 | 45 | 12345 | 12345 | 12345 |
    class YearParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$t(this, "priority", 130);

            _defineProperty$t(this, "incompatibleTokens", ['Y', 'R', 'u', 'w', 'I', 'i', 'e', 'c', 't', 'T']);
        }

        parse(dateString, token, match) {
            var valueCallback = function (year) {
                return {
                    year: year,
                    isTwoDigitYear: token === 'yy'
                };
            };

            switch (token) {
                case 'y':
                    return mapValue(parseNDigits(4, dateString), valueCallback);

                case 'yo':
                    return mapValue(match.ordinalNumber(dateString, {
                        unit: 'year'
                    }), valueCallback);

                default:
                    return mapValue(parseNDigits(token.length, dateString), valueCallback);
            }
        }

        validate(_date, value) {
            return value.isTwoDigitYear || value.year > 0;
        }

        set(date, flags, value) {
            var currentYear = date.getUTCFullYear();

            if (value.isTwoDigitYear) {
                var normalizedTwoDigitYear = normalizeTwoDigitYear(value.year, currentYear);
                date.setUTCFullYear(normalizedTwoDigitYear, 0, 1);
                date.setUTCHours(0, 0, 0, 0);
                return date;
            }

            var year = !('era' in flags) || flags.era === 1 ? value.year : 1 - value.year;
            date.setUTCFullYear(year, 0, 1);
            date.setUTCHours(0, 0, 0, 0);
            return date;
        }

    }

    function _defineProperty$s(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    // Local week-numbering year
    class LocalWeekYearParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$s(this, "priority", 130);

            _defineProperty$s(this, "incompatibleTokens", ['y', 'R', 'u', 'Q', 'q', 'M', 'L', 'I', 'd', 'D', 'i', 't', 'T']);
        }

        parse(dateString, token, match) {
            var valueCallback = function (year) {
                return {
                    year: year,
                    isTwoDigitYear: token === 'YY'
                };
            };

            switch (token) {
                case 'Y':
                    return mapValue(parseNDigits(4, dateString), valueCallback);

                case 'Yo':
                    return mapValue(match.ordinalNumber(dateString, {
                        unit: 'year'
                    }), valueCallback);

                default:
                    return mapValue(parseNDigits(token.length, dateString), valueCallback);
            }
        }

        validate(_date, value) {
            return value.isTwoDigitYear || value.year > 0;
        }

        set(date, flags, value, options) {
            var currentYear = getUTCWeekYear(date, options);

            if (value.isTwoDigitYear) {
                var normalizedTwoDigitYear = normalizeTwoDigitYear(value.year, currentYear);
                date.setUTCFullYear(normalizedTwoDigitYear, 0, options.firstWeekContainsDate);
                date.setUTCHours(0, 0, 0, 0);
                return startOfUTCWeek(date, options);
            }

            var year = !('era' in flags) || flags.era === 1 ? value.year : 1 - value.year;
            date.setUTCFullYear(year, 0, options.firstWeekContainsDate);
            date.setUTCHours(0, 0, 0, 0);
            return startOfUTCWeek(date, options);
        }

    }

    function _defineProperty$r(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

    class ISOWeekYearParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$r(this, "priority", 130);

            _defineProperty$r(this, "incompatibleTokens", ['G', 'y', 'Y', 'u', 'Q', 'q', 'M', 'L', 'w', 'd', 'D', 'e', 'c', 't', 'T']);
        }

        parse(dateString, token) {
            if (token === 'R') {
                return parseNDigitsSigned(4, dateString);
            }

            return parseNDigitsSigned(token.length, dateString);
        }

        set(_date, _flags, value) {
            var firstWeekOfYear = new Date(0);
            firstWeekOfYear.setUTCFullYear(value, 0, 4);
            firstWeekOfYear.setUTCHours(0, 0, 0, 0);
            return startOfUTCISOWeek(firstWeekOfYear);
        }

    }

    function _defineProperty$q(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    class ExtendedYearParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$q(this, "priority", 130);

            _defineProperty$q(this, "incompatibleTokens", ['G', 'y', 'Y', 'R', 'w', 'I', 'i', 'e', 'c', 't', 'T']);
        }

        parse(dateString, token) {
            if (token === 'u') {
                return parseNDigitsSigned(4, dateString);
            }

            return parseNDigitsSigned(token.length, dateString);
        }

        set(date, _flags, value) {
            date.setUTCFullYear(value, 0, 1);
            date.setUTCHours(0, 0, 0, 0);
            return date;
        }

    }

    function _defineProperty$p(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    class QuarterParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$p(this, "priority", 120);

            _defineProperty$p(this, "incompatibleTokens", ['Y', 'R', 'q', 'M', 'L', 'w', 'I', 'd', 'D', 'i', 'e', 'c', 't', 'T']);
        }

        parse(dateString, token, match) {
            switch (token) {
                // 1, 2, 3, 4
                case 'Q':
                case 'QQ':
                    // 01, 02, 03, 04
                    return parseNDigits(token.length, dateString);
                // 1st, 2nd, 3rd, 4th

                case 'Qo':
                    return match.ordinalNumber(dateString, {
                        unit: 'quarter'
                    });
                // Q1, Q2, Q3, Q4

                case 'QQQ':
                    return match.quarter(dateString, {
                        width: 'abbreviated',
                        context: 'formatting'
                    }) || match.quarter(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });
                // 1, 2, 3, 4 (narrow quarter; could be not numerical)

                case 'QQQQQ':
                    return match.quarter(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });
                // 1st quarter, 2nd quarter, ...

                case 'QQQQ':
                default:
                    return match.quarter(dateString, {
                        width: 'wide',
                        context: 'formatting'
                    }) || match.quarter(dateString, {
                        width: 'abbreviated',
                        context: 'formatting'
                    }) || match.quarter(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });
            }
        }

        validate(_date, value) {
            return value >= 1 && value <= 4;
        }

        set(date, _flags, value) {
            date.setUTCMonth((value - 1) * 3, 1);
            date.setUTCHours(0, 0, 0, 0);
            return date;
        }

    }

    function _defineProperty$o(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    class StandAloneQuarterParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$o(this, "priority", 120);

            _defineProperty$o(this, "incompatibleTokens", ['Y', 'R', 'Q', 'M', 'L', 'w', 'I', 'd', 'D', 'i', 'e', 'c', 't', 'T']);
        }

        parse(dateString, token, match) {
            switch (token) {
                // 1, 2, 3, 4
                case 'q':
                case 'qq':
                    // 01, 02, 03, 04
                    return parseNDigits(token.length, dateString);
                // 1st, 2nd, 3rd, 4th

                case 'qo':
                    return match.ordinalNumber(dateString, {
                        unit: 'quarter'
                    });
                // Q1, Q2, Q3, Q4

                case 'qqq':
                    return match.quarter(dateString, {
                        width: 'abbreviated',
                        context: 'standalone'
                    }) || match.quarter(dateString, {
                        width: 'narrow',
                        context: 'standalone'
                    });
                // 1, 2, 3, 4 (narrow quarter; could be not numerical)

                case 'qqqqq':
                    return match.quarter(dateString, {
                        width: 'narrow',
                        context: 'standalone'
                    });
                // 1st quarter, 2nd quarter, ...

                case 'qqqq':
                default:
                    return match.quarter(dateString, {
                        width: 'wide',
                        context: 'standalone'
                    }) || match.quarter(dateString, {
                        width: 'abbreviated',
                        context: 'standalone'
                    }) || match.quarter(dateString, {
                        width: 'narrow',
                        context: 'standalone'
                    });
            }
        }

        validate(_date, value) {
            return value >= 1 && value <= 4;
        }

        set(date, _flags, value) {
            date.setUTCMonth((value - 1) * 3, 1);
            date.setUTCHours(0, 0, 0, 0);
            return date;
        }

    }

    function _defineProperty$n(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    class MonthParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$n(this, "incompatibleTokens", ['Y', 'R', 'q', 'Q', 'L', 'w', 'I', 'D', 'i', 'e', 'c', 't', 'T']);

            _defineProperty$n(this, "priority", 110);
        }

        parse(dateString, token, match) {
            var valueCallback = function (value) {
                return value - 1;
            };

            switch (token) {
                // 1, 2, ..., 12
                case 'M':
                    return mapValue(parseNumericPattern(numericPatterns.month, dateString), valueCallback);
                // 01, 02, ..., 12

                case 'MM':
                    return mapValue(parseNDigits(2, dateString), valueCallback);
                // 1st, 2nd, ..., 12th

                case 'Mo':
                    return mapValue(match.ordinalNumber(dateString, {
                        unit: 'month'
                    }), valueCallback);
                // Jan, Feb, ..., Dec

                case 'MMM':
                    return match.month(dateString, {
                        width: 'abbreviated',
                        context: 'formatting'
                    }) || match.month(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });
                // J, F, ..., D

                case 'MMMMM':
                    return match.month(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });
                // January, February, ..., December

                case 'MMMM':
                default:
                    return match.month(dateString, {
                        width: 'wide',
                        context: 'formatting'
                    }) || match.month(dateString, {
                        width: 'abbreviated',
                        context: 'formatting'
                    }) || match.month(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });
            }
        }

        validate(_date, value) {
            return value >= 0 && value <= 11;
        }

        set(date, _flags, value) {
            date.setUTCMonth(value, 1);
            date.setUTCHours(0, 0, 0, 0);
            return date;
        }

    }

    function _defineProperty$m(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    class StandAloneMonthParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$m(this, "priority", 110);

            _defineProperty$m(this, "incompatibleTokens", ['Y', 'R', 'q', 'Q', 'M', 'w', 'I', 'D', 'i', 'e', 'c', 't', 'T']);
        }

        parse(dateString, token, match) {
            var valueCallback = function (value) {
                return value - 1;
            };

            switch (token) {
                // 1, 2, ..., 12
                case 'L':
                    return mapValue(parseNumericPattern(numericPatterns.month, dateString), valueCallback);
                // 01, 02, ..., 12

                case 'LL':
                    return mapValue(parseNDigits(2, dateString), valueCallback);
                // 1st, 2nd, ..., 12th

                case 'Lo':
                    return mapValue(match.ordinalNumber(dateString, {
                        unit: 'month'
                    }), valueCallback);
                // Jan, Feb, ..., Dec

                case 'LLL':
                    return match.month(dateString, {
                        width: 'abbreviated',
                        context: 'standalone'
                    }) || match.month(dateString, {
                        width: 'narrow',
                        context: 'standalone'
                    });
                // J, F, ..., D

                case 'LLLLL':
                    return match.month(dateString, {
                        width: 'narrow',
                        context: 'standalone'
                    });
                // January, February, ..., December

                case 'LLLL':
                default:
                    return match.month(dateString, {
                        width: 'wide',
                        context: 'standalone'
                    }) || match.month(dateString, {
                        width: 'abbreviated',
                        context: 'standalone'
                    }) || match.month(dateString, {
                        width: 'narrow',
                        context: 'standalone'
                    });
            }
        }

        validate(_date, value) {
            return value >= 0 && value <= 11;
        }

        set(date, _flags, value) {
            date.setUTCMonth(value, 1);
            date.setUTCHours(0, 0, 0, 0);
            return date;
        }

    }

    function setUTCWeek(dirtyDate, dirtyWeek, options) {
        requiredArgs(2, arguments);
        var date = toDate(dirtyDate);
        var week = toInteger(dirtyWeek);
        var diff = getUTCWeek(date, options) - week;
        date.setUTCDate(date.getUTCDate() - diff * 7);
        return date;
    }

    function _defineProperty$l(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

    class LocalWeekParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$l(this, "priority", 100);

            _defineProperty$l(this, "incompatibleTokens", ['y', 'R', 'u', 'q', 'Q', 'M', 'L', 'I', 'd', 'D', 'i', 't', 'T']);
        }

        parse(dateString, token, match) {
            switch (token) {
                case 'w':
                    return parseNumericPattern(numericPatterns.week, dateString);

                case 'wo':
                    return match.ordinalNumber(dateString, {
                        unit: 'week'
                    });

                default:
                    return parseNDigits(token.length, dateString);
            }
        }

        validate(_date, value) {
            return value >= 1 && value <= 53;
        }

        set(date, _flags, value, options) {
            return startOfUTCWeek(setUTCWeek(date, value, options), options);
        }

    }

    function setUTCISOWeek(dirtyDate, dirtyISOWeek) {
        requiredArgs(2, arguments);
        var date = toDate(dirtyDate);
        var isoWeek = toInteger(dirtyISOWeek);
        var diff = getUTCISOWeek(date) - isoWeek;
        date.setUTCDate(date.getUTCDate() - diff * 7);
        return date;
    }

    function _defineProperty$k(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

    class ISOWeekParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$k(this, "priority", 100);

            _defineProperty$k(this, "incompatibleTokens", ['y', 'Y', 'u', 'q', 'Q', 'M', 'L', 'w', 'd', 'D', 'e', 'c', 't', 'T']);
        }

        parse(dateString, token, match) {
            switch (token) {
                case 'I':
                    return parseNumericPattern(numericPatterns.week, dateString);

                case 'Io':
                    return match.ordinalNumber(dateString, {
                        unit: 'week'
                    });

                default:
                    return parseNDigits(token.length, dateString);
            }
        }

        validate(_date, value) {
            return value >= 1 && value <= 53;
        }

        set(date, _flags, value) {
            return startOfUTCISOWeek(setUTCISOWeek(date, value));
        }

    }

    function _defineProperty$j(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    var DAYS_IN_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    var DAYS_IN_MONTH_LEAP_YEAR = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; // Day of the month

    class DateParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$j(this, "priority", 90);

            _defineProperty$j(this, "subPriority", 1);

            _defineProperty$j(this, "incompatibleTokens", ['Y', 'R', 'q', 'Q', 'w', 'I', 'D', 'i', 'e', 'c', 't', 'T']);
        }

        parse(dateString, token, match) {
            switch (token) {
                case 'd':
                    return parseNumericPattern(numericPatterns.date, dateString);

                case 'do':
                    return match.ordinalNumber(dateString, {
                        unit: 'date'
                    });

                default:
                    return parseNDigits(token.length, dateString);
            }
        }

        validate(date, value) {
            var year = date.getUTCFullYear();
            var isLeapYear = isLeapYearIndex$1(year);
            var month = date.getUTCMonth();

            if (isLeapYear) {
                return value >= 1 && value <= DAYS_IN_MONTH_LEAP_YEAR[month];
            } else {
                return value >= 1 && value <= DAYS_IN_MONTH[month];
            }
        }

        set(date, _flags, value) {
            date.setUTCDate(value);
            date.setUTCHours(0, 0, 0, 0);
            return date;
        }

    }

    function _defineProperty$i(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    class DayOfYearParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$i(this, "priority", 90);

            _defineProperty$i(this, "subpriority", 1);

            _defineProperty$i(this, "incompatibleTokens", ['Y', 'R', 'q', 'Q', 'M', 'L', 'w', 'I', 'd', 'E', 'i', 'e', 'c', 't', 'T']);
        }

        parse(dateString, token, match) {
            switch (token) {
                case 'D':
                case 'DD':
                    return parseNumericPattern(numericPatterns.dayOfYear, dateString);

                case 'Do':
                    return match.ordinalNumber(dateString, {
                        unit: 'date'
                    });

                default:
                    return parseNDigits(token.length, dateString);
            }
        }

        validate(date, value) {
            var year = date.getUTCFullYear();
            var isLeapYear = isLeapYearIndex$1(year);

            if (isLeapYear) {
                return value >= 1 && value <= 366;
            } else {
                return value >= 1 && value <= 365;
            }
        }

        set(date, _flags, value) {
            date.setUTCMonth(0, value);
            date.setUTCHours(0, 0, 0, 0);
            return date;
        }

    }

    function setUTCDay(dirtyDate, dirtyDay, options) {
        var _ref, _ref2, _ref3, _options$weekStartsOn, _options$locale, _options$locale$optio, _defaultOptions$local, _defaultOptions$local2;

        requiredArgs(2, arguments);
        var defaultOptions = getDefaultOptions();
        var weekStartsOn = toInteger((_ref = (_ref2 = (_ref3 = (_options$weekStartsOn = options === null || options === void 0 ? void 0 : options.weekStartsOn) !== null && _options$weekStartsOn !== void 0 ? _options$weekStartsOn : options === null || options === void 0 ? void 0 : (_options$locale = options.locale) === null || _options$locale === void 0 ? void 0 : (_options$locale$optio = _options$locale.options) === null || _options$locale$optio === void 0 ? void 0 : _options$locale$optio.weekStartsOn) !== null && _ref3 !== void 0 ? _ref3 : defaultOptions.weekStartsOn) !== null && _ref2 !== void 0 ? _ref2 : (_defaultOptions$local = defaultOptions.locale) === null || _defaultOptions$local === void 0 ? void 0 : (_defaultOptions$local2 = _defaultOptions$local.options) === null || _defaultOptions$local2 === void 0 ? void 0 : _defaultOptions$local2.weekStartsOn) !== null && _ref !== void 0 ? _ref : 0); // Test if weekStartsOn is between 0 and 6 _and_ is not NaN

        if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
            throw new RangeError('weekStartsOn must be between 0 and 6 inclusively');
        }

        var date = toDate(dirtyDate);
        var day = toInteger(dirtyDay);
        var currentDay = date.getUTCDay();
        var remainder = day % 7;
        var dayIndex = (remainder + 7) % 7;
        var diff = (dayIndex < weekStartsOn ? 7 : 0) + day - currentDay;
        date.setUTCDate(date.getUTCDate() + diff);
        return date;
    }

    function _defineProperty$h(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

    class DayParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$h(this, "priority", 90);

            _defineProperty$h(this, "incompatibleTokens", ['D', 'i', 'e', 'c', 't', 'T']);
        }

        parse(dateString, token, match) {
            switch (token) {
                // Tue
                case 'E':
                case 'EE':
                case 'EEE':
                    return match.day(dateString, {
                        width: 'abbreviated',
                        context: 'formatting'
                    }) || match.day(dateString, {
                        width: 'short',
                        context: 'formatting'
                    }) || match.day(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });
                // T

                case 'EEEEE':
                    return match.day(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });
                // Tu

                case 'EEEEEE':
                    return match.day(dateString, {
                        width: 'short',
                        context: 'formatting'
                    }) || match.day(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });
                // Tuesday

                case 'EEEE':
                default:
                    return match.day(dateString, {
                        width: 'wide',
                        context: 'formatting'
                    }) || match.day(dateString, {
                        width: 'abbreviated',
                        context: 'formatting'
                    }) || match.day(dateString, {
                        width: 'short',
                        context: 'formatting'
                    }) || match.day(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });
            }
        }

        validate(_date, value) {
            return value >= 0 && value <= 6;
        }

        set(date, _flags, value, options) {
            date = setUTCDay(date, value, options);
            date.setUTCHours(0, 0, 0, 0);
            return date;
        }

    }

    function _defineProperty$g(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

    class LocalDayParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$g(this, "priority", 90);

            _defineProperty$g(this, "incompatibleTokens", ['y', 'R', 'u', 'q', 'Q', 'M', 'L', 'I', 'd', 'D', 'E', 'i', 'c', 't', 'T']);
        }

        parse(dateString, token, match, options) {
            var valueCallback = function (value) {
                var wholeWeekDays = Math.floor((value - 1) / 7) * 7;
                return (value + options.weekStartsOn + 6) % 7 + wholeWeekDays;
            };

            switch (token) {
                // 3
                case 'e':
                case 'ee':
                    // 03
                    return mapValue(parseNDigits(token.length, dateString), valueCallback);
                // 3rd

                case 'eo':
                    return mapValue(match.ordinalNumber(dateString, {
                        unit: 'day'
                    }), valueCallback);
                // Tue

                case 'eee':
                    return match.day(dateString, {
                        width: 'abbreviated',
                        context: 'formatting'
                    }) || match.day(dateString, {
                        width: 'short',
                        context: 'formatting'
                    }) || match.day(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });
                // T

                case 'eeeee':
                    return match.day(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });
                // Tu

                case 'eeeeee':
                    return match.day(dateString, {
                        width: 'short',
                        context: 'formatting'
                    }) || match.day(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });
                // Tuesday

                case 'eeee':
                default:
                    return match.day(dateString, {
                        width: 'wide',
                        context: 'formatting'
                    }) || match.day(dateString, {
                        width: 'abbreviated',
                        context: 'formatting'
                    }) || match.day(dateString, {
                        width: 'short',
                        context: 'formatting'
                    }) || match.day(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });
            }
        }

        validate(_date, value) {
            return value >= 0 && value <= 6;
        }

        set(date, _flags, value, options) {
            date = setUTCDay(date, value, options);
            date.setUTCHours(0, 0, 0, 0);
            return date;
        }

    }

    function _defineProperty$f(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

    class StandAloneLocalDayParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$f(this, "priority", 90);

            _defineProperty$f(this, "incompatibleTokens", ['y', 'R', 'u', 'q', 'Q', 'M', 'L', 'I', 'd', 'D', 'E', 'i', 'e', 't', 'T']);
        }

        parse(dateString, token, match, options) {
            var valueCallback = function (value) {
                var wholeWeekDays = Math.floor((value - 1) / 7) * 7;
                return (value + options.weekStartsOn + 6) % 7 + wholeWeekDays;
            };

            switch (token) {
                // 3
                case 'c':
                case 'cc':
                    // 03
                    return mapValue(parseNDigits(token.length, dateString), valueCallback);
                // 3rd

                case 'co':
                    return mapValue(match.ordinalNumber(dateString, {
                        unit: 'day'
                    }), valueCallback);
                // Tue

                case 'ccc':
                    return match.day(dateString, {
                        width: 'abbreviated',
                        context: 'standalone'
                    }) || match.day(dateString, {
                        width: 'short',
                        context: 'standalone'
                    }) || match.day(dateString, {
                        width: 'narrow',
                        context: 'standalone'
                    });
                // T

                case 'ccccc':
                    return match.day(dateString, {
                        width: 'narrow',
                        context: 'standalone'
                    });
                // Tu

                case 'cccccc':
                    return match.day(dateString, {
                        width: 'short',
                        context: 'standalone'
                    }) || match.day(dateString, {
                        width: 'narrow',
                        context: 'standalone'
                    });
                // Tuesday

                case 'cccc':
                default:
                    return match.day(dateString, {
                        width: 'wide',
                        context: 'standalone'
                    }) || match.day(dateString, {
                        width: 'abbreviated',
                        context: 'standalone'
                    }) || match.day(dateString, {
                        width: 'short',
                        context: 'standalone'
                    }) || match.day(dateString, {
                        width: 'narrow',
                        context: 'standalone'
                    });
            }
        }

        validate(_date, value) {
            return value >= 0 && value <= 6;
        }

        set(date, _flags, value, options) {
            date = setUTCDay(date, value, options);
            date.setUTCHours(0, 0, 0, 0);
            return date;
        }

    }

    function setUTCISODay(dirtyDate, dirtyDay) {
        requiredArgs(2, arguments);
        var day = toInteger(dirtyDay);

        if (day % 7 === 0) {
            day = day - 7;
        }

        var weekStartsOn = 1;
        var date = toDate(dirtyDate);
        var currentDay = date.getUTCDay();
        var remainder = day % 7;
        var dayIndex = (remainder + 7) % 7;
        var diff = (dayIndex < weekStartsOn ? 7 : 0) + day - currentDay;
        date.setUTCDate(date.getUTCDate() + diff);
        return date;
    }

    function _defineProperty$e(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

    class ISODayParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$e(this, "priority", 90);

            _defineProperty$e(this, "incompatibleTokens", ['y', 'Y', 'u', 'q', 'Q', 'M', 'L', 'w', 'd', 'D', 'E', 'e', 'c', 't', 'T']);
        }

        parse(dateString, token, match) {
            var valueCallback = function (value) {
                if (value === 0) {
                    return 7;
                }

                return value;
            };

            switch (token) {
                // 2
                case 'i':
                case 'ii':
                    // 02
                    return parseNDigits(token.length, dateString);
                // 2nd

                case 'io':
                    return match.ordinalNumber(dateString, {
                        unit: 'day'
                    });
                // Tue

                case 'iii':
                    return mapValue(match.day(dateString, {
                        width: 'abbreviated',
                        context: 'formatting'
                    }) || match.day(dateString, {
                        width: 'short',
                        context: 'formatting'
                    }) || match.day(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    }), valueCallback);
                // T

                case 'iiiii':
                    return mapValue(match.day(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    }), valueCallback);
                // Tu

                case 'iiiiii':
                    return mapValue(match.day(dateString, {
                        width: 'short',
                        context: 'formatting'
                    }) || match.day(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    }), valueCallback);
                // Tuesday

                case 'iiii':
                default:
                    return mapValue(match.day(dateString, {
                        width: 'wide',
                        context: 'formatting'
                    }) || match.day(dateString, {
                        width: 'abbreviated',
                        context: 'formatting'
                    }) || match.day(dateString, {
                        width: 'short',
                        context: 'formatting'
                    }) || match.day(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    }), valueCallback);
            }
        }

        validate(_date, value) {
            return value >= 1 && value <= 7;
        }

        set(date, _flags, value) {
            date = setUTCISODay(date, value);
            date.setUTCHours(0, 0, 0, 0);
            return date;
        }

    }

    function _defineProperty$d(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    class AMPMParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$d(this, "priority", 80);

            _defineProperty$d(this, "incompatibleTokens", ['b', 'B', 'H', 'k', 't', 'T']);
        }

        parse(dateString, token, match) {
            switch (token) {
                case 'a':
                case 'aa':
                case 'aaa':
                    return match.dayPeriod(dateString, {
                        width: 'abbreviated',
                        context: 'formatting'
                    }) || match.dayPeriod(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });

                case 'aaaaa':
                    return match.dayPeriod(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });

                case 'aaaa':
                default:
                    return match.dayPeriod(dateString, {
                        width: 'wide',
                        context: 'formatting'
                    }) || match.dayPeriod(dateString, {
                        width: 'abbreviated',
                        context: 'formatting'
                    }) || match.dayPeriod(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });
            }
        }

        set(date, _flags, value) {
            date.setUTCHours(dayPeriodEnumToHours(value), 0, 0, 0);
            return date;
        }

    }

    function _defineProperty$c(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    class AMPMMidnightParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$c(this, "priority", 80);

            _defineProperty$c(this, "incompatibleTokens", ['a', 'B', 'H', 'k', 't', 'T']);
        }

        parse(dateString, token, match) {
            switch (token) {
                case 'b':
                case 'bb':
                case 'bbb':
                    return match.dayPeriod(dateString, {
                        width: 'abbreviated',
                        context: 'formatting'
                    }) || match.dayPeriod(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });

                case 'bbbbb':
                    return match.dayPeriod(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });

                case 'bbbb':
                default:
                    return match.dayPeriod(dateString, {
                        width: 'wide',
                        context: 'formatting'
                    }) || match.dayPeriod(dateString, {
                        width: 'abbreviated',
                        context: 'formatting'
                    }) || match.dayPeriod(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });
            }
        }

        set(date, _flags, value) {
            date.setUTCHours(dayPeriodEnumToHours(value), 0, 0, 0);
            return date;
        }

    }

    function _defineProperty$b(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

    class DayPeriodParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$b(this, "priority", 80);

            _defineProperty$b(this, "incompatibleTokens", ['a', 'b', 't', 'T']);
        }

        parse(dateString, token, match) {
            switch (token) {
                case 'B':
                case 'BB':
                case 'BBB':
                    return match.dayPeriod(dateString, {
                        width: 'abbreviated',
                        context: 'formatting'
                    }) || match.dayPeriod(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });

                case 'BBBBB':
                    return match.dayPeriod(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });

                case 'BBBB':
                default:
                    return match.dayPeriod(dateString, {
                        width: 'wide',
                        context: 'formatting'
                    }) || match.dayPeriod(dateString, {
                        width: 'abbreviated',
                        context: 'formatting'
                    }) || match.dayPeriod(dateString, {
                        width: 'narrow',
                        context: 'formatting'
                    });
            }
        }

        set(date, _flags, value) {
            date.setUTCHours(dayPeriodEnumToHours(value), 0, 0, 0);
            return date;
        }

    }

    function _defineProperty$a(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    class Hour1to12Parser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$a(this, "priority", 70);

            _defineProperty$a(this, "incompatibleTokens", ['H', 'K', 'k', 't', 'T']);
        }

        parse(dateString, token, match) {
            switch (token) {
                case 'h':
                    return parseNumericPattern(numericPatterns.hour12h, dateString);

                case 'ho':
                    return match.ordinalNumber(dateString, {
                        unit: 'hour'
                    });

                default:
                    return parseNDigits(token.length, dateString);
            }
        }

        validate(_date, value) {
            return value >= 1 && value <= 12;
        }

        set(date, _flags, value) {
            var isPM = date.getUTCHours() >= 12;

            if (isPM && value < 12) {
                date.setUTCHours(value + 12, 0, 0, 0);
            } else if (!isPM && value === 12) {
                date.setUTCHours(0, 0, 0, 0);
            } else {
                date.setUTCHours(value, 0, 0, 0);
            }

            return date;
        }

    }

    function _defineProperty$9(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    class Hour0to23Parser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$9(this, "priority", 70);

            _defineProperty$9(this, "incompatibleTokens", ['a', 'b', 'h', 'K', 'k', 't', 'T']);
        }

        parse(dateString, token, match) {
            switch (token) {
                case 'H':
                    return parseNumericPattern(numericPatterns.hour23h, dateString);

                case 'Ho':
                    return match.ordinalNumber(dateString, {
                        unit: 'hour'
                    });

                default:
                    return parseNDigits(token.length, dateString);
            }
        }

        validate(_date, value) {
            return value >= 0 && value <= 23;
        }

        set(date, _flags, value) {
            date.setUTCHours(value, 0, 0, 0);
            return date;
        }

    }

    function _defineProperty$8(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    class Hour0To11Parser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$8(this, "priority", 70);

            _defineProperty$8(this, "incompatibleTokens", ['h', 'H', 'k', 't', 'T']);
        }

        parse(dateString, token, match) {
            switch (token) {
                case 'K':
                    return parseNumericPattern(numericPatterns.hour11h, dateString);

                case 'Ko':
                    return match.ordinalNumber(dateString, {
                        unit: 'hour'
                    });

                default:
                    return parseNDigits(token.length, dateString);
            }
        }

        validate(_date, value) {
            return value >= 0 && value <= 11;
        }

        set(date, _flags, value) {
            var isPM = date.getUTCHours() >= 12;

            if (isPM && value < 12) {
                date.setUTCHours(value + 12, 0, 0, 0);
            } else {
                date.setUTCHours(value, 0, 0, 0);
            }

            return date;
        }

    }

    function _defineProperty$7(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    class Hour1To24Parser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$7(this, "priority", 70);

            _defineProperty$7(this, "incompatibleTokens", ['a', 'b', 'h', 'H', 'K', 't', 'T']);
        }

        parse(dateString, token, match) {
            switch (token) {
                case 'k':
                    return parseNumericPattern(numericPatterns.hour24h, dateString);

                case 'ko':
                    return match.ordinalNumber(dateString, {
                        unit: 'hour'
                    });

                default:
                    return parseNDigits(token.length, dateString);
            }
        }

        validate(_date, value) {
            return value >= 1 && value <= 24;
        }

        set(date, _flags, value) {
            var hours = value <= 24 ? value % 24 : value;
            date.setUTCHours(hours, 0, 0, 0);
            return date;
        }

    }

    function _defineProperty$6(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    class MinuteParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$6(this, "priority", 60);

            _defineProperty$6(this, "incompatibleTokens", ['t', 'T']);
        }

        parse(dateString, token, match) {
            switch (token) {
                case 'm':
                    return parseNumericPattern(numericPatterns.minute, dateString);

                case 'mo':
                    return match.ordinalNumber(dateString, {
                        unit: 'minute'
                    });

                default:
                    return parseNDigits(token.length, dateString);
            }
        }

        validate(_date, value) {
            return value >= 0 && value <= 59;
        }

        set(date, _flags, value) {
            date.setUTCMinutes(value, 0, 0);
            return date;
        }

    }

    function _defineProperty$5(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    class SecondParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$5(this, "priority", 50);

            _defineProperty$5(this, "incompatibleTokens", ['t', 'T']);
        }

        parse(dateString, token, match) {
            switch (token) {
                case 's':
                    return parseNumericPattern(numericPatterns.second, dateString);

                case 'so':
                    return match.ordinalNumber(dateString, {
                        unit: 'second'
                    });

                default:
                    return parseNDigits(token.length, dateString);
            }
        }

        validate(_date, value) {
            return value >= 0 && value <= 59;
        }

        set(date, _flags, value) {
            date.setUTCSeconds(value, 0);
            return date;
        }

    }

    function _defineProperty$4(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    class FractionOfSecondParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$4(this, "priority", 30);

            _defineProperty$4(this, "incompatibleTokens", ['t', 'T']);
        }

        parse(dateString, token) {
            var valueCallback = function (value) {
                return Math.floor(value * Math.pow(10, -token.length + 3));
            };

            return mapValue(parseNDigits(token.length, dateString), valueCallback);
        }

        set(date, _flags, value) {
            date.setUTCMilliseconds(value);
            return date;
        }

    }

    function _defineProperty$3(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

    class ISOTimezoneWithZParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$3(this, "priority", 10);

            _defineProperty$3(this, "incompatibleTokens", ['t', 'T', 'x']);
        }

        parse(dateString, token) {
            switch (token) {
                case 'X':
                    return parseTimezonePattern(timezonePatterns.basicOptionalMinutes, dateString);

                case 'XX':
                    return parseTimezonePattern(timezonePatterns.basic, dateString);

                case 'XXXX':
                    return parseTimezonePattern(timezonePatterns.basicOptionalSeconds, dateString);

                case 'XXXXX':
                    return parseTimezonePattern(timezonePatterns.extendedOptionalSeconds, dateString);

                case 'XXX':
                default:
                    return parseTimezonePattern(timezonePatterns.extended, dateString);
            }
        }

        set(date, flags, value) {
            if (flags.timestampIsSet) {
                return date;
            }

            return new Date(date.getTime() - value);
        }

    }

    function _defineProperty$2(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

    class ISOTimezoneParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$2(this, "priority", 10);

            _defineProperty$2(this, "incompatibleTokens", ['t', 'T', 'X']);
        }

        parse(dateString, token) {
            switch (token) {
                case 'x':
                    return parseTimezonePattern(timezonePatterns.basicOptionalMinutes, dateString);

                case 'xx':
                    return parseTimezonePattern(timezonePatterns.basic, dateString);

                case 'xxxx':
                    return parseTimezonePattern(timezonePatterns.basicOptionalSeconds, dateString);

                case 'xxxxx':
                    return parseTimezonePattern(timezonePatterns.extendedOptionalSeconds, dateString);

                case 'xxx':
                default:
                    return parseTimezonePattern(timezonePatterns.extended, dateString);
            }
        }

        set(date, flags, value) {
            if (flags.timestampIsSet) {
                return date;
            }

            return new Date(date.getTime() - value);
        }

    }

    function _defineProperty$1(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    class TimestampSecondsParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty$1(this, "priority", 40);

            _defineProperty$1(this, "incompatibleTokens", '*');
        }

        parse(dateString) {
            return parseAnyDigitsSigned(dateString);
        }

        set(_date, _flags, value) {
            return [new Date(value * 1000), {
                timestampIsSet: true
            }];
        }

    }

    function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
    class TimestampMillisecondsParser extends Parser {
        constructor() {
            super(...arguments);

            _defineProperty(this, "priority", 20);

            _defineProperty(this, "incompatibleTokens", '*');
        }

        parse(dateString) {
            return parseAnyDigitsSigned(dateString);
        }

        set(_date, _flags, value) {
            return [new Date(value), {
                timestampIsSet: true
            }];
        }

    }

    /*
    * |     | Unit                           |     | Unit                           |
    * |-----|--------------------------------|-----|--------------------------------|
    * |  a  | AM, PM                         |  A* | Milliseconds in day            |
    * |  b  | AM, PM, noon, midnight         |  B  | Flexible day period            |
    * |  c  | Stand-alone local day of week  |  C* | Localized hour w/ day period   |
    * |  d  | Day of month                   |  D  | Day of year                    |
    * |  e  | Local day of week              |  E  | Day of week                    |
    * |  f  |                                |  F* | Day of week in month           |
    * |  g* | Modified Julian day            |  G  | Era                            |
    * |  h  | Hour [1-12]                    |  H  | Hour [0-23]                    |
    * |  i! | ISO day of week                |  I! | ISO week of year               |
    * |  j* | Localized hour w/ day period   |  J* | Localized hour w/o day period  |
    * |  k  | Hour [1-24]                    |  K  | Hour [0-11]                    |
    * |  l* | (deprecated)                   |  L  | Stand-alone month              |
    * |  m  | Minute                         |  M  | Month                          |
    * |  n  |                                |  N  |                                |
    * |  o! | Ordinal number modifier        |  O* | Timezone (GMT)                 |
    * |  p  |                                |  P  |                                |
    * |  q  | Stand-alone quarter            |  Q  | Quarter                        |
    * |  r* | Related Gregorian year         |  R! | ISO week-numbering year        |
    * |  s  | Second                         |  S  | Fraction of second             |
    * |  t! | Seconds timestamp              |  T! | Milliseconds timestamp         |
    * |  u  | Extended year                  |  U* | Cyclic year                    |
    * |  v* | Timezone (generic non-locat.)  |  V* | Timezone (location)            |
    * |  w  | Local week of year             |  W* | Week of month                  |
    * |  x  | Timezone (ISO-8601 w/o Z)      |  X  | Timezone (ISO-8601)            |
    * |  y  | Year (abs)                     |  Y  | Local week-numbering year      |
    * |  z* | Timezone (specific non-locat.) |  Z* | Timezone (aliases)             |
    *
    * Letters marked by * are not implemented but reserved by Unicode standard.
    *
    * Letters marked by ! are non-standard, but implemented by date-fns:
    * - `o` modifies the previous token to turn it into an ordinal (see `parse` docs)
    * - `i` is ISO day of week. For `i` and `ii` is returns numeric ISO week days,
    *   i.e. 7 for Sunday, 1 for Monday, etc.
    * - `I` is ISO week of year, as opposed to `w` which is local week of year.
    * - `R` is ISO week-numbering year, as opposed to `Y` which is local week-numbering year.
    *   `R` is supposed to be used in conjunction with `I` and `i`
    *   for universal ISO week-numbering date, whereas
    *   `Y` is supposed to be used in conjunction with `w` and `e`
    *   for week-numbering date specific to the locale.
    */

    var parsers = {
        G: new EraParser(),
        y: new YearParser(),
        Y: new LocalWeekYearParser(),
        R: new ISOWeekYearParser(),
        u: new ExtendedYearParser(),
        Q: new QuarterParser(),
        q: new StandAloneQuarterParser(),
        M: new MonthParser(),
        L: new StandAloneMonthParser(),
        w: new LocalWeekParser(),
        I: new ISOWeekParser(),
        d: new DateParser(),
        D: new DayOfYearParser(),
        E: new DayParser(),
        e: new LocalDayParser(),
        c: new StandAloneLocalDayParser(),
        i: new ISODayParser(),
        a: new AMPMParser(),
        b: new AMPMMidnightParser(),
        B: new DayPeriodParser(),
        h: new Hour1to12Parser(),
        H: new Hour0to23Parser(),
        K: new Hour0To11Parser(),
        k: new Hour1To24Parser(),
        m: new MinuteParser(),
        s: new SecondParser(),
        S: new FractionOfSecondParser(),
        X: new ISOTimezoneWithZParser(),
        x: new ISOTimezoneParser(),
        t: new TimestampSecondsParser(),
        T: new TimestampMillisecondsParser()
    };

    // - [yYQqMLwIdDecihHKkms]o matches any available ordinal number token
    //   (one of the certain letters followed by `o`)
    // - (\w)\1* matches any sequences of the same letter
    // - '' matches two quote characters in a row
    // - '(''|[^'])+('|$) matches anything surrounded by two quote characters ('),
    //   except a single quote symbol, which ends the sequence.
    //   Two quote characters do not end the sequence.
    //   If there is no matching single quote
    //   then the sequence will continue until the end of the string.
    // - . matches any single character unmatched by previous parts of the RegExps

    var formattingTokensRegExp = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g; // This RegExp catches symbols escaped by quotes, and also
    // sequences of symbols P, p, and the combinations like `PPPPPPPppppp`

    var longFormattingTokensRegExp = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g;
    var escapedStringRegExp = /^'([^]*?)'?$/;
    var doubleQuoteRegExp = /''/g;
    var notWhitespaceRegExp = /\S/;
    var unescapedLatinCharacterRegExp = /[a-zA-Z]/;
    /**
     * @name parse
     * @category Common Helpers
     * @summary Parse the date.
     *
     * @description
     * Return the date parsed from string using the given format string.
     *
     * > ⚠️ Please note that the `format` tokens differ from Moment.js and other libraries.
     * > See: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
     *
     * The characters in the format string wrapped between two single quotes characters (') are escaped.
     * Two single quotes in a row, whether inside or outside a quoted sequence, represent a 'real' single quote.
     *
     * Format of the format string is based on Unicode Technical Standard #35:
     * https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
     * with a few additions (see note 5 below the table).
     *
     * Not all tokens are compatible. Combinations that don't make sense or could lead to bugs are prohibited
     * and will throw `RangeError`. For example usage of 24-hour format token with AM/PM token will throw an exception:
     *
     * ```javascript
     * parse('23 AM', 'HH a', new Date())
     * //=> RangeError: The format string mustn't contain `HH` and `a` at the same time
     * ```
     *
     * See the compatibility table: https://docs.google.com/spreadsheets/d/e/2PACX-1vQOPU3xUhplll6dyoMmVUXHKl_8CRDs6_ueLmex3SoqwhuolkuN3O05l4rqx5h1dKX8eb46Ul-CCSrq/pubhtml?gid=0&single=true
     *
     * Accepted format string patterns:
     * | Unit                            |Prior| Pattern | Result examples                   | Notes |
     * |---------------------------------|-----|---------|-----------------------------------|-------|
     * | Era                             | 140 | G..GGG  | AD, BC                            |       |
     * |                                 |     | GGGG    | Anno Domini, Before Christ        | 2     |
     * |                                 |     | GGGGG   | A, B                              |       |
     * | Calendar year                   | 130 | y       | 44, 1, 1900, 2017, 9999           | 4     |
     * |                                 |     | yo      | 44th, 1st, 1900th, 9999999th      | 4,5   |
     * |                                 |     | yy      | 44, 01, 00, 17                    | 4     |
     * |                                 |     | yyy     | 044, 001, 123, 999                | 4     |
     * |                                 |     | yyyy    | 0044, 0001, 1900, 2017            | 4     |
     * |                                 |     | yyyyy   | ...                               | 2,4   |
     * | Local week-numbering year       | 130 | Y       | 44, 1, 1900, 2017, 9000           | 4     |
     * |                                 |     | Yo      | 44th, 1st, 1900th, 9999999th      | 4,5   |
     * |                                 |     | YY      | 44, 01, 00, 17                    | 4,6   |
     * |                                 |     | YYY     | 044, 001, 123, 999                | 4     |
     * |                                 |     | YYYY    | 0044, 0001, 1900, 2017            | 4,6   |
     * |                                 |     | YYYYY   | ...                               | 2,4   |
     * | ISO week-numbering year         | 130 | R       | -43, 1, 1900, 2017, 9999, -9999   | 4,5   |
     * |                                 |     | RR      | -43, 01, 00, 17                   | 4,5   |
     * |                                 |     | RRR     | -043, 001, 123, 999, -999         | 4,5   |
     * |                                 |     | RRRR    | -0043, 0001, 2017, 9999, -9999    | 4,5   |
     * |                                 |     | RRRRR   | ...                               | 2,4,5 |
     * | Extended year                   | 130 | u       | -43, 1, 1900, 2017, 9999, -999    | 4     |
     * |                                 |     | uu      | -43, 01, 99, -99                  | 4     |
     * |                                 |     | uuu     | -043, 001, 123, 999, -999         | 4     |
     * |                                 |     | uuuu    | -0043, 0001, 2017, 9999, -9999    | 4     |
     * |                                 |     | uuuuu   | ...                               | 2,4   |
     * | Quarter (formatting)            | 120 | Q       | 1, 2, 3, 4                        |       |
     * |                                 |     | Qo      | 1st, 2nd, 3rd, 4th                | 5     |
     * |                                 |     | QQ      | 01, 02, 03, 04                    |       |
     * |                                 |     | QQQ     | Q1, Q2, Q3, Q4                    |       |
     * |                                 |     | QQQQ    | 1st quarter, 2nd quarter, ...     | 2     |
     * |                                 |     | QQQQQ   | 1, 2, 3, 4                        | 4     |
     * | Quarter (stand-alone)           | 120 | q       | 1, 2, 3, 4                        |       |
     * |                                 |     | qo      | 1st, 2nd, 3rd, 4th                | 5     |
     * |                                 |     | qq      | 01, 02, 03, 04                    |       |
     * |                                 |     | qqq     | Q1, Q2, Q3, Q4                    |       |
     * |                                 |     | qqqq    | 1st quarter, 2nd quarter, ...     | 2     |
     * |                                 |     | qqqqq   | 1, 2, 3, 4                        | 3     |
     * | Month (formatting)              | 110 | M       | 1, 2, ..., 12                     |       |
     * |                                 |     | Mo      | 1st, 2nd, ..., 12th               | 5     |
     * |                                 |     | MM      | 01, 02, ..., 12                   |       |
     * |                                 |     | MMM     | Jan, Feb, ..., Dec                |       |
     * |                                 |     | MMMM    | January, February, ..., December  | 2     |
     * |                                 |     | MMMMM   | J, F, ..., D                      |       |
     * | Month (stand-alone)             | 110 | L       | 1, 2, ..., 12                     |       |
     * |                                 |     | Lo      | 1st, 2nd, ..., 12th               | 5     |
     * |                                 |     | LL      | 01, 02, ..., 12                   |       |
     * |                                 |     | LLL     | Jan, Feb, ..., Dec                |       |
     * |                                 |     | LLLL    | January, February, ..., December  | 2     |
     * |                                 |     | LLLLL   | J, F, ..., D                      |       |
     * | Local week of year              | 100 | w       | 1, 2, ..., 53                     |       |
     * |                                 |     | wo      | 1st, 2nd, ..., 53th               | 5     |
     * |                                 |     | ww      | 01, 02, ..., 53                   |       |
     * | ISO week of year                | 100 | I       | 1, 2, ..., 53                     | 5     |
     * |                                 |     | Io      | 1st, 2nd, ..., 53th               | 5     |
     * |                                 |     | II      | 01, 02, ..., 53                   | 5     |
     * | Day of month                    |  90 | d       | 1, 2, ..., 31                     |       |
     * |                                 |     | do      | 1st, 2nd, ..., 31st               | 5     |
     * |                                 |     | dd      | 01, 02, ..., 31                   |       |
     * | Day of year                     |  90 | D       | 1, 2, ..., 365, 366               | 7     |
     * |                                 |     | Do      | 1st, 2nd, ..., 365th, 366th       | 5     |
     * |                                 |     | DD      | 01, 02, ..., 365, 366             | 7     |
     * |                                 |     | DDD     | 001, 002, ..., 365, 366           |       |
     * |                                 |     | DDDD    | ...                               | 2     |
     * | Day of week (formatting)        |  90 | E..EEE  | Mon, Tue, Wed, ..., Sun           |       |
     * |                                 |     | EEEE    | Monday, Tuesday, ..., Sunday      | 2     |
     * |                                 |     | EEEEE   | M, T, W, T, F, S, S               |       |
     * |                                 |     | EEEEEE  | Mo, Tu, We, Th, Fr, Sa, Su        |       |
     * | ISO day of week (formatting)    |  90 | i       | 1, 2, 3, ..., 7                   | 5     |
     * |                                 |     | io      | 1st, 2nd, ..., 7th                | 5     |
     * |                                 |     | ii      | 01, 02, ..., 07                   | 5     |
     * |                                 |     | iii     | Mon, Tue, Wed, ..., Sun           | 5     |
     * |                                 |     | iiii    | Monday, Tuesday, ..., Sunday      | 2,5   |
     * |                                 |     | iiiii   | M, T, W, T, F, S, S               | 5     |
     * |                                 |     | iiiiii  | Mo, Tu, We, Th, Fr, Sa, Su        | 5     |
     * | Local day of week (formatting)  |  90 | e       | 2, 3, 4, ..., 1                   |       |
     * |                                 |     | eo      | 2nd, 3rd, ..., 1st                | 5     |
     * |                                 |     | ee      | 02, 03, ..., 01                   |       |
     * |                                 |     | eee     | Mon, Tue, Wed, ..., Sun           |       |
     * |                                 |     | eeee    | Monday, Tuesday, ..., Sunday      | 2     |
     * |                                 |     | eeeee   | M, T, W, T, F, S, S               |       |
     * |                                 |     | eeeeee  | Mo, Tu, We, Th, Fr, Sa, Su        |       |
     * | Local day of week (stand-alone) |  90 | c       | 2, 3, 4, ..., 1                   |       |
     * |                                 |     | co      | 2nd, 3rd, ..., 1st                | 5     |
     * |                                 |     | cc      | 02, 03, ..., 01                   |       |
     * |                                 |     | ccc     | Mon, Tue, Wed, ..., Sun           |       |
     * |                                 |     | cccc    | Monday, Tuesday, ..., Sunday      | 2     |
     * |                                 |     | ccccc   | M, T, W, T, F, S, S               |       |
     * |                                 |     | cccccc  | Mo, Tu, We, Th, Fr, Sa, Su        |       |
     * | AM, PM                          |  80 | a..aaa  | AM, PM                            |       |
     * |                                 |     | aaaa    | a.m., p.m.                        | 2     |
     * |                                 |     | aaaaa   | a, p                              |       |
     * | AM, PM, noon, midnight          |  80 | b..bbb  | AM, PM, noon, midnight            |       |
     * |                                 |     | bbbb    | a.m., p.m., noon, midnight        | 2     |
     * |                                 |     | bbbbb   | a, p, n, mi                       |       |
     * | Flexible day period             |  80 | B..BBB  | at night, in the morning, ...     |       |
     * |                                 |     | BBBB    | at night, in the morning, ...     | 2     |
     * |                                 |     | BBBBB   | at night, in the morning, ...     |       |
     * | Hour [1-12]                     |  70 | h       | 1, 2, ..., 11, 12                 |       |
     * |                                 |     | ho      | 1st, 2nd, ..., 11th, 12th         | 5     |
     * |                                 |     | hh      | 01, 02, ..., 11, 12               |       |
     * | Hour [0-23]                     |  70 | H       | 0, 1, 2, ..., 23                  |       |
     * |                                 |     | Ho      | 0th, 1st, 2nd, ..., 23rd          | 5     |
     * |                                 |     | HH      | 00, 01, 02, ..., 23               |       |
     * | Hour [0-11]                     |  70 | K       | 1, 2, ..., 11, 0                  |       |
     * |                                 |     | Ko      | 1st, 2nd, ..., 11th, 0th          | 5     |
     * |                                 |     | KK      | 01, 02, ..., 11, 00               |       |
     * | Hour [1-24]                     |  70 | k       | 24, 1, 2, ..., 23                 |       |
     * |                                 |     | ko      | 24th, 1st, 2nd, ..., 23rd         | 5     |
     * |                                 |     | kk      | 24, 01, 02, ..., 23               |       |
     * | Minute                          |  60 | m       | 0, 1, ..., 59                     |       |
     * |                                 |     | mo      | 0th, 1st, ..., 59th               | 5     |
     * |                                 |     | mm      | 00, 01, ..., 59                   |       |
     * | Second                          |  50 | s       | 0, 1, ..., 59                     |       |
     * |                                 |     | so      | 0th, 1st, ..., 59th               | 5     |
     * |                                 |     | ss      | 00, 01, ..., 59                   |       |
     * | Seconds timestamp               |  40 | t       | 512969520                         |       |
     * |                                 |     | tt      | ...                               | 2     |
     * | Fraction of second              |  30 | S       | 0, 1, ..., 9                      |       |
     * |                                 |     | SS      | 00, 01, ..., 99                   |       |
     * |                                 |     | SSS     | 000, 001, ..., 999                |       |
     * |                                 |     | SSSS    | ...                               | 2     |
     * | Milliseconds timestamp          |  20 | T       | 512969520900                      |       |
     * |                                 |     | TT      | ...                               | 2     |
     * | Timezone (ISO-8601 w/ Z)        |  10 | X       | -08, +0530, Z                     |       |
     * |                                 |     | XX      | -0800, +0530, Z                   |       |
     * |                                 |     | XXX     | -08:00, +05:30, Z                 |       |
     * |                                 |     | XXXX    | -0800, +0530, Z, +123456          | 2     |
     * |                                 |     | XXXXX   | -08:00, +05:30, Z, +12:34:56      |       |
     * | Timezone (ISO-8601 w/o Z)       |  10 | x       | -08, +0530, +00                   |       |
     * |                                 |     | xx      | -0800, +0530, +0000               |       |
     * |                                 |     | xxx     | -08:00, +05:30, +00:00            | 2     |
     * |                                 |     | xxxx    | -0800, +0530, +0000, +123456      |       |
     * |                                 |     | xxxxx   | -08:00, +05:30, +00:00, +12:34:56 |       |
     * | Long localized date             |  NA | P       | 05/29/1453                        | 5,8   |
     * |                                 |     | PP      | May 29, 1453                      |       |
     * |                                 |     | PPP     | May 29th, 1453                    |       |
     * |                                 |     | PPPP    | Sunday, May 29th, 1453            | 2,5,8 |
     * | Long localized time             |  NA | p       | 12:00 AM                          | 5,8   |
     * |                                 |     | pp      | 12:00:00 AM                       |       |
     * | Combination of date and time    |  NA | Pp      | 05/29/1453, 12:00 AM              |       |
     * |                                 |     | PPpp    | May 29, 1453, 12:00:00 AM         |       |
     * |                                 |     | PPPpp   | May 29th, 1453 at ...             |       |
     * |                                 |     | PPPPpp  | Sunday, May 29th, 1453 at ...     | 2,5,8 |
     * Notes:
     * 1. "Formatting" units (e.g. formatting quarter) in the default en-US locale
     *    are the same as "stand-alone" units, but are different in some languages.
     *    "Formatting" units are declined according to the rules of the language
     *    in the context of a date. "Stand-alone" units are always nominative singular.
     *    In `format` function, they will produce different result:
     *
     *    `format(new Date(2017, 10, 6), 'do LLLL', {locale: cs}) //=> '6. listopad'`
     *
     *    `format(new Date(2017, 10, 6), 'do MMMM', {locale: cs}) //=> '6. listopadu'`
     *
     *    `parse` will try to match both formatting and stand-alone units interchangably.
     *
     * 2. Any sequence of the identical letters is a pattern, unless it is escaped by
     *    the single quote characters (see below).
     *    If the sequence is longer than listed in table:
     *    - for numerical units (`yyyyyyyy`) `parse` will try to match a number
     *      as wide as the sequence
     *    - for text units (`MMMMMMMM`) `parse` will try to match the widest variation of the unit.
     *      These variations are marked with "2" in the last column of the table.
     *
     * 3. `QQQQQ` and `qqqqq` could be not strictly numerical in some locales.
     *    These tokens represent the shortest form of the quarter.
     *
     * 4. The main difference between `y` and `u` patterns are B.C. years:
     *
     *    | Year | `y` | `u` |
     *    |------|-----|-----|
     *    | AC 1 |   1 |   1 |
     *    | BC 1 |   1 |   0 |
     *    | BC 2 |   2 |  -1 |
     *
     *    Also `yy` will try to guess the century of two digit year by proximity with `referenceDate`:
     *
     *    `parse('50', 'yy', new Date(2018, 0, 1)) //=> Sat Jan 01 2050 00:00:00`
     *
     *    `parse('75', 'yy', new Date(2018, 0, 1)) //=> Wed Jan 01 1975 00:00:00`
     *
     *    while `uu` will just assign the year as is:
     *
     *    `parse('50', 'uu', new Date(2018, 0, 1)) //=> Sat Jan 01 0050 00:00:00`
     *
     *    `parse('75', 'uu', new Date(2018, 0, 1)) //=> Tue Jan 01 0075 00:00:00`
     *
     *    The same difference is true for local and ISO week-numbering years (`Y` and `R`),
     *    except local week-numbering years are dependent on `options.weekStartsOn`
     *    and `options.firstWeekContainsDate` (compare [setISOWeekYear]{@link https://date-fns.org/docs/setISOWeekYear}
     *    and [setWeekYear]{@link https://date-fns.org/docs/setWeekYear}).
     *
     * 5. These patterns are not in the Unicode Technical Standard #35:
     *    - `i`: ISO day of week
     *    - `I`: ISO week of year
     *    - `R`: ISO week-numbering year
     *    - `o`: ordinal number modifier
     *    - `P`: long localized date
     *    - `p`: long localized time
     *
     * 6. `YY` and `YYYY` tokens represent week-numbering years but they are often confused with years.
     *    You should enable `options.useAdditionalWeekYearTokens` to use them. See: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
     *
     * 7. `D` and `DD` tokens represent days of the year but they are ofthen confused with days of the month.
     *    You should enable `options.useAdditionalDayOfYearTokens` to use them. See: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
     *
     * 8. `P+` tokens do not have a defined priority since they are merely aliases to other tokens based
     *    on the given locale.
     *
     *    using `en-US` locale: `P` => `MM/dd/yyyy`
     *    using `en-US` locale: `p` => `hh:mm a`
     *    using `pt-BR` locale: `P` => `dd/MM/yyyy`
     *    using `pt-BR` locale: `p` => `HH:mm`
     *
     * Values will be assigned to the date in the descending order of its unit's priority.
     * Units of an equal priority overwrite each other in the order of appearance.
     *
     * If no values of higher priority are parsed (e.g. when parsing string 'January 1st' without a year),
     * the values will be taken from 3rd argument `referenceDate` which works as a context of parsing.
     *
     * `referenceDate` must be passed for correct work of the function.
     * If you're not sure which `referenceDate` to supply, create a new instance of Date:
     * `parse('02/11/2014', 'MM/dd/yyyy', new Date())`
     * In this case parsing will be done in the context of the current date.
     * If `referenceDate` is `Invalid Date` or a value not convertible to valid `Date`,
     * then `Invalid Date` will be returned.
     *
     * The result may vary by locale.
     *
     * If `formatString` matches with `dateString` but does not provides tokens, `referenceDate` will be returned.
     *
     * If parsing failed, `Invalid Date` will be returned.
     * Invalid Date is a Date, whose time value is NaN.
     * Time value of Date: http://es5.github.io/#x15.9.1.1
     *
     * @param {String} dateString - the string to parse
     * @param {String} formatString - the string of tokens
     * @param {Date|Number} referenceDate - defines values missing from the parsed dateString
     * @param {Object} [options] - an object with options.
     * @param {Locale} [options.locale=defaultLocale] - the locale object. See [Locale]{@link https://date-fns.org/docs/Locale}
     * @param {0|1|2|3|4|5|6} [options.weekStartsOn=0] - the index of the first day of the week (0 - Sunday)
     * @param {1|2|3|4|5|6|7} [options.firstWeekContainsDate=1] - the day of January, which is always in the first week of the year
     * @param {Boolean} [options.useAdditionalWeekYearTokens=false] - if true, allows usage of the week-numbering year tokens `YY` and `YYYY`;
     *   see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
     * @param {Boolean} [options.useAdditionalDayOfYearTokens=false] - if true, allows usage of the day of year tokens `D` and `DD`;
     *   see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
     * @returns {Date} the parsed date
     * @throws {TypeError} 3 arguments required
     * @throws {RangeError} `options.weekStartsOn` must be between 0 and 6
     * @throws {RangeError} `options.firstWeekContainsDate` must be between 1 and 7
     * @throws {RangeError} `options.locale` must contain `match` property
     * @throws {RangeError} use `yyyy` instead of `YYYY` for formatting years using [format provided] to the input [input provided]; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
     * @throws {RangeError} use `yy` instead of `YY` for formatting years using [format provided] to the input [input provided]; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
     * @throws {RangeError} use `d` instead of `D` for formatting days of the month using [format provided] to the input [input provided]; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
     * @throws {RangeError} use `dd` instead of `DD` for formatting days of the month using [format provided] to the input [input provided]; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
     * @throws {RangeError} format string contains an unescaped latin alphabet character
     *
     * @example
     * // Parse 11 February 2014 from middle-endian format:
     * var result = parse('02/11/2014', 'MM/dd/yyyy', new Date())
     * //=> Tue Feb 11 2014 00:00:00
     *
     * @example
     * // Parse 28th of February in Esperanto locale in the context of 2010 year:
     * import eo from 'date-fns/locale/eo'
     * var result = parse('28-a de februaro', "do 'de' MMMM", new Date(2010, 0, 1), {
     *   locale: eo
     * })
     * //=> Sun Feb 28 2010 00:00:00
     */

    function parse(dirtyDateString, dirtyFormatString, dirtyReferenceDate, options) {
        var _ref, _options$locale, _ref2, _ref3, _ref4, _options$firstWeekCon, _options$locale2, _options$locale2$opti, _defaultOptions$local, _defaultOptions$local2, _ref5, _ref6, _ref7, _options$weekStartsOn, _options$locale3, _options$locale3$opti, _defaultOptions$local3, _defaultOptions$local4;

        requiredArgs(3, arguments);
        var dateString = String(dirtyDateString);
        var formatString = String(dirtyFormatString);
        var defaultOptions = getDefaultOptions();
        var locale = (_ref = (_options$locale = options === null || options === void 0 ? void 0 : options.locale) !== null && _options$locale !== void 0 ? _options$locale : defaultOptions.locale) !== null && _ref !== void 0 ? _ref : defaultLocale;

        if (!locale.match) {
            throw new RangeError('locale must contain match property');
        }

        var firstWeekContainsDate = toInteger((_ref2 = (_ref3 = (_ref4 = (_options$firstWeekCon = options === null || options === void 0 ? void 0 : options.firstWeekContainsDate) !== null && _options$firstWeekCon !== void 0 ? _options$firstWeekCon : options === null || options === void 0 ? void 0 : (_options$locale2 = options.locale) === null || _options$locale2 === void 0 ? void 0 : (_options$locale2$opti = _options$locale2.options) === null || _options$locale2$opti === void 0 ? void 0 : _options$locale2$opti.firstWeekContainsDate) !== null && _ref4 !== void 0 ? _ref4 : defaultOptions.firstWeekContainsDate) !== null && _ref3 !== void 0 ? _ref3 : (_defaultOptions$local = defaultOptions.locale) === null || _defaultOptions$local === void 0 ? void 0 : (_defaultOptions$local2 = _defaultOptions$local.options) === null || _defaultOptions$local2 === void 0 ? void 0 : _defaultOptions$local2.firstWeekContainsDate) !== null && _ref2 !== void 0 ? _ref2 : 1); // Test if weekStartsOn is between 1 and 7 _and_ is not NaN

        if (!(firstWeekContainsDate >= 1 && firstWeekContainsDate <= 7)) {
            throw new RangeError('firstWeekContainsDate must be between 1 and 7 inclusively');
        }

        var weekStartsOn = toInteger((_ref5 = (_ref6 = (_ref7 = (_options$weekStartsOn = options === null || options === void 0 ? void 0 : options.weekStartsOn) !== null && _options$weekStartsOn !== void 0 ? _options$weekStartsOn : options === null || options === void 0 ? void 0 : (_options$locale3 = options.locale) === null || _options$locale3 === void 0 ? void 0 : (_options$locale3$opti = _options$locale3.options) === null || _options$locale3$opti === void 0 ? void 0 : _options$locale3$opti.weekStartsOn) !== null && _ref7 !== void 0 ? _ref7 : defaultOptions.weekStartsOn) !== null && _ref6 !== void 0 ? _ref6 : (_defaultOptions$local3 = defaultOptions.locale) === null || _defaultOptions$local3 === void 0 ? void 0 : (_defaultOptions$local4 = _defaultOptions$local3.options) === null || _defaultOptions$local4 === void 0 ? void 0 : _defaultOptions$local4.weekStartsOn) !== null && _ref5 !== void 0 ? _ref5 : 0); // Test if weekStartsOn is between 0 and 6 _and_ is not NaN

        if (!(weekStartsOn >= 0 && weekStartsOn <= 6)) {
            throw new RangeError('weekStartsOn must be between 0 and 6 inclusively');
        }

        if (formatString === '') {
            if (dateString === '') {
                return toDate(dirtyReferenceDate);
            } else {
                return new Date(NaN);
            }
        }

        var subFnOptions = {
            firstWeekContainsDate: firstWeekContainsDate,
            weekStartsOn: weekStartsOn,
            locale: locale
        }; // If timezone isn't specified, it will be set to the system timezone

        var setters = [new DateToSystemTimezoneSetter()];
        var tokens = formatString.match(longFormattingTokensRegExp).map(function (substring) {
            var firstCharacter = substring[0];

            if (firstCharacter in longFormatters$1) {
                var longFormatter = longFormatters$1[firstCharacter];
                return longFormatter(substring, locale.formatLong);
            }

            return substring;
        }).join('').match(formattingTokensRegExp);
        var usedTokens = [];

        var _loop = function (_token) {
            if (!(options !== null && options !== void 0 && options.useAdditionalWeekYearTokens) && isProtectedWeekYearToken(_token)) {
                throwProtectedError(_token, formatString, dirtyDateString);
            }

            if (!(options !== null && options !== void 0 && options.useAdditionalDayOfYearTokens) && isProtectedDayOfYearToken(_token)) {
                throwProtectedError(_token, formatString, dirtyDateString);
            }

            var firstCharacter = _token[0];
            var parser = parsers[firstCharacter];

            if (parser) {
                var incompatibleTokens = parser.incompatibleTokens;

                if (Array.isArray(incompatibleTokens)) {
                    var incompatibleToken = usedTokens.find(function (usedToken) {
                        return incompatibleTokens.includes(usedToken.token) || usedToken.token === firstCharacter;
                    });

                    if (incompatibleToken) {
                        throw new RangeError("The format string mustn't contain `".concat(incompatibleToken.fullToken, "` and `").concat(_token, "` at the same time"));
                    }
                } else if (parser.incompatibleTokens === '*' && usedTokens.length > 0) {
                    throw new RangeError("The format string mustn't contain `".concat(_token, "` and any other token at the same time"));
                }

                usedTokens.push({
                    token: firstCharacter,
                    fullToken: _token
                });
                var parseResult = parser.run(dateString, _token, locale.match, subFnOptions);

                if (!parseResult) {
                    token = _token;
                    return {
                        v: new Date(NaN)
                    };
                }

                setters.push(parseResult.setter);
                dateString = parseResult.rest;
            } else {
                if (firstCharacter.match(unescapedLatinCharacterRegExp)) {
                    throw new RangeError('Format string contains an unescaped latin alphabet character `' + firstCharacter + '`');
                } // Replace two single quote characters with one single quote character


                if (_token === "''") {
                    _token = "'";
                } else if (firstCharacter === "'") {
                    _token = cleanEscapedString(_token);
                } // Cut token from string, or, if string doesn't match the token, return Invalid Date


                if (dateString.indexOf(_token) === 0) {
                    dateString = dateString.slice(_token.length);
                } else {
                    token = _token;
                    return {
                        v: new Date(NaN)
                    };
                }
            }

            token = _token;
        };

        for (var token of tokens) {
            var _ret = _loop(token);

            if (typeof _ret === "object") return _ret.v;
        } // Check if the remaining input contains something other than whitespace


        if (dateString.length > 0 && notWhitespaceRegExp.test(dateString)) {
            return new Date(NaN);
        }

        var uniquePrioritySetters = setters.map(function (setter) {
            return setter.priority;
        }).sort(function (a, b) {
            return b - a;
        }).filter(function (priority, index, array) {
            return array.indexOf(priority) === index;
        }).map(function (priority) {
            return setters.filter(function (setter) {
                return setter.priority === priority;
            }).sort(function (a, b) {
                return b.subPriority - a.subPriority;
            });
        }).map(function (setterArray) {
            return setterArray[0];
        });
        var date = toDate(dirtyReferenceDate);

        if (isNaN(date.getTime())) {
            return new Date(NaN);
        } // Convert the date in system timezone to the same date in UTC+00:00 timezone.


        var utcDate = subMilliseconds(date, getTimezoneOffsetInMilliseconds(date));
        var flags = {};

        for (var setter of uniquePrioritySetters) {
            if (!setter.validate(utcDate, subFnOptions)) {
                return new Date(NaN);
            }

            var result = setter.set(utcDate, flags, subFnOptions); // Result is tuple (date, flags)

            if (Array.isArray(result)) {
                utcDate = result[0];
                assign(flags, result[1]); // Result is date
            } else {
                utcDate = result;
            }
        }

        return utcDate;
    }

    function cleanEscapedString(input) {
        return input.match(escapedStringRegExp)[1].replace(doubleQuoteRegExp, "'");
    }

    /**
     * @name subDays
     * @category Day Helpers
     * @summary Subtract the specified number of days from the given date.
     *
     * @description
     * Subtract the specified number of days from the given date.
     *
     * @param {Date|Number} date - the date to be changed
     * @param {Number} amount - the amount of days to be subtracted. Positive decimals will be rounded using `Math.floor`, decimals less than zero will be rounded using `Math.ceil`.
     * @returns {Date} the new date with the days subtracted
     * @throws {TypeError} 2 arguments required
     *
     * @example
     * // Subtract 10 days from 1 September 2014:
     * const result = subDays(new Date(2014, 8, 1), 10)
     * //=> Fri Aug 22 2014 00:00:00
     */

    function subDays(dirtyDate, dirtyAmount) {
        requiredArgs(2, arguments);
        var amount = toInteger(dirtyAmount);
        return addDays(dirtyDate, -amount);
    }

    /**
     * @name parseISO
     * @category Common Helpers
     * @summary Parse ISO string
     *
     * @description
     * Parse the given string in ISO 8601 format and return an instance of Date.
     *
     * Function accepts complete ISO 8601 formats as well as partial implementations.
     * ISO 8601: http://en.wikipedia.org/wiki/ISO_8601
     *
     * If the argument isn't a string, the function cannot parse the string or
     * the values are invalid, it returns Invalid Date.
     *
     * @param {String} argument - the value to convert
     * @param {Object} [options] - an object with options.
     * @param {0|1|2} [options.additionalDigits=2] - the additional number of digits in the extended year format
     * @returns {Date} the parsed date in the local time zone
     * @throws {TypeError} 1 argument required
     * @throws {RangeError} `options.additionalDigits` must be 0, 1 or 2
     *
     * @example
     * // Convert string '2014-02-11T11:30:30' to date:
     * const result = parseISO('2014-02-11T11:30:30')
     * //=> Tue Feb 11 2014 11:30:30
     *
     * @example
     * // Convert string '+02014101' to date,
     * // if the additional number of digits in the extended year format is 1:
     * const result = parseISO('+02014101', { additionalDigits: 1 })
     * //=> Fri Apr 11 2014 00:00:00
     */

    function parseISO(argument, options) {
        var _options$additionalDi;

        requiredArgs(1, arguments);
        var additionalDigits = toInteger((_options$additionalDi = options === null || options === void 0 ? void 0 : options.additionalDigits) !== null && _options$additionalDi !== void 0 ? _options$additionalDi : 2);

        if (additionalDigits !== 2 && additionalDigits !== 1 && additionalDigits !== 0) {
            throw new RangeError('additionalDigits must be 0, 1 or 2');
        }

        if (!(typeof argument === 'string' || Object.prototype.toString.call(argument) === '[object String]')) {
            return new Date(NaN);
        }

        var dateStrings = splitDateString(argument);
        var date;

        if (dateStrings.date) {
            var parseYearResult = parseYear(dateStrings.date, additionalDigits);
            date = parseDate(parseYearResult.restDateString, parseYearResult.year);
        }

        if (!date || isNaN(date.getTime())) {
            return new Date(NaN);
        }

        var timestamp = date.getTime();
        var time = 0;
        var offset;

        if (dateStrings.time) {
            time = parseTime(dateStrings.time);

            if (isNaN(time)) {
                return new Date(NaN);
            }
        }

        if (dateStrings.timezone) {
            offset = parseTimezone(dateStrings.timezone);

            if (isNaN(offset)) {
                return new Date(NaN);
            }
        } else {
            var dirtyDate = new Date(timestamp + time); // js parsed string assuming it's in UTC timezone
            // but we need it to be parsed in our timezone
            // so we use utc values to build date in our timezone.
            // Year values from 0 to 99 map to the years 1900 to 1999
            // so set year explicitly with setFullYear.

            var result = new Date(0);
            result.setFullYear(dirtyDate.getUTCFullYear(), dirtyDate.getUTCMonth(), dirtyDate.getUTCDate());
            result.setHours(dirtyDate.getUTCHours(), dirtyDate.getUTCMinutes(), dirtyDate.getUTCSeconds(), dirtyDate.getUTCMilliseconds());
            return result;
        }

        return new Date(timestamp + time + offset);
    }
    var patterns = {
        dateTimeDelimiter: /[T ]/,
        timeZoneDelimiter: /[Z ]/i,
        timezone: /([Z+-].*)$/
    };
    var dateRegex = /^-?(?:(\d{3})|(\d{2})(?:-?(\d{2}))?|W(\d{2})(?:-?(\d{1}))?|)$/;
    var timeRegex = /^(\d{2}(?:[.,]\d*)?)(?::?(\d{2}(?:[.,]\d*)?))?(?::?(\d{2}(?:[.,]\d*)?))?$/;
    var timezoneRegex = /^([+-])(\d{2})(?::?(\d{2}))?$/;

    function splitDateString(dateString) {
        var dateStrings = {};
        var array = dateString.split(patterns.dateTimeDelimiter);
        var timeString; // The regex match should only return at maximum two array elements.
        // [date], [time], or [date, time].

        if (array.length > 2) {
            return dateStrings;
        }

        if (/:/.test(array[0])) {
            timeString = array[0];
        } else {
            dateStrings.date = array[0];
            timeString = array[1];

            if (patterns.timeZoneDelimiter.test(dateStrings.date)) {
                dateStrings.date = dateString.split(patterns.timeZoneDelimiter)[0];
                timeString = dateString.substr(dateStrings.date.length, dateString.length);
            }
        }

        if (timeString) {
            var token = patterns.timezone.exec(timeString);

            if (token) {
                dateStrings.time = timeString.replace(token[1], '');
                dateStrings.timezone = token[1];
            } else {
                dateStrings.time = timeString;
            }
        }

        return dateStrings;
    }

    function parseYear(dateString, additionalDigits) {
        var regex = new RegExp('^(?:(\\d{4}|[+-]\\d{' + (4 + additionalDigits) + '})|(\\d{2}|[+-]\\d{' + (2 + additionalDigits) + '})$)');
        var captures = dateString.match(regex); // Invalid ISO-formatted year

        if (!captures) return {
            year: NaN,
            restDateString: ''
        };
        var year = captures[1] ? parseInt(captures[1]) : null;
        var century = captures[2] ? parseInt(captures[2]) : null; // either year or century is null, not both

        return {
            year: century === null ? year : century * 100,
            restDateString: dateString.slice((captures[1] || captures[2]).length)
        };
    }

    function parseDate(dateString, year) {
        // Invalid ISO-formatted year
        if (year === null) return new Date(NaN);
        var captures = dateString.match(dateRegex); // Invalid ISO-formatted string

        if (!captures) return new Date(NaN);
        var isWeekDate = !!captures[4];
        var dayOfYear = parseDateUnit(captures[1]);
        var month = parseDateUnit(captures[2]) - 1;
        var day = parseDateUnit(captures[3]);
        var week = parseDateUnit(captures[4]);
        var dayOfWeek = parseDateUnit(captures[5]) - 1;

        if (isWeekDate) {
            if (!validateWeekDate(year, week, dayOfWeek)) {
                return new Date(NaN);
            }

            return dayOfISOWeekYear(year, week, dayOfWeek);
        } else {
            var date = new Date(0);

            if (!validateDate(year, month, day) || !validateDayOfYearDate(year, dayOfYear)) {
                return new Date(NaN);
            }

            date.setUTCFullYear(year, month, Math.max(dayOfYear, day));
            return date;
        }
    }

    function parseDateUnit(value) {
        return value ? parseInt(value) : 1;
    }

    function parseTime(timeString) {
        var captures = timeString.match(timeRegex);
        if (!captures) return NaN; // Invalid ISO-formatted time

        var hours = parseTimeUnit(captures[1]);
        var minutes = parseTimeUnit(captures[2]);
        var seconds = parseTimeUnit(captures[3]);

        if (!validateTime(hours, minutes, seconds)) {
            return NaN;
        }

        return hours * millisecondsInHour + minutes * millisecondsInMinute + seconds * 1000;
    }

    function parseTimeUnit(value) {
        return value && parseFloat(value.replace(',', '.')) || 0;
    }

    function parseTimezone(timezoneString) {
        if (timezoneString === 'Z') return 0;
        var captures = timezoneString.match(timezoneRegex);
        if (!captures) return 0;
        var sign = captures[1] === '+' ? -1 : 1;
        var hours = parseInt(captures[2]);
        var minutes = captures[3] && parseInt(captures[3]) || 0;

        if (!validateTimezone(hours, minutes)) {
            return NaN;
        }

        return sign * (hours * millisecondsInHour + minutes * millisecondsInMinute);
    }

    function dayOfISOWeekYear(isoWeekYear, week, day) {
        var date = new Date(0);
        date.setUTCFullYear(isoWeekYear, 0, 4);
        var fourthOfJanuaryDay = date.getUTCDay() || 7;
        var diff = (week - 1) * 7 + day + 1 - fourthOfJanuaryDay;
        date.setUTCDate(date.getUTCDate() + diff);
        return date;
    } // Validation functions
    // February is null to handle the leap year (using ||)


    var daysInMonths = [31, null, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    function isLeapYearIndex(year) {
        return year % 400 === 0 || year % 4 === 0 && year % 100 !== 0;
    }

    function validateDate(year, month, date) {
        return month >= 0 && month <= 11 && date >= 1 && date <= (daysInMonths[month] || (isLeapYearIndex(year) ? 29 : 28));
    }

    function validateDayOfYearDate(year, dayOfYear) {
        return dayOfYear >= 1 && dayOfYear <= (isLeapYearIndex(year) ? 366 : 365);
    }

    function validateWeekDate(_year, week, day) {
        return week >= 1 && week <= 53 && day >= 0 && day <= 6;
    }

    function validateTime(hours, minutes, seconds) {
        if (hours === 24) {
            return minutes === 0 && seconds === 0;
        }

        return seconds >= 0 && seconds < 60 && minutes >= 0 && minutes < 60 && hours >= 0 && hours < 25;
    }

    function validateTimezone(_hours, minutes) {
        return minutes >= 0 && minutes <= 59;
    }

    /**
     * @name setMonth
     * @category Month Helpers
     * @summary Set the month to the given date.
     *
     * @description
     * Set the month to the given date.
     *
     * @param {Date|Number} date - the date to be changed
     * @param {Number} month - the month of the new date
     * @returns {Date} the new date with the month set
     * @throws {TypeError} 2 arguments required
     *
     * @example
     * // Set February to 1 September 2014:
     * const result = setMonth(new Date(2014, 8, 1), 1)
     * //=> Sat Feb 01 2014 00:00:00
     */

    function setMonth(dirtyDate, dirtyMonth) {
        requiredArgs(2, arguments);
        var date = toDate(dirtyDate);
        var month = toInteger(dirtyMonth);
        var year = date.getFullYear();
        var day = date.getDate();
        var dateWithDesiredMonth = new Date(0);
        dateWithDesiredMonth.setFullYear(year, month, 15);
        dateWithDesiredMonth.setHours(0, 0, 0, 0);
        var daysInMonth = getDaysInMonth(dateWithDesiredMonth); // Set the last day of the new month
        // if the original date was the last day of the longer month

        date.setMonth(month, Math.min(day, daysInMonth));
        return date;
    }

    /**
     * @name set
     * @category Common Helpers
     * @summary Set date values to a given date.
     *
     * @description
     * Set date values to a given date.
     *
     * Sets time values to date from object `values`.
     * A value is not set if it is undefined or null or doesn't exist in `values`.
     *
     * Note about bundle size: `set` does not internally use `setX` functions from date-fns but instead opts
     * to use native `Date#setX` methods. If you use this function, you may not want to include the
     * other `setX` functions that date-fns provides if you are concerned about the bundle size.
     *
     * @param {Date|Number} date - the date to be changed
     * @param {Object} values - an object with options
     * @param {Number} [values.year] - the number of years to be set
     * @param {Number} [values.month] - the number of months to be set
     * @param {Number} [values.date] - the number of days to be set
     * @param {Number} [values.hours] - the number of hours to be set
     * @param {Number} [values.minutes] - the number of minutes to be set
     * @param {Number} [values.seconds] - the number of seconds to be set
     * @param {Number} [values.milliseconds] - the number of milliseconds to be set
     * @returns {Date} the new date with options set
     * @throws {TypeError} 2 arguments required
     * @throws {RangeError} `values` must be an object
     *
     * @example
     * // Transform 1 September 2014 into 20 October 2015 in a single line:
     * const result = set(new Date(2014, 8, 20), { year: 2015, month: 9, date: 20 })
     * //=> Tue Oct 20 2015 00:00:00
     *
     * @example
     * // Set 12 PM to 1 September 2014 01:23:45 to 1 September 2014 12:00:00:
     * const result = set(new Date(2014, 8, 1, 1, 23, 45), { hours: 12 })
     * //=> Mon Sep 01 2014 12:23:45
     */
    function set(dirtyDate, values) {
        requiredArgs(2, arguments);

        if (typeof values !== 'object' || values === null) {
            throw new RangeError('values parameter must be an object');
        }

        var date = toDate(dirtyDate); // Check if date is Invalid Date because Date.prototype.setFullYear ignores the value of Invalid Date

        if (isNaN(date.getTime())) {
            return new Date(NaN);
        }

        if (values.year != null) {
            date.setFullYear(values.year);
        }

        if (values.month != null) {
            date = setMonth(date, values.month);
        }

        if (values.date != null) {
            date.setDate(toInteger(values.date));
        }

        if (values.hours != null) {
            date.setHours(toInteger(values.hours));
        }

        if (values.minutes != null) {
            date.setMinutes(toInteger(values.minutes));
        }

        if (values.seconds != null) {
            date.setSeconds(toInteger(values.seconds));
        }

        if (values.milliseconds != null) {
            date.setMilliseconds(toInteger(values.milliseconds));
        }

        return date;
    }

    /**
     * @name setHours
     * @category Hour Helpers
     * @summary Set the hours to the given date.
     *
     * @description
     * Set the hours to the given date.
     *
     * @param {Date|Number} date - the date to be changed
     * @param {Number} hours - the hours of the new date
     * @returns {Date} the new date with the hours set
     * @throws {TypeError} 2 arguments required
     *
     * @example
     * // Set 4 hours to 1 September 2014 11:30:00:
     * const result = setHours(new Date(2014, 8, 1, 11, 30), 4)
     * //=> Mon Sep 01 2014 04:30:00
     */

    function setHours(dirtyDate, dirtyHours) {
        requiredArgs(2, arguments);
        var date = toDate(dirtyDate);
        var hours = toInteger(dirtyHours);
        date.setHours(hours);
        return date;
    }

    /**
     * @name setMilliseconds
     * @category Millisecond Helpers
     * @summary Set the milliseconds to the given date.
     *
     * @description
     * Set the milliseconds to the given date.
     *
     * @param {Date|Number} date - the date to be changed
     * @param {Number} milliseconds - the milliseconds of the new date
     * @returns {Date} the new date with the milliseconds set
     * @throws {TypeError} 2 arguments required
     *
     * @example
     * // Set 300 milliseconds to 1 September 2014 11:30:40.500:
     * const result = setMilliseconds(new Date(2014, 8, 1, 11, 30, 40, 500), 300)
     * //=> Mon Sep 01 2014 11:30:40.300
     */

    function setMilliseconds(dirtyDate, dirtyMilliseconds) {
        requiredArgs(2, arguments);
        var date = toDate(dirtyDate);
        var milliseconds = toInteger(dirtyMilliseconds);
        date.setMilliseconds(milliseconds);
        return date;
    }

    /**
     * @name setMinutes
     * @category Minute Helpers
     * @summary Set the minutes to the given date.
     *
     * @description
     * Set the minutes to the given date.
     *
     * @param {Date|Number} date - the date to be changed
     * @param {Number} minutes - the minutes of the new date
     * @returns {Date} the new date with the minutes set
     * @throws {TypeError} 2 arguments required
     *
     * @example
     * // Set 45 minutes to 1 September 2014 11:30:40:
     * const result = setMinutes(new Date(2014, 8, 1, 11, 30, 40), 45)
     * //=> Mon Sep 01 2014 11:45:40
     */

    function setMinutes(dirtyDate, dirtyMinutes) {
        requiredArgs(2, arguments);
        var date = toDate(dirtyDate);
        var minutes = toInteger(dirtyMinutes);
        date.setMinutes(minutes);
        return date;
    }

    /**
     * @name setSeconds
     * @category Second Helpers
     * @summary Set the seconds to the given date.
     *
     * @description
     * Set the seconds to the given date.
     *
     * @param {Date|Number} date - the date to be changed
     * @param {Number} seconds - the seconds of the new date
     * @returns {Date} the new date with the seconds set
     * @throws {TypeError} 2 arguments required
     *
     * @example
     * // Set 45 seconds to 1 September 2014 11:30:40:
     * const result = setSeconds(new Date(2014, 8, 1, 11, 30, 40), 45)
     * //=> Mon Sep 01 2014 11:30:45
     */

    function setSeconds(dirtyDate, dirtySeconds) {
        requiredArgs(2, arguments);
        var date = toDate(dirtyDate);
        var seconds = toInteger(dirtySeconds);
        date.setSeconds(seconds);
        return date;
    }

    /**
     * @name setYear
     * @category Year Helpers
     * @summary Set the year to the given date.
     *
     * @description
     * Set the year to the given date.
     *
     * @param {Date|Number} date - the date to be changed
     * @param {Number} year - the year of the new date
     * @returns {Date} the new date with the year set
     * @throws {TypeError} 2 arguments required
     *
     * @example
     * // Set year 2013 to 1 September 2014:
     * const result = setYear(new Date(2014, 8, 1), 2013)
     * //=> Sun Sep 01 2013 00:00:00
     */

    function setYear(dirtyDate, dirtyYear) {
        requiredArgs(2, arguments);
        var date = toDate(dirtyDate);
        var year = toInteger(dirtyYear); // Check if date is Invalid Date because Date.prototype.setFullYear ignores the value of Invalid Date

        if (isNaN(date.getTime())) {
            return new Date(NaN);
        }

        date.setFullYear(year);
        return date;
    }

    /**
     * @name subMonths
     * @category Month Helpers
     * @summary Subtract the specified number of months from the given date.
     *
     * @description
     * Subtract the specified number of months from the given date.
     *
     * @param {Date|Number} date - the date to be changed
     * @param {Number} amount - the amount of months to be subtracted. Positive decimals will be rounded using `Math.floor`, decimals less than zero will be rounded using `Math.ceil`.
     * @returns {Date} the new date with the months subtracted
     * @throws {TypeError} 2 arguments required
     *
     * @example
     * // Subtract 5 months from 1 February 2015:
     * const result = subMonths(new Date(2015, 1, 1), 5)
     * //=> Mon Sep 01 2014 00:00:00
     */

    function subMonths(dirtyDate, dirtyAmount) {
        requiredArgs(2, arguments);
        var amount = toInteger(dirtyAmount);
        return addMonths(dirtyDate, -amount);
    }

    /**
     * @name sub
     * @category Common Helpers
     * @summary Subtract the specified years, months, weeks, days, hours, minutes and seconds from the given date.
     *
     * @description
     * Subtract the specified years, months, weeks, days, hours, minutes and seconds from the given date.
     *
     * @param {Date|Number} date - the date to be changed
     * @param {Duration} duration - the object with years, months, weeks, days, hours, minutes and seconds to be subtracted
     *
     * | Key     | Description                        |
     * |---------|------------------------------------|
     * | years   | Amount of years to be subtracted   |
     * | months  | Amount of months to be subtracted  |
     * | weeks   | Amount of weeks to be subtracted   |
     * | days    | Amount of days to be subtracted    |
     * | hours   | Amount of hours to be subtracted   |
     * | minutes | Amount of minutes to be subtracted |
     * | seconds | Amount of seconds to be subtracted |
     *
     * All values default to 0
     *
     * @returns {Date} the new date with the seconds subtracted
     * @throws {TypeError} 2 arguments required
     *
     * @example
     * // Subtract the following duration from 15 June 2017 15:29:20
     * const result = sub(new Date(2017, 5, 15, 15, 29, 20), {
     *   years: 2,
     *   months: 9,
     *   weeks: 1,
     *   days: 7,
     *   hours: 5,
     *   minutes: 9,
     *   seconds: 30
     * })
     * //=> Mon Sep 1 2014 10:19:50
     */

    function sub(date, duration) {
        requiredArgs(2, arguments);
        if (!duration || typeof duration !== 'object') return new Date(NaN);
        var years = duration.years ? toInteger(duration.years) : 0;
        var months = duration.months ? toInteger(duration.months) : 0;
        var weeks = duration.weeks ? toInteger(duration.weeks) : 0;
        var days = duration.days ? toInteger(duration.days) : 0;
        var hours = duration.hours ? toInteger(duration.hours) : 0;
        var minutes = duration.minutes ? toInteger(duration.minutes) : 0;
        var seconds = duration.seconds ? toInteger(duration.seconds) : 0; // Subtract years and months

        var dateWithoutMonths = subMonths(date, months + years * 12); // Subtract weeks and days

        var dateWithoutDays = subDays(dateWithoutMonths, days + weeks * 7); // Subtract hours, minutes and seconds

        var minutestoSub = minutes + hours * 60;
        var secondstoSub = seconds + minutestoSub * 60;
        var mstoSub = secondstoSub * 1000;
        var finalDate = new Date(dateWithoutDays.getTime() - mstoSub);
        return finalDate;
    }

    /**
     * @name subYears
     * @category Year Helpers
     * @summary Subtract the specified number of years from the given date.
     *
     * @description
     * Subtract the specified number of years from the given date.
     *
     * @param {Date|Number} date - the date to be changed
     * @param {Number} amount - the amount of years to be subtracted. Positive decimals will be rounded using `Math.floor`, decimals less than zero will be rounded using `Math.ceil`.
     * @returns {Date} the new date with the years subtracted
     * @throws {TypeError} 2 arguments required
     *
     * @example
     * // Subtract 5 years from 1 September 2014:
     * const result = subYears(new Date(2014, 8, 1), 5)
     * //=> Tue Sep 01 2009 00:00:00
     */

    function subYears(dirtyDate, dirtyAmount) {
        requiredArgs(2, arguments);
        var amount = toInteger(dirtyAmount);
        return addYears(dirtyDate, -amount);
    }

    self.o365.exportScripts({
        add,
        addDays,
        addMonths,
        addYears,
        differenceInCalendarDays,
        eachDayOfInterval,
        endOfWeek,
        format,
        getDay,
        getHours,
        getISOWeek,
        getMinutes,
        getMonth,
        getSeconds,
        getYear,
        isAfter,
        isBefore,
        isDate,
        isEqual,
        isValid,
        parse,
        parseISO,
        set,
        setHours,
        setMilliseconds,
        setMinutes,
        setMonth,
        setSeconds,
        setYear,
        startOfWeek,
        sub,
        subMonths,
        subYears
    });
})();
